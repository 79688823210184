import { Link } from "react-router-dom";
import "./style.css"
import { FaFilter } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { FaSort } from "react-icons/fa6";
const ClientList=()=>{
    return (
        <>
        <div className="clientListHeader">
        <div className="ClientList">
<div className="ClientListSearchBox"> 
  <span className="ClientListSearchSpan"><CiSearch className="CiSearch"/><input className="ClientListSearchSpanInput" type="search" placeholder="Search"/></span><i class="FaFilter fa-solid fa-sliders"></i><Link to="/" className="ClientListSearchSpanLink">Add New</Link> 
</div>
<div className="ClientListMainDiv">
<div className="ClientListMainDivTable">
      <div className="ClientListTableThead">
        <div className="clientListFirstTR">
            <p className="ClientListTableTH">Company <i class="fa-solid fa-sort faSolidIconSort"></i></p>
            <p className="ClientListTableTH">Name <i class="fa-solid fa-sort faSolidIconSort" ></i></p>
            <p className="ClientListTableTH">Email ID <i class="fa-solid fa-sort faSolidIconSort"></i></p>
            <p className="ClientListTableTH">Contact No. <i class="fa-solid fa-sort faSolidIconSort"></i></p>
            <p className="ClientListTableTH">Source From <i class="fa-solid fa-sort faSolidIconSort"></i></p>
            <p className="ClientListTableTH">Last Contacted On <i class="fa-solid fa-sort faSolidIconSort"></i></p>
            <p className="ClientListTableTH">Lead Status <i class="fa-solid fa-sort faSolidIconSort"></i></p>
        </div>
      </div>
      <div className="ClientListTableTbody">
          <div className="clientListSecondTR">
            <p className="clientListTD clientListIdcompanyName">Comdrade Tech Park</p>
            <p className="clientListTD">Subramanian</p>
            <p className="clientListTD">subhuman@gmail.com</p>
            <p className="clientListTD">9083161534</p>
            <p className="clientListTD">LinkedIn</p>
            <p className="clientListTD">08-Aug-2023</p>
          <p className="clientListTD" id="clientListTDAttr"><p  className="editButtonPFlex">Connected </p><Link to=""><i class="fa-regular fa-pen-to-square clientEditITag"></i></Link></p>  
          </div>
          <div className="clientListSecondTR">
            <p className="clientListTD clientListIdcompanyName">Comdrade Tech Park</p>
            <p className="clientListTD">Subramanian</p>
            <p className="clientListTD">subhuman@gmail.com</p>
            <p className="clientListTD">9083161534</p>
            <p className="clientListTD">LinkedIn</p>
            <p className="clientListTD">08-Aug-2023</p>
            <p className="clientListTD">Connected</p>
          </div>
          <div className="clientListSecondTR">
            <p className="clientListTD clientListIdcompanyName">Comdrade Tech Park</p>
            <p className="clientListTD">Subramanian</p>
            <p className="clientListTD">subhuman@gmail.com</p>
            <p className="clientListTD">9083161534</p>
            <p className="clientListTD">LinkedIn</p>
            <p className="clientListTD">08-Aug-2023</p>
            <p className="clientListTD">Connected</p>
          </div>
      </div>
    </div>
</div></div>

        </div>

        </>
    )
}
export default ClientList;