import CandidateDetailsSidebar from "./CandidateDetailsSidebar";
import JobSearchDashboard from "./JobSearchDashboard";
import "./index.css"
import documentsIcon from "../../Assets/png/documentsIcon.png"
import IconAbout from "../../Assets/png/IiconAbout.png"
import polygonToggle from "../../Assets/png/togglePolygon.svg"
import dragAndDragImg from "../../Assets/png/dragDropIcon.png"
import { useState, useEffect } from "react";
import googleDrive from "../../Assets/png/googleDrive.png"
import rightArrowImg from "../../Assets/png/rightArrow.png"
import checkListImg from "../../Assets/png/checklistImg.png"
import downArrowImg from "../../Assets/png/downArrowimage.png"
import tickmarkImg from "../../Assets/png/tickmarkImg.png"
import candidateProfilePicture from "../../Assets/png/candidateProfilePicture.png"
import instagramIcon from "../../Assets/png/instagramIcon.png"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { candidateAddDetailsAPI, uploadImagesToAPI, uploadPDFfilesToAPI } from "../../Api/auth";
import fileUploadImg from "../../Assets/png/fileUploadPdfImg.png"
import convertToWebp from "webp-converter-clientside";
import explorerImg from "../../Assets/png/explorerImg.png"
import twitterImg from "../../Assets/png/twitterImg.png"
import beIMg from "../../Assets/png/BeImgProfile.png"
import whatsappImg from "../../Assets/png/whatsaapImg.png"
import LinkedinIMg from "../../Assets/png/LinkedInImg.png"
import telegramImg from "../../Assets/png/telemgramImg.png"
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Slider from 'react-input-slider';
const FillCandidateDetails = () => {
  const [showDocumentsSection, setShowDocumentsSection] = useState(false)
  const [showResumeSection, setShowResumeSection] = useState(true)
  const [openSections, setOpenSections] = useState([true, false, false, false]);
  const [rotateIcon, setRotateIcon] = useState([0, 150, 150, 150]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [socialLinksDiv, setSocialLinksDiv] = useState(false)
  const handleImage = async (file) => {
    try {
      if (!file) {
        toast.error("Please provide an image");
      }

      const convertedFile = await convertToWebp(file, 0.7);
      if (!convertedFile) {
        toast.error("Error converting image");
      }

      setSelectedImage(convertedFile);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOnDropImage = (event) => {
    event.preventDefault();
    const imageFile = event.dataTransfer.files[0];
    handleImage(imageFile);
  };

  const uploadImages = async () => {
    try {
      if (!selectedImage) {
        toast.error("Please select an image");
      }

      else {
        const uploadedUrls = await uploadImagesToAPI([selectedImage]);
        if (!uploadedUrls || uploadedUrls.urls.length === 0) {
          toast.error("Error uploading image to the server");
        }

        const displayImageUrl = String(uploadedUrls.urls[0]);

        setFormData((prevData) => ({
          ...prevData,
          photo: displayImageUrl,
        }));

        toast.success("Image uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading images:", error.message);
      toast.error("Error uploading image");
    } finally {
    }
  };
  const toggleFormVisibility = (index) => {
    const updatedSections = [...openSections];
    const updatedRotate = [...rotateIcon];
    updatedSections[index] = !updatedSections[index];
    updatedRotate[index] = updatedSections[index] ? 0 : 150;
    setOpenSections(updatedSections);
    setRotateIcon(updatedRotate);
  };

  const [formData, setFormData] = useState({
    // candidate: '',
    pdfFile: null,
    photo: '',
    socialMedia: [
      { handle: 'linkedIn', link: '' },
      { handle: 'Instagram', link: '' },
      { handle: 'whatsapp', link: '' },
    ],
    skills: [{ name: '', score: 0 }],
    languages: [{ name: '', score: 0 }],
    hobbies: [{ name: '', score: 0 }],

    firstName: '',
    lastName: '',
    contactNumber: '',
    emailId: '',
    dob: '',
    gender: '',
    Nationality: '',
    havePassport: false,
    AdharNumber: '',
    PanNumber: '',
    FathersName: '',
    FathersDob: '',
    maritalStatus: false,
    spouseName: '',
    spouseDob: '',
    education: [
      {
        title: 'Post Graduation',
        university: '',
        college: '',
        fromDate: '',
        toDate: '',
        percentage: '',
        degree: '',
        specialization: '',
        educationType: '',
      },
      {
        title: 'Graduation',
        university: '',
        college: '',
        fromDate: '',
        toDate: '',
        percentage: '',
        degree: '',
        specialization: '',
        educationType: '',
      },
    ],
    experience: [
      {
        companyName: '',
        designation: '',
        fromDate: '',
        ToDate: '',
        ctc: '',
        status: '',
        lastWorkingDate: '',
        noOfDaysLeft: '',
        currentLocation: '',
        experienceInYear: '',
        reasonForLeaving: '',
        existingOffers: [{ question: 'Do you hold any existing offers we should be aware of?', answer: '' }],
        offerAcceptance: [{ question: 'Do you hold any existing offers we should be aware of?', answer: '' }],
      },
    ],
    consetData: {
      data: [
        {
          question: 'Consent to conduct background checks',
          answer: ''
        },
        {
          question: 'Any criminal convictions or pending criminal cases',
          answer: ''
        },
        {
          question: 'Drug/Alcohol Testing Consent',
          answer: ''
        },
        {
          question: 'Declaration of the accuracy of provided information',
          answer: ''
        },
      ],
      signDoc: '',
    },
    documents: {
      cv: { type: String },
      companies: [{
        name: { type: String },
        offerLatter: { type: String },
        resignationLatter: { type: String },
        salaryProof: { type: String }
      }],
      education: [{
        postGraduation: { type: String },
        graduation: { type: String },
        higherSecondary: { type: String }
      }],
      personal: [{
        passport: { type: String },
        adharCard: { type: String },
        panCard: { type: String }
      }]
    }
  });
  const [overallProgress, setOverallProgress] = useState(0);

  useEffect(() => {
    updateFormProgress();
  }, [formData]);

  const updateFormProgress = () => {
    const totalFields = getTotalFieldsCount(formData);
    const completedFields = getCompletedFieldsCount(formData);
    const progress = (completedFields / totalFields) * 100;

    setOverallProgress(progress);
  };

  const getTotalFieldsCount = (data) => {
    // Customize this function based on your formData structure
    // Recursively count fields in nested structures
    if (typeof data === 'object' && data !== null) {
      return Object.values(data).reduce((count, value) => count + getTotalFieldsCount(value), 0);
    } else {
      return 1;
    }
  };

  const getCompletedFieldsCount = (data) => {
    // Customize this function based on your completion criteria
    // Recursively count completed fields in nested structures
    if (typeof data === 'object' && data !== null) {
      return Object.values(data).reduce((count, value) => count + getCompletedFieldsCount(value), 0);
    } else if (typeof data === 'string' && data.trim() !== '') {
      return 1;
    } else {
      return 0;
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //   const handleSliderChange = (index, value, field) => {
  //     setFormData((prevData) => {
  //       const updatedSkills = [...prevData.skills];
  //       updatedSkills[index][field] = value;
  //       return {
  //         ...prevData,
  //         skills: updatedSkills,
  //       };
  //     });
  //   };

  const handleArrayChange = (e, section, index, subfield) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [section]: prevData[section].map((item, i) =>
        i === index ? { ...item, [subfield || name]: value } : item
      ),
    }));
  };

  const addNewField = (section) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: [
        ...prevData[section],
        section === 'hobbies' || 'skills' || 'languages' ? { name: '', score: 0, showInput: true } : { name: '', score: 0 },
      ],
    }));
  };

  const removeField = (section, index) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: prevData[section].filter((_, i) => i !== index),
    }));
  };
  //   handleChange for filling education details
  const handleChangeEducation = (event, index) => {
    const { name, value } = event.target;
    console.log(event.target.value)
    setFormData((prevData) => ({
      ...prevData,
      education: prevData.education.map((edu, i) =>
        i === index ? { ...edu, [name]: value } : edu
      ),
    }));
  };
  const handleChangeSocialLinks = (event, index) => {
    const { name, value } = event.target;
    console.log(event.target.value)
    setFormData((prevData) => ({
      ...prevData,
      socialMedia: prevData.socialMedia.map((edu, i) =>
        i === index ? { ...edu, [name]: value } : edu
      ),
    }));
  };
  const handleExperienceChange = (event, section, index) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [section]: prevData[section].map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));
  };

  const handleExistingOffersChange = (event, index) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      experience: prevData.experience.map((item, i) =>
        i === index
          ? {
            ...item,
            existingOffers: item.existingOffers.map((offer, j) =>
              j === 0 ? { ...offer, [name]: value } : offer
            ),
          }
          : item
      ),
    }));
  };

  const handleOfferAcceptanceChange = (event, index) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      experience: prevData.experience.map((item, i) =>
        i === index
          ? {
            ...item,
            offerAcceptance: item.offerAcceptance.map((acceptance, j) =>
              j === 0 ? { ...acceptance, [name]: value } : acceptance
            ),
          }
          : item
      ),
    }));
  };
  const handleChangeConsentData = (e, section, field, index) => {
    setFormData((prevFormData) => {
      const updatedData = { ...prevFormData[section] };
      if (field) {
        // Update the specific field
        updatedData[field] = e.target.value;
      } else {
        // Update the data array with the specific index
        updatedData.data[index][e.target.name] = e.target.value;
      }

      return {
        ...prevFormData,
        [section]: updatedData,
      };
    });
  };



  const handleChangeFileDocs = async (e, section, index, fileType) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        return;
      }

      const fileUploadResponse = await uploadPDFfilesToAPI(file);
      console.log(`File uploaded successfully: ${fileType} - ${fileUploadResponse.url}`);

      setFormData((prevFormData) => {
        const sectionArray = prevFormData.documents[section] || [];
        const existingSection = sectionArray[index] || {};

        const updatedSection = {
          ...existingSection,
          [fileType]: fileUploadResponse.url,
        };

        const updatedDocuments = {
          ...prevFormData.documents,
          [section]: [
            ...sectionArray.slice(0, index),
            updatedSection,
            ...sectionArray.slice(index + 1),
          ],
        };

        return {
          ...prevFormData,
          documents: updatedDocuments,
        };
      });
    } catch (error) {
      console.error(`Error uploading file for ${fileType}:`, error);
      toast.error(`Error uploading ${fileType}. Please try again.`);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // await uploadImages();
      const formDataCopy = { ...formData };
      console.log(formDataCopy, "data")

      //   After uploading all files, submit the rest of the form data
      const candidateDetailsResponse = await candidateAddDetailsAPI({
        body: formDataCopy,
      });

      if (candidateDetailsResponse.success) {
        toast.success("Candidate details added successfully");
      } else {
        toast.error("Unknown error occurred while adding candidate details");
      }
    } catch (error) {
      console.error("An error occurred while adding candidate details:", error);
      toast.error("An unexpected error occurred");
    }
  };



  return (
    <>
      <ToastContainer />
      <div className="candidateDetailsFillMainDiv">
        <JobSearchDashboard />
        {/* <CandidateDetailsSidebar /> */}
        <form onSubmit={handleSubmit} className="candidateFormDivForm">
          <div className="CandidateDetailsSidebarMain">
            <div className="CandidateDetailsSidebarDiv">
              <label className="lableForProrgressBarDiv"
                htmlFor="logo"
                onDragOver={(event) => event.preventDefault()}
                onDrop={handleOnDropImage}
              >
                <div className="cicularProgresBar">
                  <CircularProgressbar value={overallProgress} />
                </div>
                <img
                  className="handledropImage"
                  src={selectedImage ? URL.createObjectURL(selectedImage) : fileUploadImg}
                  alt=""
                />
              </label>
              <input
                type="file"
                name="photo"
                onChange={(e) => handleImage(e.target.files[0])}
                accept="image/*"
                style={{ display: "none" }}
                id="logo"
              />
              <button type="button" className="profileUploadbutton" onClick={uploadImages} style={{ display: selectedImage ? "none" : "" }} >
                Upload Image
              </button>
              <p className="portfolionHeading">Portfolio Connections</p>
              <div className="portfolioLinks">
                <img alt="portfolioLinksPicture" className="portfolioLinksPicture" src={LinkedinIMg} />
                <img alt="portfolioLinksPicture" className="portfolioLinksPicture" src={whatsappImg} />
                <img alt="portfolioLinksPicture" className="portfolioLinksPicture" src={instagramIcon} />
                <img alt="portfolioLinksPicture" className="portfolioLinksPicture" src={telegramImg} />
                <img alt="portfolioLinksPicture" className="portfolioLinksPicture" src={twitterImg} />
                <img alt="portfolioLinksPicture" className="portfolioLinksPicture" src={beIMg} />
                <img alt="portfolioLinksPicture" className="portfolioLinksPicture" src={explorerImg} />
              </div>
              <p className="skillsAndLanuguageHeadingsPSecond addlinkForSocial" onClick={() => setSocialLinksDiv(true)}>+ Add Link</p>
              {socialLinksDiv && (

                <div className="socialMediaSocialLinks">
                  <input name="link" onChange={(e) => handleChangeSocialLinks(e, 0)} value={formData.socialMedia[0].link} placeholder="Enter your linkedin her" type="text" className="toggledFormInput" />
                  <input name="link" onChange={(e) => handleChangeSocialLinks(e, 0)} value={formData.socialMedia[0].link} placeholder="Enter your whatsapp here" type="text" className="toggledFormInput" />

                </div>
              )}
              <div className="skillsAndLanuguageMainDiv">
                <div className="skillsAndLanuguage">
                  <span className="skillsAndLanuguageHeadings">
                    <p className="skillsAndLanuguageHeadingsP">Skills</p>
                    <p className="skillsAndLanuguageHeadingsPSecond" onClick={() => addNewField('skills')}>+ Add</p>
                  </span>
                  <div className="skillItem candidatePortfoliosInputs">
                    {formData.skills.map((skill, index) => (
                      <>{skill.showInput && (
                        <div key={index}>
                          <div className="formSkillsDivForCount">
                            <input
                              type="text"
                              name={`skills[${index}].name`}
                              value={skill.name}
                              onChange={(e) => handleArrayChange(e, 'skills', index, 'name')}
                              className="candidatePortfoliosInputSecond"
                              placeholder="Skill name"
                            /><span style={{ color: "#C5C9D9" }}>{`${skill.score}/5`}</span></div>
                          <Slider
                            axis="x"
                            x={skill.score}
                            onChange={({ x }) => handleArrayChange({ target: { name: `skills[${index}].score`, value: x } }, 'skills', index, 'score')}
                            style={{ width: '222px', margin: '0 10px' }}
                            xmax={5} styles={{
                              track: {
                                backgroundColor: '#C5C9D9'
                              },
                              active: {
                                backgroundColor: '#2F8F27'
                              }
                            }}
                            xmin={0}
                          />

                          <button type="button" className="skillsAndLanuguageHeadingsPSecond skillAddRemoveButton" onClick={() => removeField('skills', index)}>
                            Remove
                          </button>
                        </div>)}</>
                    ))}
                  </div>
                  {/* <input name="skills" onChange={handleChange} value={formData.skills}  type="text"  className="candidatePortfoliosInputs" placeholder="No skills is added"/> */}
                </div>
                <div className="skillsAndLanuguage">
                  <span className="skillsAndLanuguageHeadings">
                    <p className="skillsAndLanuguageHeadingsP">Languages</p>
                    <p className="skillsAndLanuguageHeadingsPSecond" onClick={() => addNewField('languages')}>+ Add</p>
                  </span>
                  <div className="skillItem candidatePortfoliosInputs">
                    {formData.languages.map((skill, index) => (
                      <>{skill.showInput && (


                        <div key={index}>
                          <div className="formSkillsDivForCount">
                            <input
                              type="text"
                              name={`languages[${index}].name`}
                              value={skill.name}
                              onChange={(e) => handleArrayChange(e, 'languages', index, 'name')}
                              className="candidatePortfoliosInputSecond"
                              placeholder="Add Languages"
                            /><span style={{ color: "#C5C9D9" }}>{`${skill.score}/5`}</span></div>
                          <Slider
                            axis="x"
                            x={skill.score}
                            onChange={({ x }) => handleArrayChange({ target: { name: `languages[${index}].score`, value: x } }, 'languages', index, 'score')}
                            style={{ width: '222px', margin: '0 10px' }}
                            xmax={5} styles={{
                              track: {
                                backgroundColor: '#C5C9D9'
                              },
                              active: {
                                backgroundColor: '#2F8F27'
                              }
                            }}
                            xmin={0}
                          />

                          <button type="button" className="skillsAndLanuguageHeadingsPSecond skillAddRemoveButton" onClick={() => removeField('languages', index)}>
                            Remove
                          </button>
                        </div>)}</>
                    ))}
                  </div>
                  {/* <input name="skills" onChange={handleChange} value={formData.skills}  type="text"  className="candidatePortfoliosInputs" placeholder="No skills is added"/> */}
                </div>
                <div className="skillsAndLanuguage">
                  <span className="skillsAndLanuguageHeadings">
                    <p className="skillsAndLanuguageHeadingsP">Hobbies</p>
                    <p className="skillsAndLanuguageHeadingsPSecond" onClick={() => addNewField('hobbies')}>+ Add</p>
                  </span>
                  <div className="skillItem candidatePortfoliosInputs">
                    {formData.hobbies.map((skill, index) => (
                      <>    {skill.showInput && (
                        <div key={index}>
                          <div className="formSkillsDivForCount">
                            <input
                              type="text"
                              name={`hobbies[${index}].name`}
                              value={skill.name}
                              onChange={(e) => handleArrayChange(e, 'hobbies', index, 'name')}
                              className="candidatePortfoliosInputSecond"
                              placeholder="Add Hobbies"
                            /><span style={{ color: "#C5C9D9" }}>{`${skill.score}/5`}</span></div>
                          <Slider
                            axis="x"
                            x={skill.score}
                            onChange={({ x }) => handleArrayChange({ target: { name: `hobbies[${index}].score`, value: x } }, 'hobbies', index, 'score')}
                            style={{ width: '222px', margin: '0 10px' }}
                            xmax={5} styles={{
                              track: {
                                backgroundColor: '#C5C9D9'
                              },
                              active: {
                                backgroundColor: '#2F8F27'
                              }
                            }}
                            xmin={0}
                          />

                          <button type="button" className="skillsAndLanuguageHeadingsPSecond skillAddRemoveButton" onClick={() => removeField('hobbies', index)}>
                            Remove
                          </button>
                        </div>

                      )}
                      </>
                    ))}
                  </div>
                  {/* <input name="skills" onChange={handleChange} value={formData.skills}  type="text"  className="candidatePortfoliosInputs" placeholder="No skills is added"/> */}
                </div>
              </div>

            </div>
          </div>
          <div className="candidateFormMainDiv">
            <div className="candidateFormDiv">

              <div className="candidateFormToggle">
                <span className="candidateFormToggleSpan" > <img style={{ transform: `rotate(${rotateIcon[0]}deg)` }} onClick={() => toggleFormVisibility(0)} src={polygonToggle} alt="polygonToggle" className={`polygonToggle ${openSections[0] ? 'activePolygon' : ''}`} /> <p className="candidateFormToggleHeading">About Me <img src={IconAbout} alt="IconAbout" className="IconAbout" /></p> </span>
                <img src={documentsIcon} alt="documentsIcon" onClick={() => setShowDocumentsSection(true)} className="documentsIcon" />
              </div>
              {openSections[0] && (

                <div className="toggledFormMainDiv">
                  <div className="toggledFormDivFirst">
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">First name</label>
                      <input name="firstName" onChange={handleChange} value={formData.firstName} placeholder="Enter" type="text" className="toggledFormInput" />
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Last name</label>
                      <input name="lastName" onChange={handleChange} value={formData.lastName} type="text" placeholder="Enter" className="toggledFormInput" />
                    </div><div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Contact Number</label>
                      <input name="contactNumber" onChange={handleChange} value={formData.contactNumber} type="text" placeholder="Enter" className="toggledFormInput" />
                      <div className="checkboxSaveNumber">
                        <input name="" onChange={handleChange} value={formData} type="checkbox" /><p className="whatsappP">Save as Whatsapp</p>
                      </div>

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Email ID</label>
                      <input name="emailId" onChange={handleChange} value={formData.emailId} type="text" placeholder="Enter" className="toggledFormInput" />
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">DOB</label>
                      <input name="dob" onChange={handleChange} value={formData.dob} type="date" placeholder="Enter" className="toggledFormInput" />
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Gender</label>
                      <select name="gender" onChange={handleChange} value={formData.gender} className="toggledFormInput" >
                        <option>Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                        <option value="I prefer not to say">I prefer not to say</option>
                      </select>
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Nationality</label>
                      <select name="Nationality" onChange={handleChange} value={formData.Nationality} className="toggledFormInput">
                        <option>Select</option>
                        <option>Indian</option>
                        <option>NRI</option>

                      </select>
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Do you have Passport</label>
                      <select name="havePassport" onChange={handleChange} value={formData.havePassport} className="toggledFormInput">
                        <option>Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Aadhar Number</label>
                      <input name="AdharNumber" onChange={handleChange} value={formData.AdharNumber} type="text" placeholder="Enter" className="toggledFormInput" />
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">PAN Number</label>
                      <input name="PanNumber" onChange={handleChange} value={formData.PanNumber} type="text" placeholder="Enter" className="toggledFormInput" />
                    </div><div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Father's Name</label>
                      <input name="FathersName" onChange={handleChange} value={formData.FathersName} type="text" placeholder="Enter" className="toggledFormInput" />
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Father's DOB</label>
                      <input name="FathersDob" onChange={handleChange} value={formData.FathersDob} type="date" placeholder="Enter" className="toggledFormInput" />
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Marital Status</label>
                      <select name="maritalStatus" onChange={handleChange} value={formData.maritalStatus} className="toggledFormInput">
                        <option>Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Spouse's Name</label>
                      <input name="spouseName" onChange={handleChange} value={formData.spouseName} type="text" placeholder="Enter" className="toggledFormInput" />
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Spouse DOB</label>
                      <input name="spouseDob" onChange={handleChange} value={formData.spouseDob} type="date" placeholder="Enter" className="toggledFormInput" />
                    </div>

                  </div>
                </div>)}
              <div className="candidateFormToggleEducation">
                <span className="candidateFormToggleSpan" > <img style={{ transform: `rotate(${rotateIcon[1]}deg)` }} src={polygonToggle} onClick={() => toggleFormVisibility(1)} alt="polygonToggle" className="polygonToggle" /> <p className="candidateFormToggleHeading">Education</p> </span>
              </div>
              {openSections[1] && (

                <div className="toggledFormMainDiv">
                  <p className="candidateFormToggleHeadingP">Post Graduation</p>
                  <div className="toggledFormDivFirst">
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">University</label>
                      <input name="university" onChange={(e) => handleChangeEducation(e, 0)} value={formData.education[0].university} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">College Institute</label>
                      <input name="college" onChange={(e) => handleChangeEducation(e, 0)} value={formData.education[0].college} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Graduation</label>

                      <div className="toggledFormDateDiv">
                        <input name="fromDate" onChange={(e) => handleChangeEducation(e, 0)} value={formData.education[0].fromDate} placeholder="From" type="date" className="toggledFormInputDate" />
                        <input name="toDate" onChange={handleChange} value={formData.education[0].toDate} placeholder="To" type="date" className="toggledFormInputDate" />
                      </div>

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Percentage</label>
                      <input name="percentage" onChange={(e) => handleChangeEducation(e, 0)} value={formData.education[0].percentage} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Degree</label>
                      <input name="degree" onChange={(e) => handleChangeEducation(e, 0)} value={formData.education[0].degree} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Specialization</label>
                      <input name="specialization" onChange={(e) => handleChangeEducation(e, 0)} value={formData.education[0].specialization} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Education Type</label>
                      <select name="educationType" onChange={(e) => handleChangeEducation(e, 0)} value={formData.education[0].educationType} className="toggledFormInput">
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                  <p className="candidateFormToggleHeadingP">Graduation</p>
                  <div className="toggledFormDivFirst">
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">University</label>
                      <input name="university" onChange={(e) => handleChangeEducation(e, 1)} value={formData.education[1].university} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">College Institute</label>
                      <input name="college" onChange={(e) => handleChangeEducation(e, 1)} value={formData.education[1].college} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Graduation</label>

                      <div className="toggledFormDateDiv">
                        <input name="fromDate" onChange={(e) => handleChangeEducation(e, 1)} value={formData.education[1].fromDate} placeholder="From" type="date" className="toggledFormInputDate" />
                        <input onChange={(e) => handleChangeEducation(e, 1)} value={formData.education[1].toDate} placeholder="To" type="date" className="toggledFormInputDate" />
                      </div>

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Percentage</label>
                      <input name="percentage" onChange={(e) => handleChangeEducation(e, 1)} value={formData.education[1].percentage} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Degree</label>
                      <input name="degree" onChange={(e) => handleChangeEducation(e, 1)} value={formData.education[1].degree} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Specialization</label>
                      <input name="specialization" onChange={(e) => handleChangeEducation(e, 1)} value={formData.education[1].specialization} placeholder="Enter" type="text" className="toggledFormInput" />

                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Education Type</label>
                      <select name="educationType" onChange={(e) => handleChangeEducation(e, 1)} value={formData.education[1].educationType} className="toggledFormInput">
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                </div>)}
              <div className="candidateFormToggleEducation">
                <span className="candidateFormToggleSpan" > <img style={{ transform: `rotate(${rotateIcon[2]}deg)` }} src={polygonToggle} onClick={() => toggleFormVisibility(2)} alt="polygonToggle" className="polygonToggle" /> <p className="candidateFormToggleHeading">Experience</p> </span>
              </div>
              {openSections[2] && (
                <div>
                  <div className="toggledFormMainDiv">
                    <div className="toggledFormDivFirst">
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Current Company Name</label>
                        <input name="companyName" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].companyName} placeholder="Enter" type="text" className="toggledFormInput" />

                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Designation</label>
                        <input name="designation" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].designation} placeholder="Enter" type="text" className="toggledFormInput" />

                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Duration</label>

                        <div className="toggledFormDateDiv">
                          <input name="fromDate" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].fromDate} placeholder="From" type="date" className="toggledFormInputDate" />
                          <input name="ToDate" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].ToDate} placeholder="To" type="date" className="toggledFormInputDate" />
                        </div>

                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Current CTC</label>
                        <input name="ctc" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].ctc} placeholder="Enter" type="text" className="toggledFormInput" />

                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Status</label>
                        <select name="status" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].status} className="toggledFormInput">
                          <option>Select</option>
                          <option>Select</option>
                        </select>
                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Last Working Date</label>
                        <input name="lastWorkingDate" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].lastWorkingDate} placeholder="Enter" type="date" className="toggledFormInput" />

                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">No. of Days Left</label>
                        <input name="noOfDaysLeft" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].noOfDaysLeft} placeholder="Enter" type="text" className="toggledFormInput" />

                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Current Location</label>
                        <input name="currentLocation" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].currentLocation} placeholder="Enter" type="text" className="toggledFormInput" />

                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Experience in years</label>
                        <input name="experienceInYear" onChange={(e) => handleExperienceChange(e, 'experience', 0)} value={formData.experience[0].experienceInYear} placeholder="Enter" type="text" className="toggledFormInput" />

                      </div>
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Designation</label>
                        <textarea value={formData.experience[0].reasonForLeaving} name="reasonForLeaving" onChange={(e) => handleExperienceChange(e, 'experience', 0)} placeholder="Enter" className="toggledFormInputTextarea" ></textarea>
                      </div>
                    </div>
                    <p className="candidateFormToggleHeadingP">Existing Offers</p>
                    <div className="toggledFormDivFirst toggledFormDivThird">
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Do you hold any existing offers we should be aware of?</label>
                        <select name="answer" onChange={(e) => handleExistingOffersChange(e, 0,)} value={formData.experience[0].existingOffers[0].answer} className="toggledFormInputSelect">
                          <option>Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {/* <div className="toggledFormLabelInput">
                                                <label className="toggledFormLabel">Enter name of the organization you hold the offer from</label>
                                                <input name="" onChange={handleChange} value={formData}  placeholder="Enter" type="text" className="toggledFormInputExperience" />
                                            </div> */}
                      {/* <div className="toggledFormLabelInput">
                                                <label className="toggledFormLabel">Do you hold any existing offers we should be aware of?</label>
                                                <select name="FathersName" onChange={handleChange} value={formData.FathersName}  className="toggledFormInputSelect">
                                                    <option>Select</option>
                                                </select>
                                            </div> */}
                    </div>
                    <p className="candidateFormToggleHeadingP">Offer Acceptance</p>
                    <div className="toggledFormDivFirst toggledFormDivThird">
                      <div className="toggledFormLabelInput">
                        <label className="toggledFormLabel">Do you hold any existing offers we should be aware of?</label>
                        <select name="answer" onChange={(e) => handleOfferAcceptanceChange(e, 0,)} value={formData.experience[0].offerAcceptance[0].answer} className="toggledFormInputSelect">
                          <option>Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>

                  </div>
                  <p className="addWorkExperienceP">+ Add Work Experience</p>
                </div>)}
              <div className="candidateFormToggleEducation">
                <span className="candidateFormToggleSpan" > <img src={polygonToggle} style={{ transform: `rotate(${rotateIcon[3]}deg)` }} onClick={() => toggleFormVisibility(3)} alt="polygonToggle" className="polygonToggle" /> <p className="candidateFormToggleHeading">Consent Data</p> </span>
              </div>
              {openSections[3] && (
                <div className="toggledFormMainDiv">
                  <div className="toggledFormDivLast">
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Consent to conduct background checks</label>
                      <select name="answer" onChange={(e) => handleChangeConsentData(e, 'consetData', 'data', 0)} value={formData.consetData.data[0].answer} className="toggledFormInputSelect">
                        <option>Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Any criminal convictions or pending criminal cases</label>
                      <select name="answer" onChange={(e) => handleChangeConsentData(e, 'consetData', 'data', 1)} value={formData.consetData.data[0].answer} className="toggledFormInputSelect">
                        <option>Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Drug/Alcohol Testing Consent</label>
                      <select name="answer" onChange={(e) => handleChangeConsentData(e, 'consetData', 'data', 2)} value={formData.consetData.data[0].answer} className="toggledFormInputSelect">
                        <option>Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">Declaration of the accuracy of provided information</label>
                      <select name="answer" onChange={(e) => handleChangeConsentData(e, 'consetData', 'data', 3)} value={formData.consetData.data[0].answer} className="toggledFormInputSelect">
                        <option>Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <div className="toggledFormLabelInput">
                      <label className="toggledFormLabel">I hereby declare what I have</label>
                      <div className="lastDivForConfirmation">
                        <input name="signDoc" onChange={(e) => handleChangeConsentData(e, 'consetData', 'signDoc', 0)} value={formData.consetData.signDoc} className="confirmationInput" type="text" placeholder="Please sign here" />
                        <p className="orLastP">or</p>
                        <label id="fileUploadLast" className="fileUploadClassname">
                          <input name="pdfFile"
                            onChange={(e) => handleChangeFileDocs(e, "documents", 0, "cv")} type="file" style={{ display: "none" }} id="fileUploadLast" />
                          <div className="fileUploadClassnameDiv">
                            <img src={dragAndDragImg} alt="" className="dragAndDragImg" />
                            <span className="chooseFileDrag">Drag and drop or <span className="chooseFileP">choose file</span> to upload</span></div>
                        </label>
                      </div>
                    </div>

                  </div>
                </div>)}
              <div className={`uploadMainResume ${!showResumeSection ? "uploadResumeHide" : ""}`}>
                <div className="uploadResumeDiv">
                  <div className="uploadResumeHeading">
                    <p className="uploadREsumeHeadingFirst">Upload Your Resume</p>
                    <p className="uploadREsumeHeadingSecond">Lorem ipsum dolor sit amet, consetetur  inthe sadipscing elitr, </p>
                  </div>
                  <label id="fileUploadLastforResume" className="fileUploadClassname">
                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, 0, 0, "cv")} type="file" style={{ display: "none" }} id="fileUploadLastforResume" />
                    <div className="fileUploadClassnameDiv">
                      <img src={dragAndDragImg} alt="" className="dragAndDragImg" />
                      <span className="chooseFileDrag">Drag and drop or <span className="chooseFileP">choose file</span> to upload</span></div>
                  </label>
                  <div className="uploadResumebuttons">
                    <p className="uploadREsumeButtonsFirst" onClick={() => setShowResumeSection(false)}>Cancel</p>
                    <p className="uploadREsumeButtonsSecond" onClick={() => setShowResumeSection(false)}>Upload</p>
                  </div>
                </div>
              </div>
              <div className="candidateDocumentsDivHeader">
                <div className={`candidateDocumentsDiv ${showDocumentsSection ? "candidateDocumentsDivShow" : ""}`}>
                  <div className="candidateDocumentsMainDiv">
                    <div className="headingsofDocuments">
                      <h1 className="headingsofDocuments">Documents</h1>
                      <img src={rightArrowImg} alt="rightArrowImg" onClick={() => setShowDocumentsSection(false)} className="rightArrowImg" />
                    </div>
                    <div className="candidateDocumentsDivForm">
                      <div className="candidateDocumentsDivFormMai">
                        <label id="fileUploadLastDocs" className="fileUploadClassnameDocs">
                          <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, 0, 0, "cv")} type="file" style={{ display: "none" }} id="fileUploadLastDocs" />
                          <div className="docsUploadClassnameDiv">
                            <img src={dragAndDragImg} alt="" className="dragAndDragImgDocs" />
                            <span className="chooseFileDragDocs">Drag and drop or <span className="chooseFilePDocs">choose your CV &/or Cover letter</span> to upload pdf or jpeg</span></div>

                        </label>
                        <span className="docsHrTagSpan" >
                          <hr className="docsHrTag" /> <p className="docsHrTagP">To upload login with</p> <hr className="docsHrTag" />
                        </span>
                        <div className="docsLoginLinks">
                          <img src={googleDrive} alt="" className="googleDrive" />
                          <img src={googleDrive} alt="" className="googleDrive" />
                          <img src={googleDrive} alt="" className="googleDrive" />
                          <img src={googleDrive} alt="" className="googleDrive" />
                          <img src={googleDrive} alt="" className="googleDrive" />
                        </div>
                        <p className="docsLoginP">Please login to upload your documents requested in the checklist given below</p>
                      </div>
                      <div className="docsUploadMainDiv">
                        <p className="docsUploadMainDivPara">Checklists:</p>
                        <div lassName="docsUploadMDivDetails">
                          <p className="docsUploadMDivDetailsFirstP">Experience</p>
                          <div className="docsUploadDivsStarts">
                            <div className="docsUploadHeadings">
                              <img src={checkListImg} alt="" className="" />
                              <p className="docsUploadPs">Company 1</p>
                              <img src={downArrowImg} alt="" className="" />
                            </div>
                            <div className="documentsUploadDiffTypes">
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Offer Letter</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "companies", 0, "offerLatter")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Resignation Letter</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "companies", 0, "resignationLatter")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Salary Proof <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" /></p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "companies", 0, "salaryProof")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="docsUploadDivsStarts">
                            <div className="docsUploadHeadings">
                              <img src={checkListImg} alt="" className="" />
                              <p className="docsUploadPs">Company 2</p>
                              <img src={downArrowImg} alt="" className="" />
                            </div>
                            <div className="documentsUploadDiffTypes">
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Offer Letter</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "companies", 0, "offerLatter")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Resignation Letter</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "companies", 0, "resignationLatter")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Salary Proof <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" /></p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" nChange={(e) => handleChangeFileDocs(e, "companies", 0, "salaryProof")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div lassName="docsUploadMDivDetails">
                          <p className="docsUploadMDivDetailsFirstP"> <img src={checkListImg} alt="" className="" /> Education</p>
                          <div className="docsUploadDivsStarts">

                            <div className="documentsUploadDiffTypes">
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Post Graduation</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "education", 0, "postGraduation")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Graduation</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "education", 0, "graduation")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Higher Secondary</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "education", 0, "higherSecondary")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div lassName="docsUploadMDivDetails">
                          <p className="docsUploadMDivDetailsFirstP"> <img src={checkListImg} alt="" className="" /> Personal</p>
                          <div className="docsUploadDivsStarts">

                            <div className="documentsUploadDiffTypes">
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Passport</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "personal", 0, "passport")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">Aadhar Card</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "personal", 0, "adharCard")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                              <div className="documentsUploadDiffTypesSecond">
                                <span className="documentsUploadDiffTypesSpan">
                                  <img src={tickmarkImg} className="tickmarkImg" alt="tickmarkImg" />
                                  <p className="docsUploadDiffTypesPara">PAN Card</p>
                                </span>
                                <span className="documentsUploadDiffTypesSpan">
                                  <label className="fileUploadClassnameDocsDiffTypes">
                                    <input name="pdfFile" onChange={(e) => handleChangeFileDocs(e, "personal", 0, "panCard")} type="file" style={{ display: "none" }} />
                                    <p className="documentsUploadDiffTypesButton">Upload</p>
                                  </label>
                                  <img src={IconAbout} className="tickmarkImg" alt="tickmarkImg" />
                                </span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="candidateAddDetailsSubmitDiv">
                <button className="candidateAddDetailsSubmit">Submit</button>

              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default FillCandidateDetails;