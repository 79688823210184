import { baseApiCall } from "../baseApi";

export const loginAPI = (data) => {
  return baseApiCall({
    url: "employee-transport/auth/hr/login",
    method: "post",
    data,
  });
};

export const signUpAPI = (data) => {
  return baseApiCall({
    url: "employee-transport/auth/hr/register",
    method: "post",
    data,
  });
};

export const forgotPasswordApi = (data) => {
  return baseApiCall({
    url: "auth/customer/forgot-password",
    method: "post",
    data,
  });
};

export const otpVerificationApi = (data) => {
  return baseApiCall({
    url: "auth/customer/otp-verification",
    method: "post",
    data,
  });
};

export const resetPasswordApi = (data) => {
  return baseApiCall({
    url: "auth/customer/change-password",
    method: "post",
    data,
  });
};
// candidate login api's
export const candidateSignUpAPI = (data) => {
  return baseApiCall({
    url: "employment/auth/candidate/register",
    method: "post",
    data,
  });
};
export const candidateLoginAPI = (data) => {
  return baseApiCall({
    url: "employment/auth/candidate/login",
    method: "post",
    data,
  });
};
export const HRSignAPI = (data) => {
  return baseApiCall({
    url: "employment/client/hr/register",
    method: "post",
    data,
  });
};
export const HRLoginAPI = (data) => {
  return baseApiCall({
    url: "employment/client/hr/login",
    method: "post",
    data,
  });
};
// candidate add details api's
export const candidateAddDetailsAPI = (data) => {
  return baseApiCall({
    url: "employment/candidate/add/detals",
    method: "post",
    data,
  });
};
export const clientAddDetailsAPI = (data) => {
  return baseApiCall({
    url: "employment/client/add",
    method: "post",
    data,
  });
};
export const uploadPDFfilesToAPI = async (data) => {
  const formData = new FormData();
    formData.append("pdfFile", data);
  
  try {
    const response = await fetch("https://newlandingpagebackend-hsx5qhhsda-el.a.run.app/api/v1/cloud/upload/pdf", {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData; // Assuming the API returns image URLs or relevant data
    } else {
      throw new Error("pdf upload failed");
    }
  } catch (error) {
    console.error("An error occurred while uploading files:", error);
    return null;
  }
};
export const uploadImagesToAPI = async (data) => {
  const formData = new FormData();
  data.forEach((img) => {
    formData.append("images", img);
  });
  
  try {
    const response = await fetch("https://newlandingpagebackend-hsx5qhhsda-el.a.run.app/api/v1/cloud/upload", {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData; // Assuming the API returns image URLs or relevant data
    } else {
      throw new Error("Image upload failed");
    }
  } catch (error) {
    console.error("An error occurred while uploading images:", error);
    return null;
  }
};