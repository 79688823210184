import { baseApiCall } from "../baseApi";

export const addJob = async (data) => {
    const token = localStorage.getItem("hrToken");
    return baseApiCall({
        url: `employment/jobpost/add`,
        method: "post",
        headers: {
            Authorization: "Bearer " + token,
        },
        data,
    });
};



