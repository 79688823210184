import "./ClientOnBoarding.css"
import { useState } from "react";
import IconAbout from "../../Assets/png/IiconAbout.png"
import { FaSort } from "react-icons/fa6";
import dragAndDragImg from "../../Assets/png/dragDropIcon.png"

const ClientPreviewMode=({data})=>{
            
    return(
        <>
        <div className="onBoardClientHeaderMain" style={{background:"none"}}>
        <div className="onBoardClientHeader" style={{margin:"0"}}>
            {/* <h1 className="onBoardClientHeaderH1">Client Onboarding</h1> */}
            <form className="onBoardClientHeaderForm">
            
        
        {/* primaryDetails */}
        <div className="clientDetailsSlider">
        <>
            {/* primaryDetails */}
            {/* Your primary details component */}
            <div className="onBoardPrimaryDetails">
              <p className="onBoardPrimaryDetailsPara">Client Details </p>
              <div className="onBoardPrimaryDetailsInputs">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Client ID</label>
                  <input 
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="clientId"
                    value={data.clientId}
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Client Name</label>
                  <input value={data.clientName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="clientName"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Client Industry</label>
                  <select
                    className="onBoardPrimaryDetailsInputData"
                    name="clientIndustry"
                     value={data.clientIndustry}
                  >
                    <option>select</option>
                  </select>
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Name</label>
                  <input value={data.clientName1}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="clientName1"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Email ID</label>
                  <input value={data.clientEmailID}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="clientEmailID"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Phone Number</label>
                  <input value={data.clientPhoneNumber}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="clientPhoneNumber"
                    
                  />
                </div>
              </div>
            </div>
            {/* Business Profile */}
            <div className="onBoardPrimaryDetails">
              <p className="onBoardPrimaryDetailsPara">Business Profile </p>
              <div className="onBoardPrimaryDetailsInputs">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Department</label>
                  <input value={data.department}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="department"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Domain</label>
                  <select
                    className="onBoardPrimaryDetailsInputData"
                    name="domain"
                     value={data.domain}
                  >
                    <option>select</option>
                  </select>
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Website Link</label>
                  <input value={data.websiteLink}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="websiteLink"
                    
                  />
                </div>
              </div>
            </div>
            {/* Shipping Address */}
            <div className="onBoardPrimaryDetails">
              <span className="onBoardingPrimaryDetailsSpanH">
                <p className="onBoardPrimaryDetailsPara">Shipping Address </p>
                <p className="ShippingAddressSpanP">
                  <input 
                    type="checkbox"
                    className="onBoardPrimaryDetailsCheckbox"
                  />
                  &nbsp;Same as billing address
                </p>
              </span>
              <div className="onBoardPrimaryDetailsInputs onBoardPrimaryDetailsInputsTwo">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Name</label>
                  <input value={data.shippingName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingName"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Designation</label>
                  <input value={data.shippingDesignation}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingDesignation"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Contact</label>
                  <input value={data.shippingContact}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingContact"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Address</label>
                  <input value={data.shippingAddress}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingAddress"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">TAN</label>
                  <input value={data.shippingTAN}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingTAN"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">PAN</label>
                  <input value={data.shippingPAN}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingPAN"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">GST</label>
                  <input value={data.shippingGST}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingGST"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    Invoice Email ID
                  </label>
                  <input value={data.shippingInvoiceEmail}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingInvoiceEmail"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    CC Invoice Email ID
                  </label>
                  <input value={data.shippingCCInvoiceEmail}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="shippingCCInvoiceEmail"
                    
                  />
                </div>
              </div>
              {/* <p className="AddNewShippingAddress">+ Add New Address</p> */}
            </div>
            {/* Billing Address */}
            <div className="onBoardPrimaryDetails">
              <span className="onBoardingPrimaryDetailsSpanH">
                <p className="onBoardPrimaryDetailsPara">Billing Address </p>
                <p className="ShippingAddressSpanP">
                  <input 
                    type="checkbox"
                    className="onBoardPrimaryDetailsCheckbox"
                  />
                  &nbsp;Same as shipping address
                </p>
              </span>
              <div className="onBoardPrimaryDetailsInputs onBoardPrimaryDetailsInputsTwo">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Name</label>
                  <input value={data.billingName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingName"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Designation</label>
                  <input value={data.billingDesignation}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingDesignation"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Contact</label>
                  <input value={data.billingContact}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingContact"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Address</label>
                  <input value={data.billingAddress}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingAddress"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">TAN</label>
                  <input value={data.billingTAN}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingTAN"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">PAN</label>
                  <input value={data.billingPAN}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingPAN"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">GST</label>
                  <input value={data.billingGST}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingGST"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    Invoice Email ID
                  </label>
                  <input value={data.billingInvoiceEmail}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingInvoiceEmail"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    CC Invoice Email ID
                  </label>
                  <input value={data.billingCCInvoiceEmail}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="billingCCInvoiceEmail"
                    
                  />
                </div>
              </div>
              {/* <p className="AddNewShippingAddress">+ Add New Address</p> */}
            </div>
            {/* Bank Details */}
            <div className="onBoardPrimaryDetails">
              <p className="onBoardPrimaryDetailsPara">Bank Details</p>
              <div className="onBoardPrimaryDetailsInputs">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Account Name</label>
                  <input value={data.accountName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="accountName"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Account Number</label>
                  <input value={data.accountNumber}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="accountNumber"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    Reenter Account Number
                  </label>
                  <input value={data.reenterAccountNumber}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="reenterAccountNumber"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Bank Name</label>
                  <input value={data.bankName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="bankName"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Branch Name</label>
                  <input value={data.branchName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="branchName"
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">IFSC Code</label>
                  <input value={data.ifscCode}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="ifscCode"
                    
                  />
                </div>
              </div>
            </div>
          </>
          
          <>
                    {/* compensation component */}
                    <div className="CompensationDiv">
                      <div className="CompensationDivHeader">
                        <div className="Employmenttype">
                          <label className="EmploymenttypeLabel">Employment Type</label>
                          <select className="EmploymenttypeSelect" name="employmentType1"  value={data.employmentType1}> 
                            <option>select</option>
                            <option>Permanent</option>
                          </select>
                        </div>
                        <div className="percentageValue">
                          <p className="percentageValuePara">
                            Percentage Value Excluding 18% GST <img src={IconAbout} />
                          </p>
                          <div className="percentageValueParaDiv">
                            <div className="percentageValueParaDivWithInput">
                              <span className="percentageValueSpan">
                                <input type="checkbox" className="percentageValueParaCheckbox" />Entry / Junior Level
                              </span>
                              <input value={data.entryLevel} type="text" className="onBoardPrimaryDetailsInputData" name="entryLevel"  />
                            </div>
                            <div className="percentageValueParaDivWithInput">
                              <span className="percentageValueSpan">
                                <input type="checkbox" className="percentageValueParaCheckbox" />Mid / Associate Level
                              </span>
                              <input value={data.midLevel} type="text" className="onBoardPrimaryDetailsInputData" name="midLevel"  />
                            </div>
                            <div className="percentageValueParaDivWithInput">
                              <span className="percentageValueSpan">
                                <input type="checkbox" className="percentageValueParaCheckbox" />Senior Level
                              </span>
                              <input value={data.seniorLevel} type="text" className="onBoardPrimaryDetailsInputData" name="seniorLevel"  />
                            </div>
                            <div className="percentageValueParaDivWithInput">
                              <span className="percentageValueSpan">
                                <input type="checkbox" className="percentageValueParaCheckbox" />Top Management Level
                              </span>
                              <input value={data.topManagementLevel} type="text" className="onBoardPrimaryDetailsInputData" name="topManagementLevel"  />
                            </div>
                          </div>
                          <div className="percentageValuePrimarDetails">
                            <div className="onBoardPrimaryDetailsData">
                              <label className="onBoardPrimaryDetailsLabel">Invoice Raised Days</label>
                              <input value={data.invoiceRaisedDays1} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="invoiceRaisedDays1"  />
                            </div>
              
                            <div className="onBoardPrimaryDetailsData">
                              <label className="onBoardPrimaryDetailsLabel">Client Payable Days</label>
                              <input value={data.clientPayableDays1} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="clientPayableDays1"  />
                            </div>
                            <div className="onBoardPrimaryDetailsData">
                              <label className="onBoardPrimaryDetailsLabel">Candidate Retention Days</label>
                              <input value={data.candidateRetentionDays} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="candidateRetentionDays"  />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="CompensationDivHeader">
                        <div className="Employmenttype">
                          <label className="EmploymenttypeLabel">Employment Type</label>
                          <select className="EmploymenttypeSelect" name="employmentType2"  value={data.employmentType2}>
                            <option>select</option>
                            <option>Contingent / Managed Services</option>
                          </select>
                        </div>
                        <div className="percentageValue">
                          <p className="percentageValuePara">
                            CTC - INR Value Excluding 18% GST<img src={IconAbout} />
                          </p>
                          <div className="percentageValueParaDiv">
                            <div className="percentageValueParaDivWithInput">
                              <span className="percentageValueSpan">
                                <input type="checkbox" className="percentageValueParaCheckbox" />Monthly Rate Card
                              </span>
                              <input value={data.monthlyRateCard} type="text" className="onBoardPrimaryDetailsInputData" name="monthlyRateCard"  />
                            </div>
                            <div className="percentageValueParaDivWithInput">
                              <span className="percentageValueSpan">
                                <input type="checkbox" className="percentageValueParaCheckbox" />GST Value
                              </span>
                              <input value={data.gstValue} type="text" className="onBoardPrimaryDetailsInputData" name="gstValue"  />
                            </div>
                          </div>
                          <div className="percentageValuePrimarDetails">
                            <div className="onBoardPrimaryDetailsData">
                              <label className="onBoardPrimaryDetailsLabel">Invoice Raised Days</label>
                              <input value={data.invoiceRaisedDays2} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="invoiceRaisedDays2"  />
                            </div>
              
                            <div className="onBoardPrimaryDetailsData">
                              <label className="onBoardPrimaryDetailsLabel">Client Payable Days</label>
                              <input value={data.clientPayableDays2} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="clientPayableDays2"  />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <button className="AddNewShippingAddress">+ Add New Type</button> */}
                    </div>
                  </>
            <>
              {/* agreement component */}
              <div className="AgreementDivHeader">

<div className="AgreementDiv">
   
    
    {/* agreement file upload */}
    <div className="AgreementDivDetails">
    <p className="onBoardPrimaryDetailsPara" style={{marginTop:"2rem"}}>Agreements</p>
        <div className="AgreementDivAttachments">
            <span className="AgreementDivAttachments" style={{borderRight:" 2px solid #F7F7F7", height: "5rem"}}>
                <p className="AgreementDivAttachmentsP1">Final MSA.pdf</p>
                <p className="AgreementDivAttachmentsP2">2.3 MB</p>
            </span>
            <span className="AgreementDivAttachments" style={{borderRight:" 2px solid #F7F7F7", height: "5rem"}}>
                <p className="AgreementDivAttachmentsP1">Signoff Date</p>
                <p className="AgreementDivAttachmentsP2">12-Aug-2023</p>
            </span>
            <span className="AgreementDivAttachments">
                <p className="AgreementDivAttachmentsP1">Renewal Date</p>
                <p className="AgreementDivAttachmentsP2">12-Aug-2023</p>
            </span>
        </div>
    </div>
 
</div>
</div>
            </>
            <>
  {/* clientContact component*/}
  <div className="clientContactDiv">
    <div className="ClientContactDivLast">
      <div style={{ boxShadow: "none" }} className="onBoardPrimaryDetails">
        <p className="onBoardPrimaryDetailsPara">HOD-HR Contact</p>
        <div className="onBoardPrimaryDetailsInputs">
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Name</label>
            <input value={data.hodHrContactName} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="hodHrContactName"  />
          </div>
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Email ID</label>
            <input value={data.hodHrContactEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="hodHrContactEmail"  />
          </div>
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Phone Number</label>
            <input value={data.hodHrContactPhoneNumber} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="hodHrContactPhoneNumber"  />
          </div>
        </div>
      </div>
      <div style={{ boxShadow: "none" }} className="onBoardPrimaryDetails">
        <p className="onBoardPrimaryDetailsPara">HOD-Finance Contact</p>
        <div className="onBoardPrimaryDetailsInputs">
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Name</label>
            <input value={data.hodFinanceContactName} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="hodFinanceContactName"  />
          </div>
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Email ID</label>
            <input value={data.hodFinanceContactEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="hodFinanceContactEmail"  />
          </div>
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Phone Number</label>
            <input value={data.hodFinanceContactPhoneNumber} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="hodFinanceContactPhoneNumber"  />
          </div>
        </div>
      </div>
      <div style={{ boxShadow: "none" }} className="onBoardPrimaryDetails">
        <p className="onBoardPrimaryDetailsPara">Escalation Manager Contact</p>
        <div className="onBoardPrimaryDetailsInputs">
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Name</label>
            <input value={data.escalationManagerContactName} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="escalationManagerContactName"  />
          </div>
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Email ID</label>
            <input value={data.escalationManagerContactEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="escalationManagerContactEmail"  />
          </div>
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Phone Number</label>
            <input value={data.escalationManagerContactPhoneNumber} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="escalationManagerContactPhoneNumber"  />
          </div>
          <div className="onBoardPrimaryDetailsData">
            <label className="onBoardPrimaryDetailsLabel">Designation</label>
            <input value={data.escalationManagerContactDesignation} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="escalationManagerContactDesignation"  />
          </div>
        </div>
      </div>
    </div>
    <div className="clientcontactSecondDiv onBoardPrimaryDetails">
      <div className="onBoardPrimaryDetailsInputs">
        <div className="onBoardPrimaryDetailsData">
          <label className="onBoardPrimaryDetailsLabel">Admin Email</label>
          <input value={data.adminEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="adminEmail"  />
        </div>
        <div className="onBoardPrimaryDetailsData">
          <label className="onBoardPrimaryDetailsLabel">HR Email</label>
          <input value={data.hrEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="hrEmail"  />
        </div>
        <div className="onBoardPrimaryDetailsData">
          <label className="onBoardPrimaryDetailsLabel">Payment Team Email</label>
          <input value={data.paymentTeamEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="paymentTeamEmail"  />
        </div>
      </div>
      {/* <button className="AddNewShippingAddress">+ Add New</button> */}
    </div>
    <div className="onBoardPrimaryDetails">
      <p className="onBoardPrimaryDetailsPara">Interview panel Email ID’s</p>
      <div className="onBoardPrimaryDetailsInputs">
        <div className="onBoardPrimaryDetailsData">
          <label className="onBoardPrimaryDetailsLabel">Name</label>
          <input value={data.interviewPanelName} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="interviewPanelName"  />
        </div>
        <div className="onBoardPrimaryDetailsData">
          <label className="onBoardPrimaryDetailsLabel">Email ID</label>
          <input value={data.interviewPanelEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="interviewPanelEmail"  />
        </div>
      </div>
      {/* <button className="AddNewShippingAddress">+ Add New</button> */}
    </div>
  </div>
</>
</div>

</form>
</div>
</div>
        </>
    )
}
export default ClientPreviewMode;