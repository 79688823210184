import "./CandidateAuth.css";
import Footer from "../../Components/Footer/Footer";
import "./CandidateAuth.css";
import signUpPageImage from "../../Assets/png/face-blck.jpg"
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import instance from "../../Api/index";
import { candidateLoginAPI } from "../../Api/auth";
import JobFooter from "../JarvixJobDashboard/JobFooter";

const LoginCandidate =()=>{
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const showToastError = (message) => {
    toast.error(message);
  };
  const handleFormSubmit = async (data) => {
    if (!email || !password) {
      return showToastError("Enter all the fields");
    }

    try {
      const response = await candidateLoginAPI(data);

      if (response && response.statusCode === 200) {
        handleLoginSuccess(response.data);
      
      } else {
        toast.success("Logged In Successfully!");
        setTimeout(()=>{
          navigate("/add-candidate-details");
        },1000)
      }
    } catch (error) {
      showToastError("Error during login");
    }
  };

  const handleLoginSuccess = (data) => {
    localStorage.setItem("token", data.token);
    instance.defaults.headers.common["Authorization"] = "Bearer " + data.token;
    localStorage.setItem("CANDIDATE_LOGIN_INFO", JSON.stringify(data));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFormSubmit({ email, password });
  };
 

return (
    <>
       <ToastContainer />
     <div className="CandidateRegistorMainDiv" style={{background: "#202430"}}>
    <div className="candidateSignUpMainDiv">
<div className="SignUpMainDiv">
    <div className="LoginMainDivForm">
        <form className="signUpFormForm" onSubmit={handleSubmit}>
<h1 className="signUph1">Candidate SignUp</h1>
<p className="signUpPara">Doesn’t have an account? <Link to="/candidate/signIn" className="signRedirectingA">SignUp</Link> </p>
<div className="SignUpMainDivFormInputs">
    <div className="FormInputsDiv">
    <label className="FormInputslabel">Email ID</label>
    <input className="FormInputsText"  id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)} placeholder="Enter email" type="text"/></div>
    <div className="FormInputsDiv">
    <label className="FormInputslabel">Password</label>
    <input className="FormInputsText" id="password"
                           value={password}
              onChange={(event) => setPassword(event.target.value)}placeholder="Enter Password" type="password"/></div>
   
    <button className="signUpformSubmit" type="submit">Login</button>
<p className="lastLineHrTag"><hr className="hrTagofSignup"/>Or login with <hr className="hrTagofSignup"/></p>
<div className="socialAuthentitor">
    <p>Google</p>
</div>
</div>
        </form>
    </div>
    <div className="SignUpMainDivImagePart"><img src={signUpPageImage}alt="signUpPageImage" className="signUpPageImage"/></div>
</div>

</div>
<div style={{    paddingLeft: "3rem",
    paddingRight: "3rem"}}>
        <JobFooter/>

</div>
</div>
    </>
)
}
export default LoginCandidate;