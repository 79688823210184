import "../CandidateAuth/CandidateAuth.css";
import signUpPageImage from "../../Assets/png/face-blck.jpg"
import { Link ,useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance from  "../../Api/index"
import { HRLoginAPI, HRSignAPI, candidateLoginAPI, candidateSignUpAPI } from "../../Api/auth";
import JobFooter from "../JarvixJobDashboard/JobFooter";
const SignInHR=()=>{
  const [email, setEmail] = useState("");
  const [clientId, setClientId] = useState("");
  const [password, setPassword] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const navigate = useNavigate();

  const showToastError = (message) => {
    toast.error(message);
  };

  const handleSignUp = async (data) => {
    try {
      const resp = await HRSignAPI(data);

      if (resp.statusCode === 200) {
        localStorage.setItem("HR_INFO", JSON.stringify(resp.data));
        const loginData = { email: data.email, password: data.password };
        handleLogin(loginData);
        toast.success("Registered Successfully!");
      } else {
        toast.success("Registered Successfully!");
       setTimeout(()=>{
navigate("/post/job");
       },1000) 
      }
    } catch (error) {
      showToastError("Error during registration");
    }
  };

  const handleLogin = async (data) => {
    try {
      const resp = await HRLoginAPI(data);

      if (resp.statusCode === 200) {
        localStorage.setItem("token", resp.data.token);
        instance.defaults.headers.common["Authorization"] = "Bearer " + resp.data.token;
        localStorage.setItem("HRJOB_LOGIN_INFO", JSON.stringify(resp.data));
        navigate("/post/job");
      } else {
        showToastError(resp?.message);
      }
    } catch (error) {
      showToastError("Error during login");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !clientId || !contactNumber || !password) {
      showToastError("Enter all the fields");
    } else {
      const data = { email, password, clientId, contactNumber };
      handleSignUp(data);
    }
  };
    return (
        <>
        <ToastContainer/>
        <div className="CandidateRegistorMainDiv" style={{background: "#202430"}}>
<div className="candidateSignUpMainDiv">
<div className="SignUpMainDiv">
    <div className="SignUpMainDivForm">
        <form onSubmit={handleSubmit}>
<h1 className="signUph1">HR SignUp</h1>
<p className="signUpPara">Already have an account? <Link to="/hr/login" className="signRedirectingA">Login</Link> </p>
<div className="SignUpMainDivFormInputs">
    <div className="FormInputsDiv">
    <label className="FormInputslabel">Client ID</label>
    <input className="FormInputsText" id="username"
                value={clientId}
                onChange={(event) => setClientId(event.target.value)} placeholder="Enter client ID" type="text"/></div>
    <div className="FormInputsDiv">
    <label className="FormInputslabel">Email ID</label>
    <input className="FormInputsText" id="email"
                value={email} 
                onChange={(event) => setEmail(event.target.value)} placeholder="Enter Email ID" type="email"/></div>
    <div className="FormInputsDiv">
    <label className="FormInputslabel">Password</label>
    <input className="FormInputsText"id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)} placeholder="Enter Password" type="password"/>
    {/* <p className="passwordRecomdation">Minimum of 6 characters, with upper and lowercase and a number or symbol</p> */}
    </div>
    <div className="FormInputsDiv" style={{marginTop:"-1rem"}}>
    <label className="FormInputslabel">Contact Number</label>
    <input className="FormInputsText" id="reEnterPassword"
                value={contactNumber}
                onChange={(event) => setContactNumber(event.target.value)}placeholder="Enter Password" type="text"/></div>
    <button className="signUpformSubmit" type="submit">Sign Up</button>
<p className="lastLineHrTag"><hr className="hrTagofSignup"/>Or Signup with<hr className="hrTagofSignup"/></p>
<div className="socialAuthentitor">
    <p>Google</p>
</div>
</div>
        </form>
    </div>
    <div className="SignUpMainDivImagePart"><img src={signUpPageImage}alt="signUpPageImage" className="signUpPageImage"/></div>
</div>

</div>
<div className="emailVerificationStep">
    <div className="emailVerificationStepDiv">
<h2>
Email Verification
</h2>
<p className="emailVerifyPara">
We will send you a email verification code on your email. Please check and Loren
</p>
<form className="emailVerifyDiv">

<div className="emailVerifyForm">
<label className="verifyInputslabel">Verification Code</label>
    <input className="verifyInputsText" value={""} id="" placeholder="Enter verification code" type="text"/>
</div>
<span><input type="checkbox"/> I agree the terms and conditions</span>
<button className="signUpformSubmit" type="submit">Login</button>
</form>
    </div>

</div>
<div style={{    paddingLeft: "3rem",
    paddingRight: "3rem"}}>

        <JobFooter/>
</div>
</div>
        </>
    )
}

export default SignInHR;