import { Link } from "react-router-dom"
import LatestJobsOpenImgArrow from "../../Assets/png/rightArrowBlue.png"
import FeaturedCompanyLogo from "../../Assets/png/FeaturedCompanyLogo.png"
const FeaturedJobs=()=>{
    return(
        <>
        <div className="FeaturedJobs">
        <div className="LatestJobsOpenheadings">
    <div className="LatestJobsOpenH4">
    Featured<span className="LatestJobsOpenH4Span"> jobs</span>
    </div>
    <Link to="/" className="showAllJobsLink">Show all jobs <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/></Link>
</div>

<div className="FeaturedJobsMainDiv">
    <div className="FeaturedJobsMainDivList">
        <div className="FeaturedJobsListSpan"><img src={FeaturedCompanyLogo} alt="" className="FeaturedJobsListImgs"/>
        <p className="FeaturedJobsjobTime">Full Time</p>
        </div>
        <p className="FeaturedJobsTitle">Email Marketing</p>
        <p className="FeaturedJobsLocation">
        Revolut . Madrid, Spain
        </p>
        <p className="FeaturedJobsDescription">
        ClassPass is looking for Product Designer to help us... 
        </p>
        <div className="FeaturedJobsQualify">
            <p id="FeaturedJobsQualify">Marketing</p>
            <p id="FeaturedJobsQualify1">Design</p>
        </div>
    </div>
    <div className="FeaturedJobsMainDivList">
        <div className="FeaturedJobsListSpan"><img src={FeaturedCompanyLogo} alt="" className="FeaturedJobsListImgs"/>
        <p className="FeaturedJobsjobTime">Full Time</p>
        </div>
        <p className="FeaturedJobsTitle">Email Marketing</p>
        <p className="FeaturedJobsLocation">
        Revolut . Madrid, Spain
        </p>
        <p className="FeaturedJobsDescription">
        ClassPass is looking for Product Designer to help us... 
        </p>
        <div className="FeaturedJobsQualify">
            <p id="FeaturedJobsQualify">Marketing</p>
            <p id="FeaturedJobsQualify1">Design</p>
        </div>
    </div>
    <div className="FeaturedJobsMainDivList">
        <div className="FeaturedJobsListSpan"><img src={FeaturedCompanyLogo} alt="" className="FeaturedJobsListImgs"/>
        <p className="FeaturedJobsjobTime">Full Time</p>
        </div>
        <p className="FeaturedJobsTitle">Email Marketing</p>
        <p className="FeaturedJobsLocation">
        Revolut . Madrid, Spain
        </p>
        <p className="FeaturedJobsDescription">
        ClassPass is looking for Product Designer to help us... 
        </p>
        <div className="FeaturedJobsQualify">
            <p id="FeaturedJobsQualify">Marketing</p>
            <p id="FeaturedJobsQualify1">Design</p>
        </div>
    </div>
    <div className="FeaturedJobsMainDivList">
        <div className="FeaturedJobsListSpan"><img src={FeaturedCompanyLogo} alt="" className="FeaturedJobsListImgs"/>
        <p className="FeaturedJobsjobTime">Full Time</p>
        </div>
        <p className="FeaturedJobsTitle">Email Marketing</p>
        <p className="FeaturedJobsLocation">
        Revolut . Madrid, Spain
        </p>
        <p className="FeaturedJobsDescription">
        ClassPass is looking for Product Designer to help us... 
        </p>
        <div className="FeaturedJobsQualify">
            <p id="FeaturedJobsQualify">Marketing</p>
            <p id="FeaturedJobsQualify1">Design</p>
        </div>
    </div>
    <div className="FeaturedJobsMainDivList">
        <div className="FeaturedJobsListSpan"><img src={FeaturedCompanyLogo} alt="" className="FeaturedJobsListImgs"/>
        <p className="FeaturedJobsjobTime">Full Time</p>
        </div>
        <p className="FeaturedJobsTitle">Email Marketing</p>
        <p className="FeaturedJobsLocation">
        Revolut . Madrid, Spain
        </p>
        <p className="FeaturedJobsDescription">
        ClassPass is looking for Product Designer to help us... 
        </p>
        <div className="FeaturedJobsQualify">
            <p id="FeaturedJobsQualify">Marketing</p>
            <p id="FeaturedJobsQualify1">Design</p>
        </div>
    </div>
    <div className="FeaturedJobsMainDivList">
        <div className="FeaturedJobsListSpan"><img src={FeaturedCompanyLogo} alt="" className="FeaturedJobsListImgs"/>
        <p className="FeaturedJobsjobTime">Full Time</p>
        </div>
        <p className="FeaturedJobsTitle">Email Marketing</p>
        <p className="FeaturedJobsLocation">
        Revolut . Madrid, Spain
        </p>
        <p className="FeaturedJobsDescription">
        ClassPass is looking for Product Designer to help us... 
        </p>
        <div className="FeaturedJobsQualify">
            <p id="FeaturedJobsQualify">Marketing</p>
            <p id="FeaturedJobsQualify1">Design</p>
        </div>
    </div>
    <div className="FeaturedJobsMainDivList">
        <div className="FeaturedJobsListSpan"><img src={FeaturedCompanyLogo} alt="" className="FeaturedJobsListImgs"/>
        <p className="FeaturedJobsjobTime">Full Time</p>
        </div>
        <p className="FeaturedJobsTitle">Email Marketing</p>
        <p className="FeaturedJobsLocation">
        Revolut . Madrid, Spain
        </p>
        <p className="FeaturedJobsDescription">
        ClassPass is looking for Product Designer to help us... 
        </p>
        <div className="FeaturedJobsQualify">
            <p id="FeaturedJobsQualify">Marketing</p>
            <p id="FeaturedJobsQualify1">Design</p>
        </div>
    </div>
    <div className="FeaturedJobsMainDivList">
        <div className="FeaturedJobsListSpan"><img src={FeaturedCompanyLogo} alt="" className="FeaturedJobsListImgs"/>
        <p className="FeaturedJobsjobTime">Full Time</p>
        </div>
        <p className="FeaturedJobsTitle">Email Marketing</p>
        <p className="FeaturedJobsLocation">
        Revolut . Madrid, Spain
        </p>
        <p className="FeaturedJobsDescription">
        ClassPass is looking for Product Designer to help us... 
        </p>
        <div className="FeaturedJobsQualify">
            <p id="FeaturedJobsQualify">Marketing</p>
            <p id="FeaturedJobsQualify1">Design</p>
        </div>
    </div>
</div>
        </div>
        </>
    )
}
export default FeaturedJobs;