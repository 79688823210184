import React from "react";
import "./styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../Pages/Home";
import ScrollToTop from "../ScrollToTop";
import LoginPage from "../Pages/Auth/LoginPage";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import SignUpPage from "../Pages/Auth/SignUpPage";
import SideBar from "../Components/Sidebar/Sidebar";
import SignUpCandidate from "../Pages/CandidateAuth/SignUp";
import LoginCandidate from "../Pages/CandidateAuth/Login";
import FillCandidateDetails from "../Pages/CandidateDetails/index";
import JobSearch from "../Pages/JobSearch/index"
import JarvixJobDashboard from "../Pages/JarvixJobDashboard";
import ClientList from "../Pages/ClientOnBoarding/ClientLists";
import ClientOnBoarding from "../Pages/ClientOnBoarding/OnBoardClient";
import TotalJobs from "../Pages/ClientPostedJobs/TotalJobs";
import ClientAddJob from "../Pages/ClientPostedJobs/ClientAddJob";
import LoginHR from "../Pages/HRAuth/HRLogin";
import SignInHR from "../Pages/HRAuth/HRSignIn";
import { CandidateListing } from "../Pages/CandidateListing";

const Routing = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/candidate/signIn" element={<SignUpCandidate/>}/>
        <Route exact path="/candidate/login" element={<LoginCandidate/>}/>
        <Route exact path="/hr/login" element={<LoginHR/>}/>
      <Route exact path="/hr/signIn" element={<SignInHR/>}/>
        <Route exact path="/add-candidate-details" element={<FillCandidateDetails/>}/>
        <Route exact path="/jobs" element={<JobSearch/>}/>
<Route exact path="/" element={<JarvixJobDashboard/>}/>
<Route exact path="/client-lists" element={<ClientList/>}/>
<Route exact path="/client/onboarding" element={<ClientOnBoarding/>}/>
<Route exact path="/job/types" element={<TotalJobs/>}/>
<Route exact path="/post/job" element={<ClientAddJob/>}/>
        <Route exact path="/corporate_rentals/sign-in" element={<SignUpPage />} />
        <Route exact path="/corporate_rentals/login" element={<LoginPage />} />
        <Route exact path="/corporate_rentals/home" element={<HomePage />} />
        <Route exact path="/auth/forgetpassword" element={<ForgetPassword />} />
        <Route exact path="/corporate_rentals/home/:component" element={<SideBar/>} />
       <Route exact path="/candidate/lists" element={<CandidateListing/>}/>
      </Routes>
    </Router>
  );
};

export default Routing;
