import "./ClientOnBoarding.css"
import { useState } from "react";
import IconAbout from "../../Assets/png/IiconAbout.png"
import dragAndDropImgForFile from "../../Assets/png/fileUploadPdfImg.png"
import ClientPreviewMode from "./ClientPreviewMode";
// import { Storage } from "@google-cloud/storage";
import FileUploadComponent from "./FileUploadComponent";
import { clientAddDetailsAPI, uploadPDFfilesToAPI } from "../../Api/auth";
import { ToastContainer, toast } from "react-toastify";
const ClientOnBoarding = () => {
  // HANDLING PROGRESS BAR
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [saveMode, setSaveMode] = useState(false);
  // TOGGLING INPUTS WITH CHECKBOX
  const [showInputFields, setShowInputFields] = useState({
    showEntryLevelCheck: false,
    showMidLevelCheck: false,
    showSeniorLevelCheck: false,
    showTopManagementLevelCheck: false,
    showMonthlyRateCardCheck: false,
    showGSTValueCheck: false,
  });

  const handleCheckboxClick = (checkboxType) => {
    setShowInputFields((prevState) => ({
      ...prevState,
      [checkboxType]: !prevState[checkboxType],
    }));
  };
  const [selectedEmploymentType, setSelectedEmploymentType] = useState('');
  const [selectedEmploymentType2, setSelectedEmploymentType2] = useState('');
  const [showRemainingEmploymentType, setShowRemainingEmploymentType] = useState(false);



  const handleAddNewTypeClick = () => {
    setShowRemainingEmploymentType(true);
  };
  // PROGRESS BAR STEPS
  const steps = [
    "Primary Details",
    "Compensation",
    "Agreement",
    "Client Contact",
  ];

  // FILTER AGREEMENTS PART WITH BUTTONS
  const [activeFilter, setActiveFilter] = useState("Agreements");

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };
  const handleStepClick = (index) => {
    if (index < currentStep) {
      setCurrentStep(index + 1);
      setCompletedSteps(completedSteps.filter((step) => step < index + 1));
      setPreviewMode(false);
    }
  };

  const handleCancel = () => {
    if (saveMode) {
      setSaveMode(false);
    } else if (previewMode) {
      setPreviewMode(false);
    } else {
      // Handle cancel logic here
    }
  };

 
  const [pdfFile, setPdfFile] = useState(null);
 


  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    // setAgreementFile1(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPdfFile(file);
  };

  const uploadPDFFile = async (file) => {
    try {
      const response = await uploadPDFfilesToAPI(file);

      if (response && response.url) {
        console.log('PDF file uploaded successfully:', response.url);
        return response.url; // Return the URL of the uploaded file
      } else {
        console.error('PDF file upload failed.');
        // Handle the error, e.g., show an error message to the user
        return null;
      }
    } catch (error) {
      console.error('An error occurred while uploading PDF file:', error);
      // Handle the error, e.g., show an error message to the user
      return null;
    }
  };                                    
  const addShippingAddress = () => {
    setShippingAddress((prevAddresses) => [
      ...prevAddresses,
      { name: "", designation: "", TAN: "", address: "", contactNumber: "", PAN: "", GST: "", ccInvoiceEmail: "", invoiceEmail: "" }
    ]);
  };

  const addBillingAddress = () => {
    setBillingAddress((prevAddresses) => [
      ...prevAddresses,
      { name: "", designation: "", TAN: "", address: "", contactNumber: "", PAN: "", GST: "", ccInvoiceEmail: "", invoiceEmail: "" }
    ]);
  };
  const addNewAdminEmail = (e) => {
    e.preventDefault();
    setEmails((prevQuestions) => [
      ...prevQuestions,
      {paymentTeamEmail:'',hrEmail:'',adminEmail:''},
    ]);
  };
  const addNewInterviewEmails = (e) => {
    e.preventDefault();
    setInterviewPanelEmails((prevQuestions) => [
      ...prevQuestions,
      {
        interviewPanelEmail:'',interviewPanelName:''
      },
    ]);
  };
  const [postClientDetails, setPostClientDetails] = useState({});
  const [shippingAddress, setShippingAddress] = useState([
    { type: "shipping",name:'',designation:'',TAN:'',address:'',contactNumber:'',PAN:'',GST:'',ccInvoiceEmail:'',invoiceEmail:'' }
  ]);
  const [billingAddress, setBillingAddress] = useState([
    { type: "billing",name:'',designation:'',TAN:'',address:'',contactNumber:'',PAN:'',GST:'',ccInvoiceEmail:'',invoiceEmail:'' }
  ]);
  const [bankDetails, setBankDetails] = useState({});
  const [compensation1, setCompensation1] = useState({});
  const [compensation2, setCompensation2] = useState({});
  const [HOD_Hrcontact, setHOD_Hrcontact] = useState({});
  const [HOD_finace, setHOD_finace] = useState({});
  const [EscalationManager, setEscalationManager] = useState({});
  const [emails, setEmails] = useState([
    {paymentTeamEmail:'',hrEmail:'',adminEmail:''}
  ]);
  // const [hrEmails, setHrEmails] = useState([]);
  // const [paymentTeamEmails, setPaymentTeamEmails] = useState([]);
  const [interviewPanelEmails, setInterviewPanelEmails] = useState([{
    interviewPanelEmail:'',interviewPanelName:''
  }]);
  const copyShippingToBilling = (index) => {
    const selectedShippingAddress = { ...shippingAddress[index] };
    setBillingAddress((prevAddresses) => [...prevAddresses, selectedShippingAddress]);
  };

  const copyBillingToShipping = (index) => {
    const selectedBillingAddress = { ...billingAddress[index] };
    setShippingAddress((prevAddresses) => [...prevAddresses, selectedBillingAddress]);
  };
  const handleInputChange = (e) => {
    setPostClientDetails({
      ...postClientDetails,
      [e.target.name]: e.target.value,
    });
  };
  const sendDataToApi = async () => {
     // Upload PDF file if it exists
     let pdfUrl = null;
     if (pdfFile) {
       pdfUrl = await uploadPDFFile(pdfFile);
       console.log('pdfUrl after uploadPDFFile:', pdfUrl);
     }
    const data = {
      ...postClientDetails,
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
      bankDetails: bankDetails,
      compensation1: [compensation1, compensation2],
      HOD_Hrcontact: HOD_Hrcontact,
      HOD_finace: HOD_finace,
      EscalationManager: EscalationManager,
      pdfFile:pdfUrl,
      emails:emails,
      // hrEmails:hrEmails,
      // paymentTeamEmails:paymentTeamEmails,
      interviewPanelEmails:interviewPanelEmails
    }
    console.log('Data to be sent:', data);
    try {
      const response = await clientAddDetailsAPI(data);

      // Handle the response here, e.g., show a success message
      console.log('Data sent successfully:', response);

    } catch (error) {
      console.error('Error sending data:', error);
      // Handle error, e.g., show an error message
    }
  };
  // Validation rules for each step
  const validateStep1 = () => {
    return postClientDetails.clientId && postClientDetails.clientName && postClientDetails.department && postClientDetails.domain && postClientDetails.websiteLink ;
  };

  const validateStep2 = () => {
    return HOD_finace.name && HOD_finace.email && HOD_finace.phoneNumber;
  };

  const validateStep3 = () => {
    return EscalationManager.name && EscalationManager.email && EscalationManager.phoneNumber && EscalationManager.designation;
  };

  const validateStep4 = () => {
    return;
  };

  const handleSaveAndNext = async() => {
    // let isStepValid = false;

    // switch (currentStep) {
    //   case 1:
    //     isStepValid = validateStep1();
    //     break;

    //   case 2:
    //     isStepValid = validateStep2();
    //     break;

    //   case 3:
    //     isStepValid = validateStep3();
    //     break;

    //   case 4:
    //     isStepValid = validateStep4();
    //     break;

    //   default:
    //     break;
    // }

    // if (!isStepValid) {
    //   toast.error("Please fill in all the details before proceeding.");
    //   return;
    // }

    if (currentStep < steps.length) {
      // Check if the current step is within the total steps
      const updatedCompletedSteps = [...completedSteps];
      updatedCompletedSteps.push(currentStep);
      setCompletedSteps(updatedCompletedSteps);

      // Proceed to the next step
      setCurrentStep(currentStep + 1);
      // if (activeFilter === "Agreements") {
      //   // Upload PDF file
      //   if (pdfFile) {
      //     await uploadPDFFile(pdfFile);
      //   }
      // }
    } else if (currentStep === steps.length && !previewMode) {
      // If on the last step and not in preview mode, switch to preview mode
      setPreviewMode(true);
    } else {
      sendDataToApi()
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            {/* primaryDetails */}
            {/* Your primary details component */}
            <div className="onBoardPrimaryDetails">
              <p className="onBoardPrimaryDetailsPara">Client Details </p>
              <div className="onBoardPrimaryDetailsInputs">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Client ID</label>
                  <input
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="clientId"
                    value={postClientDetails.clientId}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Client Name</label>
                  <input value={postClientDetails.clientName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="clientName"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Client Industry</label>
                  <select
                    className="onBoardPrimaryDetailsInputData"
                    name="clientIndustry"
                    onChange={handleInputChange} value={postClientDetails.clientIndustry}
                  >
                    <option>select</option>
                  </select>
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Name</label>
                  <input value={postClientDetails.clientName1}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="name"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Email ID</label>
                  <input value={postClientDetails.clientEmailID}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="email"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Phone Number</label>
                  <input value={postClientDetails.clientPhoneNumber}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="contactNumber"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            {/* Business Profile */}
            <div className="onBoardPrimaryDetails">
              <p className="onBoardPrimaryDetailsPara">Business Profile </p>
              <div className="onBoardPrimaryDetailsInputs">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Department</label>
                  <input value={postClientDetails.department}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="department"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Domain</label>
                  <select
                    className="onBoardPrimaryDetailsInputData"
                    name="domain"
                    onChange={handleInputChange} value={postClientDetails.domain}
                  >
                    <option>select</option>
                    <option>selehjgct</option>
                  </select>
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Website Link</label>
                  <input value={postClientDetails.websiteLink}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="websiteLink"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            {/* Shipping Address */}
            {shippingAddress.map((item,index)=>(
            <div key={index} className="onBoardPrimaryDetails">
              <span className="onBoardingPrimaryDetailsSpanH">
                <p className="onBoardPrimaryDetailsPara">{`Shipping Address-${index + 1}`} </p>
                <p className="ShippingAddressSpanP">
                  <input
                    type="checkbox"onClick={() => copyShippingToBilling(index)}
                    className="onBoardPrimaryDetailsCheckbox"
                  />
                  &nbsp;Same as billing address
                </p>
              </span>
              <div className="onBoardPrimaryDetailsInputs onBoardPrimaryDetailsInputsTwo">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Name</label>
                  <input value={item.name}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="name"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], name: selectedOption };
                                    return updatedQuestions;
                                  });
                                }} 
                    
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Designation</label>
                  <input value={item.designation}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="designation"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], designation: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Contact</label>
                  <input value={item.contactNumber}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="contactNumber"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], contactNumber: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Address</label>
                  <input value={item.address}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="address"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], address: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">TAN</label>
                  <input value={item.TAN}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="TAN"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], TAN: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">PAN</label>
                  <input value={item.PAN}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="PAN"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], PAN: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">GST</label>
                  <input value={item.GST}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="GST"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], GST: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    Invoice Email ID
                  </label>
                  <input value={item.invoiceEmail}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="invoiceEmail"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], invoiceEmail: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    CC Invoice Email ID
                  </label>
                  <input value={item.ccInvoiceEmail}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="ccInvoiceEmail"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setShippingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], ccInvoiceEmail: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
              </div>
              <p onClick={addShippingAddress}  className="AddNewShippingAddress">+ Add New Address</p>
            </div>

           ) )}
            {/* Billing Address */}
            {billingAddress.map((item,index)=>(
            <div key={index} className="onBoardPrimaryDetails">
              <span className="onBoardingPrimaryDetailsSpanH">
                <p className="onBoardPrimaryDetailsPara">{`Billing Address-${index + 1}`}</p>
                <p className="ShippingAddressSpanP">
                  <input
                    type="checkbox"  onClick={() => copyBillingToShipping(index)}
                    className="onBoardPrimaryDetailsCheckbox"
                  />
                  &nbsp;Same as shipping address
                </p>
              </span>
              <div className="onBoardPrimaryDetailsInputs onBoardPrimaryDetailsInputsTwo">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Name</label>
                  <input value={item.name}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="name"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], name: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Designation</label>
                  <input value={item.designation}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="designation"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], designation: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Contact</label>
                  <input value={item.contactNumber}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="contactNumber"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], contactNumber: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Address</label>
                  <input value={item.address}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="address"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], address: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">TAN</label>
                  <input value={item.TAN}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="TAN"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], TAN: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">PAN</label>
                  <input value={item.PAN}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="PAN"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], PAN: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">GST</label>
                  <input value={item.GST}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="GST"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], GST: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    Invoice Email ID
                  </label>
                  <input value={item.invoiceEmail}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="invoiceEmail"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], invoiceEmail: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    CC Invoice Email ID
                  </label>
                  <input value={item.ccInvoiceEmail}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="ccInvoiceEmail"
                     onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setBillingAddress((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], ccInvoiceEmail: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                  />
                </div>
              </div>
              <p className="AddNewShippingAddress"onClick={addBillingAddress}>+ Add New Address</p>
            </div>))}
            {/* Bank Details */}
            <div className="onBoardPrimaryDetails">
              <p className="onBoardPrimaryDetailsPara">Bank Details</p>
              <div className="onBoardPrimaryDetailsInputs">
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Account Name</label>
                  <input value={bankDetails.accountName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="accountName"
                    onChange={(e) => { setBankDetails({ ...bankDetails, [e.target.name]: e.target.value }) }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Account Number</label>
                  <input value={bankDetails.accountNumber}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="accountNumber"
                    onChange={(e) => { setBankDetails({ ...bankDetails, [e.target.name]: e.target.value }) }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">
                    Reenter Account Number
                  </label>
                  <input value={bankDetails.reenterAccountNumber}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="reenterAccountNumber"
                    onChange={(e) => { setBankDetails({ ...bankDetails, [e.target.name]: e.target.value }) }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Bank Name</label>
                  <input value={bankDetails.bankName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="bankName"
                    onChange={(e) => { setBankDetails({ ...bankDetails, [e.target.name]: e.target.value }) }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">Branch Name</label>
                  <input value={bankDetails.branchName}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="branchName"
                    onChange={(e) => { setBankDetails({ ...bankDetails, [e.target.name]: e.target.value }) }}
                  />
                </div>
                <div className="onBoardPrimaryDetailsData">
                  <label className="onBoardPrimaryDetailsLabel">IFSC Code</label>
                  <input value={bankDetails.IFSC}
                    className="onBoardPrimaryDetailsInputData"
                    type="text"
                    placeholder="Enter"
                    name="IFSC"
                    onChange={(e) => { setBankDetails({ ...bankDetails, [e.target.name]: e.target.value }) }}
                  />
                </div>
              </div>
            </div>
          </>

        );
      case 2:
        return (
          <>
            {/* compensation component */}
            <div className="CompensationDiv">
              <div className="CompensationDivHeader">
                <div className="Employmenttype">
                  <label className="EmploymenttypeLabel">Employment Type</label>
                  <select
                    className="EmploymenttypeSelect"
                    name="employmentType"
                    onChange={(e) => {
                      setCompensation1({ ...compensation1, [e.target.name]: e.target.value });
                      setSelectedEmploymentType(e.target.value);
                    }}
                    value={compensation1.employmentType}
                  >
                    <option value="select">select</option>
                    <option value="Permanent">Permanent</option>
                    <option value="Contingent">Contingent / Managed Services</option>
                  </select>
                </div>
                {selectedEmploymentType === 'Permanent' && (
                  <div className="percentageValue">
                    <p className="percentageValuePara">
                      Percentage Value Excluding 18% GST <img src={IconAbout} alt="About Icon" />
                    </p>
                    <div className="percentageValueParaDiv">
                      <div className="percentageValueParaDivWithInput">
                        <span className="percentageValueSpan">
                          <input
                            type="checkbox"
                            onClick={() => handleCheckboxClick('showEntryLevelCheck')}
                            className="percentageValueParaCheckbox"
                          />
                          Entry / Junior Level
                        </span>
                        {showInputFields.showEntryLevelCheck && (
                          <input
                            value={compensation1.juniorLevel}
                            type="text"
                            className="onBoardPrimaryDetailsInputData"
                            name="juniorLevel"
                            onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }}
                          />
                        )}
                      </div>
                      <div className="percentageValueParaDivWithInput">
                        <span className="percentageValueSpan">
                          <input
                            type="checkbox"
                            onClick={() => handleCheckboxClick('showMidLevelCheck')}
                            className="percentageValueParaCheckbox"
                          />
                          Mid / Associate Level
                        </span>
                        {showInputFields.showMidLevelCheck && (
                          <input
                            value={compensation1.midLevel}
                            type="text"
                            className="onBoardPrimaryDetailsInputData"
                            name="midLevel"
                            onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }}
                          />
                        )}
                      </div>
                      <div className="percentageValueParaDivWithInput">
                        <span className="percentageValueSpan">
                          <input
                            type="checkbox"
                            onClick={() => handleCheckboxClick('showSeniorLevelCheck')}
                            className="percentageValueParaCheckbox"
                          />
                          Senior Level
                        </span>
                        {showInputFields.showSeniorLevelCheck && (
                          <input
                            value={compensation1.seniorLevel}
                            type="text"
                            className="onBoardPrimaryDetailsInputData"
                            name="seniorLevel"
                            onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }}
                          />
                        )}
                      </div>
                      <div className="percentageValueParaDivWithInput">
                        <span className="percentageValueSpan">
                          <input
                            type="checkbox"
                            onClick={() => handleCheckboxClick('showTopManagementLevelCheck')}
                            className="percentageValueParaCheckbox"
                          />
                          Top Management Level
                        </span>
                        {showInputFields.showTopManagementLevelCheck && (
                          <input
                            value={compensation1.topManagementLevel}
                            type="text"
                            className="onBoardPrimaryDetailsInputData"
                            name="topManagementLevel"
                            onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="percentageValuePrimarDetails">
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Invoice Raised Days</label>
                        <input value={compensation1.invoiceRaisedDays1} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="invoiceRaisedDays" onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }} />
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Client Payable Days</label>
                        <input value={compensation1.clientPayableDays1} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="clientPayableDays" onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }} />
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Candidate Retention Days</label>
                        <input value={compensation1.candidateRetentionDays} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="candidateRetentionDays" onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }} />
                      </div>
                    </div>
                  </div>)}
                {selectedEmploymentType === 'Contingent' && (
                  <div className="percentageValue">
                    <p className="percentageValuePara">
                      CTC - INR Value Excluding 18% GST<img src={IconAbout} alt="About Icon" />
                    </p>
                    <div className="percentageValueParaDiv">
                      <div className="percentageValueParaDivWithInput">
                        <span className="percentageValueSpan">
                          <input
                            type="checkbox"
                            onClick={() => handleCheckboxClick('showMonthlyRateCardCheck')}
                            className="percentageValueParaCheckbox"
                          />
                          Monthly Rate Card
                        </span>
                        {showInputFields.showMonthlyRateCardCheck && (
                          <input
                            value={compensation1.monthlyRateCard}
                            type="text"
                            className="onBoardPrimaryDetailsInputData"
                            name="monthlyRateCard"
                            onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }}
                          />
                        )}
                      </div>
                      <div className="percentageValueParaDivWithInput">
                        <span className="percentageValueSpan">
                          <input
                            type="checkbox"
                            onClick={() => handleCheckboxClick('showGSTValueCheck')}
                            className="percentageValueParaCheckbox"
                          />
                          GST Value
                        </span>
                        {showInputFields.showGSTValueCheck && (
                          <input
                            value={compensation1.GST}
                            type="text"
                            className="onBoardPrimaryDetailsInputData"
                            name="GST"
                            onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }}
                          />
                        )}
                      </div>
                      {/* Add similar sections for other checkboxes */}
                    </div>
                    <div className="percentageValuePrimarDetails">
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Invoice Raised Days</label>
                        <input value={postClientDetails.invoiceRaisedDays} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="invoiceRaisedDays" onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }} />
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Client Payable Days</label>
                        <input value={postClientDetails.clientPayableDays2} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="clientPayableDays" onChange={(e) => { setCompensation1({ ...compensation1, [e.target.name]: e.target.value }) }} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {showRemainingEmploymentType && (
                <div className="CompensationDivHeader">
                  <div className="Employmenttype">
                    <label className="EmploymenttypeLabel">Employment Type</label>
                    <select
                      className="EmploymenttypeSelect"
                      name="employmentType2"
                      onChange={(e) => {
                        setCompensation2({ ...compensation2, [e.target.value]: e.target.value });
                        setSelectedEmploymentType2(e.target.value);
                      }}
                      value={postClientDetails.employmentType2}
                    >
                      <option value="select">select</option>
                      <option value="Permanent">Permanent</option>
                      <option value="Contingent">Contingent / Managed Services</option>
                    </select>
                  </div>
                  {selectedEmploymentType2 === 'Permanent' && (
                    <div className="percentageValue">
                      <p className="percentageValuePara">
                        Percentage Value Excluding 18% GST <img src={IconAbout} alt="About Icon" />
                      </p>
                      <div className="percentageValueParaDiv">
                        <div className="percentageValueParaDivWithInput">
                          <span className="percentageValueSpan">
                            <input
                              type="checkbox"
                              onClick={() => handleCheckboxClick('showEntryLevelCheck')}
                              className="percentageValueParaCheckbox"
                            />
                            Entry / Junior Level
                          </span>
                          {showInputFields.showEntryLevelCheck && (
                            <input
                              value={compensation2.juniorLevel}
                              type="text"
                              className="onBoardPrimaryDetailsInputData"
                              name="juniorLevel"
                              onChange={(e) => {
                                setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                              }}
                            />
                          )}
                        </div>
                        <div className="percentageValueParaDivWithInput">
                          <span className="percentageValueSpan">
                            <input
                              type="checkbox"
                              onClick={() => handleCheckboxClick('showMidLevelCheck')}
                              className="percentageValueParaCheckbox"
                            />
                            Mid / Associate Level
                          </span>
                          {showInputFields.showMidLevelCheck && (
                            <input
                              value={compensation2.associateLevel}
                              type="text"
                              className="onBoardPrimaryDetailsInputData"
                              name="associateLevel"
                              onChange={(e) => {
                                setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                              }}
                            />
                          )}
                        </div>
                        <div className="percentageValueParaDivWithInput">
                          <span className="percentageValueSpan">
                            <input
                              type="checkbox"
                              onClick={() => handleCheckboxClick('showSeniorLevelCheck')}
                              className="percentageValueParaCheckbox"
                            />
                            Senior Level
                          </span>
                          {showInputFields.showSeniorLevelCheck && (
                            <input
                              value={compensation2.seniorLevel}
                              type="text"
                              className="onBoardPrimaryDetailsInputData"
                              name="seniorLevel"
                              onChange={(e) => {
                                setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                              }}
                            />
                          )}
                        </div>
                        <div className="percentageValueParaDivWithInput">
                          <span className="percentageValueSpan">
                            <input
                              type="checkbox"
                              onClick={() => handleCheckboxClick('showTopManagementLevelCheck')}
                              className="percentageValueParaCheckbox"
                            />
                            Top Management Level
                          </span>
                          {showInputFields.showTopManagementLevelCheck && (
                            <input
                              value={compensation2.topManagementLevel}
                              type="text"
                              className="onBoardPrimaryDetailsInputData"
                              name="topManagementLevel"
                              onChange={(e) => {
                                setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="percentageValuePrimarDetails">
                        <div className="onBoardPrimaryDetailsData">
                          <label className="onBoardPrimaryDetailsLabel">Invoice Raised Days</label>
                          <input value={compensation2.invoiceRaisedDays} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="invoiceRaisedDays" onChange={(e) => {
                            setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                          }} />
                        </div>
                        <div className="onBoardPrimaryDetailsData">
                          <label className="onBoardPrimaryDetailsLabel">Client Payable Days</label>
                          <input value={compensation2.clientPayableDays} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="clientPayableDays" onChange={(e) => {
                            setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                          }} />
                        </div>
                        <div className="onBoardPrimaryDetailsData">
                          <label className="onBoardPrimaryDetailsLabel">Candidate Retention Days</label>
                          <input value={compensation2.candidateRetentionDays} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="candidateRetentionDays" onChange={(e) => {
                            setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                          }} />
                        </div>
                      </div>
                    </div>)}
                  {selectedEmploymentType2 === 'Contingent' && (
                    <div className="percentageValue">
                      <p className="percentageValuePara">
                        CTC - INR Value Excluding 18% GST<img src={IconAbout} alt="About Icon" />
                      </p>
                      <div className="percentageValueParaDiv">
                        <div className="percentageValueParaDivWithInput">
                          <span className="percentageValueSpan">
                            <input
                              type="checkbox"
                              onClick={() => handleCheckboxClick('showMonthlyRateCardCheck')}
                              className="percentageValueParaCheckbox"
                            />
                            Monthly Rate Card
                          </span>
                          {showInputFields.showMonthlyRateCardCheck && (
                            <input
                              value={compensation2.monthlyRateCard}
                              type="text"
                              className="onBoardPrimaryDetailsInputData"
                              name="monthlyRateCard"
                              onChange={(e) => {
                                setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                              }}
                            />
                          )}
                        </div>
                        <div className="percentageValueParaDivWithInput">
                          <span className="percentageValueSpan">
                            <input
                              type="checkbox"
                              onClick={() => handleCheckboxClick('showGSTValueCheck')}
                              className="percentageValueParaCheckbox"
                            />
                            GST Value
                          </span>
                          {showInputFields.showGSTValueCheck && (
                            <input
                              value={compensation2.GST}
                              type="text"
                              className="onBoardPrimaryDetailsInputData"
                              name="GST"
                              onChange={(e) => {
                                setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                              }}
                            />
                          )}
                        </div>
                        {/* Add similar sections for other checkboxes */}
                      </div>
                      <div className="percentageValuePrimarDetails">
                        <div className="onBoardPrimaryDetailsData">
                          <label className="onBoardPrimaryDetailsLabel">Invoice Raised Days</label>
                          <input value={compensation2.invoiceRaisedDays2} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="invoiceRaisedDays" onChange={(e) => {
                            setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                          }} />
                        </div>
                        <div className="onBoardPrimaryDetailsData">
                          <label className="onBoardPrimaryDetailsLabel">Client Payable Days</label>
                          <input value={compensation2.clientPayableDays2} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="clientPayableDays" onChange={(e) => {
                            setCompensation2({ ...compensation2, [e.target.value]: e.target.value })
                          }} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>)}
              {!showRemainingEmploymentType && (
                <p className="AddNewShippingAddress" onClick={handleAddNewTypeClick}>+ Add New Type</p>)}
            </div>
          </>
        );

      case 3:
        return (
          <>
            {/* agreement component */}
            <div className="AgreementDivHeader">
              <div className="AgreementDiv">
                <div className="AgrrementDivFilterButton">
                  <p className={`AgreementDivButtons ${activeFilter === "Agreements" ? "activeFilter" : ""
                    }`} onClick={() => handleFilterClick("Agreements")}>Agreements</p>
                  <p className={`AgreementDivButtons ${activeFilter === "Attachments" ? "activeFilter" : ""
                    }`} onClick={() => handleFilterClick("Attachments")}>Attachments</p>
                  <p className={`AgreementDivButtons ${activeFilter === "MailTimeline" ? "activeFilter" : ""
                    }`} onClick={() => handleFilterClick("MailTimeline")}>Mail Timeline</p>
                </div>
                {/* agreement file upload */}
                {activeFilter === "Agreements" && (
                  <div className="AgreementDivDetails">
                    <label id="fileUploadLastAgreement" className="fileUploadClassname">
                      <input type="file"  onChange={handleFileChange}
                        name="file" style={{ display: "none" }} id="fileUploadLastAgreement" />
                      <img src={dragAndDropImgForFile} className="dragAndDropImgForFile" />
                    </label>
                    {/* <FileUploadComponent/> */}
                    <div className="AgreementDivAttachments">
                      <span className="AgreementDivAttachments borderRightAttacchments" >
                        <p className="AgreementDivAttachmentsP1">{pdfFile?.name ? pdfFile.name : "Add a pdf file"}</p>
                        <p className="AgreementDivAttachmentsP2">2.3 MB</p>
                      </span>
                      <span className="AgreementDivAttachments borderRightAttacchments" >
                        <p className="AgreementDivAttachmentsP1">Signoff Date</p>
                        <p className="AgreementDivAttachmentsP2">12-Aug-2023</p>
                      </span>
                      <span className="AgreementDivAttachments">
                        <p className="AgreementDivAttachmentsP1">Renewal Date</p>
                        <p className="AgreementDivAttachmentsP2">12-Aug-2023</p>
                      </span>
                    </div>
                  </div>
                )}
                {/* all attachments */}
                {activeFilter === "Attachments" && (<div className="AttachmentsDivTable">
                  <label id="fileUploadLastAgreement" className="fileUploadClassname">
                    <input type="file" onChange={handleFileChange1}
                      name="agreementFile1" style={{ display: "none" }} id="fileUploadLastAgreement" />
                    <img src={dragAndDropImgForFile} className="dragAndDropImgForFile" />
                  </label>
                  <div className="ClientListMainDivTable">
                    <div className="ClientListTableThead">
                      <div className="clientListFirstTR">
                        <p className="ClientListTableTH">Document Type  <i class="fa-solid fa-sort faSolidIconSort"></i></p>
                        <p className="ClientListTableTH">Document Title  <i class="fa-solid fa-sort faSolidIconSort"></i></p>
                        <p className="ClientListTableTH">Document Date  <i class="fa-solid fa-sort faSolidIconSort"></i></p>
                        <p className="ClientListTableTH">Invoice Date <i class="fa-solid fa-sort faSolidIconSort"></i></p>
                        <p className="ClientListTableTH">Payment Date <i class="fa-solid fa-sort faSolidIconSort"></i></p>
                        <p className="ClientListTableTH">Status <i class="fa-solid fa-sort faSolidIconSort"></i></p>
                      </div>
                    </div>
                    <div className="ClientListTableTbody">
                      <div className="clientListSecondTR">
                        <p className="clientListTD">Agreement</p>
                        <p className="clientListTD">Filename 1</p>
                        <p className="clientListTD">12-Aug-2023</p>
                        <p className="clientListTD">12-Aug-2023</p>
                        <p className="clientListTD">08-Aug-2023</p>
                        <p className="clientListTD">Completed</p>
                      </div>

                    </div>
                  </div>
                </div>)}
                {/* mail timeline */}
                {activeFilter === "MailTimeline" && (<div className="MainTimeLineDivHeaderMain">
                  <div className="mailTimlineDiv">
                    <div className="mailTimelneDateAndTimeDiv">
                      <p className="mailTimelneDocName">Doc Name</p>
                      <p className="mailTimelneDateAndTime">12 Jul 2023 | 12:34</p>
                    </div>
                    <p className="mailTimelineHeading">usam et justo duo dolores et ea rebum. Stet clita  </p>
                    <p className="mailTimelineDescription">usam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos</p>
                  </div>
                  <div className="mailTimlineDiv">
                    <div className="mailTimelneDateAndTimeDiv">
                      <p className="mailTimelneDocName">Doc Name</p>
                      <p className="mailTimelneDateAndTime">12 Jul 2023 | 12:34</p>
                    </div>
                    <p className="mailTimelineHeading">usam et justo duo dolores et ea rebum. Stet clita  </p>
                    <p className="mailTimelineDescription">usam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos</p>
                  </div>
                </div>)}
              </div>
            </div>
          </>
        );

      case 4:
        return (
          <>
            {/* clientContact component*/}
            <div className="clientContactDiv">
              <div className="ClientContactDivLast">
                <div style={{ boxShadow: "none" }} className="onBoardPrimaryDetails">
                  <p className="onBoardPrimaryDetailsPara">HOD-HR Contact</p>
                  <div className="onBoardPrimaryDetailsInputs">
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Name</label>
                      <input value={HOD_Hrcontact.name} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="name" onChange={(e) => { setHOD_Hrcontact({ ...HOD_Hrcontact, [e.target.value]: e.target.value }) }} />
                    </div>
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Email ID</label>
                      <input value={HOD_Hrcontact.email} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="email" onChange={(e) => { setHOD_Hrcontact({ ...HOD_Hrcontact, [e.target.value]: e.target.value }) }} />
                    </div>
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Phone Number</label>
                      <input value={HOD_Hrcontact.phoneNumber} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="phoneNumber" onChange={(e) => { setHOD_Hrcontact({ ...HOD_Hrcontact, [e.target.value]: e.target.value }) }} />
                    </div>
                  </div>
                </div>
                <div style={{ boxShadow: "none" }} className="onBoardPrimaryDetails">
                  <p className="onBoardPrimaryDetailsPara">HOD-Finance Contact</p>
                  <div className="onBoardPrimaryDetailsInputs">
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Name</label>
                      <input value={HOD_finace.name} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="name" onChange={(e) => { setHOD_finace({ ...HOD_finace, [e.target.value]: e.target.value }) }} />
                    </div>
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Email ID</label>
                      <input value={HOD_finace.email} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="email" onChange={(e) => { setHOD_finace({ ...HOD_finace, [e.target.value]: e.target.value }) }} />
                    </div>
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Phone Number</label>
                      <input value={HOD_finace.phoneNumber} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="phoneNumber" onChange={(e) => { setHOD_finace({ ...HOD_finace, [e.target.value]: e.target.value }) }} />
                    </div>
                  </div>
                </div>
                <div style={{ boxShadow: "none" }} className="onBoardPrimaryDetails">
                  <p className="onBoardPrimaryDetailsPara">Escalation Manager Contact</p>
                  <div className="onBoardPrimaryDetailsInputs">
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Name</label>
                      <input value={EscalationManager.name} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="name" onChange={(e) => { setEscalationManager({ ...EscalationManager, [e.target.value]: e.target.value }) }} />
                    </div>
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Email ID</label>
                      <input value={EscalationManager.email} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="email" onChange={(e) => { setEscalationManager({ ...EscalationManager, [e.target.value]: e.target.value }) }} />
                    </div>
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Phone Number</label>
                      <input value={EscalationManager.phoneNumber} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="phoneNumber" onChange={(e) => { setEscalationManager({ ...EscalationManager, [e.target.value]: e.target.value }) }} />
                    </div>
                    <div className="onBoardPrimaryDetailsData">
                      <label className="onBoardPrimaryDetailsLabel">Designation</label>
                      <input value={EscalationManager.designation} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="designation" onChange={(e) => { setEscalationManager({ ...EscalationManager, [e.target.value]: e.target.value }) }} />
                    </div>
                  </div>
                </div>
              </div>
              {emails.map((item,index)=>(
              <div key={index} className="clientcontactSecondDiv onBoardPrimaryDetails">
                <div className="onBoardPrimaryDetailsInputs">
                  <div className="onBoardPrimaryDetailsData">
                    <label className="onBoardPrimaryDetailsLabel">Admin Email</label>
                    <input value={item.adminEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="adminEmail"onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setEmails((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], adminEmail: selectedOption };
                                    return updatedQuestions;
                                  });
                                }} />
                  </div>
                  <div className="onBoardPrimaryDetailsData">
                    <label className="onBoardPrimaryDetailsLabel">HR Email</label>
                    <input value={item.hrEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="hrEmail"  onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setEmails((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], hrEmail: selectedOption };
                                    return updatedQuestions;
                                  });
                                }} 
                 
                   />
                  </div>
                  <div className="onBoardPrimaryDetailsData">
                    <label className="onBoardPrimaryDetailsLabel">Payment Team Email</label>
                    <input value={item.paymentTeamEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="paymentTeamEmail" onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setEmails((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], paymentTeamEmail: selectedOption };
                                    return updatedQuestions;
                                  });
                                }} />
                  </div>
                </div>
                <button className="AddNewShippingAddress" onClick={addNewAdminEmail} >+ Add New</button>
              </div>))}
              {interviewPanelEmails.map((item,index)=>(
              <div key={index}className="onBoardPrimaryDetails">
                <p className="onBoardPrimaryDetailsPara"> {`Interview panel Email ID’s-${index + 1}`}</p>
                <div className="onBoardPrimaryDetailsInputs">
                  <div className="onBoardPrimaryDetailsData">
                    <label className="onBoardPrimaryDetailsLabel">Name</label>
                    <input value={item.interviewPanelName} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="interviewPanelName" onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setInterviewPanelEmails((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], interviewPanelName: selectedOption };
                                    return updatedQuestions;
                                  });
                                }} />
                  </div>
                  <div className="onBoardPrimaryDetailsData">
                    <label className="onBoardPrimaryDetailsLabel">Email ID</label>
                    <input value={item.interviewPanelEmail} className="onBoardPrimaryDetailsInputData" type="text" placeholder="Enter" name="interviewPanelEmail" onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setInterviewPanelEmails((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], interviewPanelEmail: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}  />
                  </div>
                </div>
                <button className="AddNewShippingAddress" onClick={addNewInterviewEmails}>+ Add New</button>
              </div>))}
            </div>
          </>

        );
      default:
        return null;
    }
  };

 
  return (
    <>
    <ToastContainer />
      <div className="onBoardClientHeaderMain">
        <div className="onBoardClientHeader">
          <h1 className="onBoardClientHeaderH1">Client Onboarding</h1>
          <form className="onBoardClientHeaderForm" onSubmit={sendDataToApi}>
            
            {previewMode ? "" : (
              <div className="onBoardClientProgressBar">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className={`progressBarP ${index + 1 === currentStep ? "activeStep" : ""} ${completedSteps.includes(index + 1) ? "completedStep" : ""
                    }`}
                  onClick={() => handleStepClick(index)}
                >
                  <span className="tickMarkStep">
                    {completedSteps.includes(index + 1) ? (
                      <span><i class="fa-solid fa-check spanCheckIcon"></i></span> // Tick mark for completed steps
                    ) : (
                      index + 1
                    )}
                  </span>
                  <p className="stepHeadingsMain">{step}</p>
                </div>
              ))}
            </div>
            )}
            {/* primaryDetails */}
            <div className="clientDetailsSlider">

              {previewMode ? <ClientPreviewMode data={postClientDetails} /> : renderStep()}


            </div>
            <div className="buttonsCancAndNext">
              <p className="buttonsCancAndNextCanc" onClick={handleCancel}>
                {saveMode ? "Cancel" : previewMode ? "Back to edit" : "Cancel"}
              </p>
              <p className="buttonsCancAndNextSave" onClick={handleSaveAndNext}>
                {currentStep === steps.length ? previewMode   ? "Submit"         : "Save & Next"
                  : saveMode    ? "Save"   : "Next"}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default ClientOnBoarding;