import "./style.css"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useRef, useEffect, useState } from "react";
import faceWithOutBg from "../../Assets/png/findYourGrowth.png"
import { ToastContainer, toast } from "react-toastify";
import { addJob } from "../../Api/AddJob";

const ClientAddJob = () => {
  const [showPrescreeningQuestions, setShowPrescreeningQuestions] = useState(false)
  const [previewMode, setPreviewMode] = useState(false);
  const [saveMode, setSaveMode] = useState(false);
  const predefinedQuestions = [
    "What is the role about?",
    "What will your typical day be like?",
    "Who is our desired candidate key roles and responsibilities",
    "Good to have and preferred expertise",
    "Any perks or benefits"
  ];
  const [jobDetails, setJobDetails] = useState({
    clientName: "",
    clientId: "",
    requirmentOpenDate: "",
    requirmentCloseDate: "",
    jobTitle: "",
    priority: "",
    location: "",
    employmentType: "",
    minEducation: "",
    seniorityLevel: "",
    workMode: "",
    noOfVacancies: "",
    noticePerios: "",
    domain: "",
    jobDescription: {
      questions: predefinedQuestions,
      answers: Array(predefinedQuestions.length).fill('') // Initialize answers with empty strings
    },
    workExperience: { min: "", max: "" },
    salary: { min: "", max: "" },
    skills: []
  });

  const [rolesResponsibility, setRolesResponsibility] = useState([]);
  const [preScreeningQuestions, setPreScreeningQuestions] = useState([
    { question: '', answerType: '' }
  ]);

  const [jobDescription, setJobDescription] = useState('');


  const handleCancel = () => {
    if (saveMode) {
      setSaveMode(false);
    } else if (previewMode) {
      setPreviewMode(false);
    } else {
      // Handle cancel logic here
    }
  };
  const addQuestion = (e) => {
    e.preventDefault();
    setPreScreeningQuestions((prevQuestions) => [
      ...prevQuestions,
      { question: '', answerType: '' },
    ]);
  };
  const handleDeleteQuestion = (index) => {
    setPreScreeningQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(index, 1);
      return updatedQuestions;
    });
  };
  const handleClosePrescreeningQuestion = () => {
    const incompleteQuestion = preScreeningQuestions.find(
      (question) => question.question === "" || question.answerType === ""
    );
    if (incompleteQuestion) {
      toast.error("Please fill in all questions and answer types.");
    } else {
      setShowPrescreeningQuestions(false);
      // toast.success("Successfully Added")
    }
  };
  const handleSkillChange = (e, index) => {
    const { value } = e.target;
    setJobDetails((prevDetails) => {
      const updatedSkills = [...prevDetails.skills];
      updatedSkills[index] = { ...updatedSkills[index], name: value };
      return { ...prevDetails, skills: updatedSkills };
    });
    // console.log(`Skill ${index + 1}: Name - ${value}, Mandatory - ${jobDetails.skills[index].mandatory}`);
  };

  const handleToggleMandatory = (index) => {
    setJobDetails((prevDetails) => {
      const updatedSkills = [...prevDetails.skills];
      updatedSkills[index] = { ...updatedSkills[index], mandatory: !updatedSkills[index].mandatory };
      return { ...prevDetails, skills: updatedSkills };
    });
    // console.log(`Skill ${index + 1}: Name - ${jobDetails.skills[index].name}, Mandatory - ${!jobDetails.skills[index].mandatory}`);
  };
  const handleDeleteSkill = (index) => {
    setJobDetails((prevDetails) => {
      const updatedSkills = [...prevDetails.skills];
      updatedSkills.splice(index, 1);
      return { ...prevDetails, skills: updatedSkills };
    });
  };
  const addSkill = () => {
    setJobDetails((prevDetails) => ({
      ...prevDetails,
      skills: [...prevDetails.skills, { name: '', mandatory: false }],
    }));

    // Log the newly added skill
    // console.log(`Newly added skill: Skill ${jobDetails.skills.length + 1}`);
  };


  const handleSaveAndNext = async () => {
    // if (jobDetails.clientId==="" || jobDetails.clientName ===""||jobDetails.domain ===""||jobDetails.employmentType ===""||jobDetails.jobTitle ===""||jobDetails.location ===""||jobDetails.minEducation ===""||jobDetails.noOfVacancies ===""||jobDetails.noticePerios ===""||jobDetails.priority ===""||jobDetails.requirmentCloseDate ===""|| jobDetails.requirmentOpenDate ===""||jobDetails.salary ===""||jobDetails.seniorityLevel ===""||jobDetails.skills ==="" ||jobDetails.workExperience ===""||jobDetails.workMode ==="") {
    //   toast.error("Please fill all the details");
    // } else
    if (previewMode) {
      setSaveMode(true);
      sendDataToApi(); // Uncomment if you want to send data to API
    }
    else {
      setPreviewMode(true);
    }
  };
  const sendDataToApi = async () => {
    try {
      const data = {
        ...jobDetails,
        preScreeningQuestions,
        rolesResponsibility,
      }
      console.log(data)
      if (data) {
        const response = await addJob(data)
        toast.success("job added successfully")
      }
    } catch (error) {
      toast.error("Server Error")
      console.error('Error sending data:', error);
    }
  };
  const contentEditableRef = useRef();

  const handleFormat = (command, value = null) => {
    document.execCommand(command, false, value);
    contentEditableRef.current.focus();
  };
  const handleFormatButton = (style) => {
    document.execCommand("styleWithCSS", null, true);
    document.execCommand(style, false, null);
    contentEditableRef.current.focus();
  };
  const handleColorChange = (color) => {
    document.execCommand("foreColor", false, color);
  };
  const handleList = (type) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const listNode = document.createElement(type === 'ordered' ? 'ol' : 'ul');

    // Ensure that the range is collapsed (no selection) before surrounding contents
    range.collapse(true);

    // Create a new text node to ensure surroundContents works correctly
    const textNode = document.createTextNode('\u200B'); // zero-width space
    range.insertNode(textNode);
    range.selectNode(textNode);

    // Surround the contents with the listNode
    range.surroundContents(listNode);

    // Set the list-style-type for ordered list items
    if (type === 'ordered') {
      const listItems = listNode.querySelectorAll('li');
      listItems.forEach((li, index) => {
        li.style.listStyleType = 'decimal';
      });
    }

    // Remove the temporary text node
    textNode.parentNode.removeChild(textNode);

    contentEditableRef.current.focus();
  };



  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };

  const handleAnswerChange = (question, answer) => {
    const strippedAnswer = stripHtmlTags(answer);
    setJobDetails((prevDetails) => ({
      ...prevDetails,
      jobDescription: {
        ...prevDetails.jobDescription,
        answers: [
          ...prevDetails.jobDescription.answers.slice(0, predefinedQuestions.indexOf(question)),
          strippedAnswer,
          ...prevDetails.jobDescription.answers.slice(predefinedQuestions.indexOf(question) + 1),
        ],
      },
    }));
  };

  return (
    <>
      <ToastContainer />
      <div className="onBoardClientHeaderMain">
        <div className="onBoardClientHeader" style={{margin:"0"}}>
          <h1 className="onBoardClientHeaderH1">Post a Job</h1>
          <form className="onBoardClientHeaderForm">

            {/* primaryDetails */}
            {!previewMode ?

              <div className="primarDetailsAndPrescreeningDiv">
                <div className="clientDetailsSlider">

                  <div className="onBoardPrimaryDetails">
                    <p className="onBoardPrimaryDetailsPara">Client Details </p>
                    <div className="onBoardPrimaryDetailsInputs">
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Client Name</label>
                        <select value={jobDetails.clientName}
                          className="onBoardPrimaryDetailsInputData"
                          name="clientName"
                          onChange={(e) => setJobDetails({ ...jobDetails, clientName: e.target.value })}
                        >
                          <option value="">Enter Client name</option>
                          <option value="select1">select1</option>
                        </select>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Client ID</label>
                        <input
                          className="onBoardPrimaryDetailsInputData"
                          type="text"
                          placeholder="Enter Client Id"
                          name="clientId"
                          onChange={(e) => setJobDetails({ ...jobDetails, clientId: e.target.value })}
                        />
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Priority</label>
                        <select value={jobDetails.priority}
                          className="onBoardPrimaryDetailsInputData"
                          name="priority"
                          onChange={(e) => setJobDetails({ ...jobDetails, priority: e.target.value })}
                        >
                          <option value="select">select</option>
                          <option value="select1">select1</option>
                        </select>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Requirement Open Date</label>
                        <input
                          className="onBoardPrimaryDetailsInputData"
                          type="date"
                          placeholder={jobDetails.requirmentOpenDate}
                          name="requirmentOpenDate"
                          onChange={(e) => setJobDetails({ ...jobDetails, requirmentOpenDate: e.target.value })}
                        />
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Requirement Close Date</label>
                        <input
                          className="onBoardPrimaryDetailsInputData"
                          type="date"
                          placeholder={jobDetails.requirmentCloseDate}
                          name="requirmentCloseDate"
                          onChange={(e) => setJobDetails({ ...jobDetails, requirmentCloseDate: e.target.value })}
                        />
                      </div>

                    </div>
                  </div>
                  <div className="onBoardPrimaryDetails">
                    <p className="onBoardPrimaryDetailsPara">Job Details </p>
                    <div className="onBoardPrimaryDetailsInputs">
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Job Title</label>
                        <input
                          className="onBoardPrimaryDetailsInputData"
                          type="text"
                          placeholder="Enter Job Title"
                          name="jobTitle"
                          onChange={(e) => setJobDetails({ ...jobDetails, jobTitle: e.target.value })}
                        />
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Location</label>
                        <input
                          className="onBoardPrimaryDetailsInputData"
                          type="text"
                          placeholder="Enter Job Location"
                          name="location"
                          onChange={(e) => setJobDetails({ ...jobDetails, location: e.target.value })}
                        />
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Employment Type</label>
                        <select
                          className="onBoardPrimaryDetailsInputData"
                          name="employmentType" value={jobDetails.employmentType}
                          onChange={(e) => setJobDetails({ ...jobDetails, employmentType: e.target.value })}
                        >
                          <option value="">Enter Employment Type</option>
                          <option value="Internship">Internship</option>
                          <option value="Temporary">Temporary</option>
                          <option value="Contract">Contract</option>
                          <option value="Part-Time">Part-Time</option>
                          <option value="Full-Time">Full-Time</option>
                        </select>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Minimum Education</label>
                        <select
                          className="onBoardPrimaryDetailsInputData"
                          name="minEducation" value={jobDetails.minEducation}
                          onChange={(e) => setJobDetails({ ...jobDetails, minEducation: e.target.value })}
                        >
                          <option value="">select</option>
                          <option value="select1">select1</option>
                        </select>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Senority Level</label>
                        <select value={jobDetails.seniorityLevel}
                          className="onBoardPrimaryDetailsInputData"
                          name="seniorityLevel"
                          onChange={(e) => setJobDetails({ ...jobDetails, seniorityLevel: e.target.value })}
                        >
                          <option value="">select</option>
                          <option value="Internship">Internship</option>
                          <option value="Entry Level">Entry Level</option>
                          <option value="Associate">Associate</option>
                          <option value="Mid Senior Level">Mid Senior Level</option>
                          <option value="Director">Director</option>
                          <option value="Executive"> Executive</option>
                        </select>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Work Mode</label>
                        <select
                          className="onBoardPrimaryDetailsInputData"
                          name="workMode" value={jobDetails.workMode}
                          onChange={(e) => setJobDetails({ ...jobDetails, workMode: e.target.value })}
                        >
                          <option value="">select</option>
                          <option value="select1">select1</option>
                        </select>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">No. of Vacancies</label>
                        <input
                          className="onBoardPrimaryDetailsInputData"
                          type="text"
                          placeholder="Enter Job Vacancies"
                          name="noOfVacancies"
                          onChange={(e) => setJobDetails({ ...jobDetails, noOfVacancies: e.target.value })}
                        />
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Notice Period</label>
                        <select
                          className="onBoardPrimaryDetailsInputData"
                          name="noticePerios" value={jobDetails.noticePerios}
                          onChange={(e) => setJobDetails({ ...jobDetails, noticePerios: e.target.value })}
                        >
                          <option value="">select</option>
                          <option value="select1">select1</option>
                        </select>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Domain</label>
                        <select
                          className="onBoardPrimaryDetailsInputData"
                          name="domain" value={jobDetails.domain}
                          onChange={(e) => setJobDetails({ ...jobDetails, domain: e.target.value })}
                        >
                          <option value="">select</option>
                          <option value="select1">select2</option>
                          <option value="select2">select3</option>
                        </select>
                      </div>
                    </div>
                    <div className="clientAddJobSkillsDiv">
                     <div className="onboardPrimaryMinMaxDiv"> <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Work Experience (in years)</label>
                        <div className="clientAddJobSkillsDivInput">
                          <input
                            className="onBoardPrimaryDetailsInputData onBoardPrimaryDetailsInputData2"
                            type="text"
                            placeholder="Minimum"
                            name="min"
                            onChange={(e) => setJobDetails({ ...jobDetails, workExperience: { ...jobDetails.workExperience, min: e.target.value } })}
                          /><p className="pMinuesP">-</p>
                          <input
                            className="onBoardPrimaryDetailsInputData onBoardPrimaryDetailsInputData2"
                            type="text"
                            placeholder="Maximum"
                            name="max"
                            onChange={(e) => setJobDetails({ ...jobDetails, workExperience: { ...jobDetails.workExperience, max: e.target.value } })}
                          />
                        </div>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Salary (in CTC)</label>
                        <div className="clientAddJobSkillsDivInput">
                          <input
                            className="onBoardPrimaryDetailsInputData onBoardPrimaryDetailsInputData2"
                            type="text"
                            placeholder="Minimum"
                            name="min"
                            onChange={(e) => setJobDetails({ ...jobDetails, salary: { ...jobDetails.salary, min: e.target.value } })}
                          /><p className="pMinuesP">-</p>
                          <input
                            className="onBoardPrimaryDetailsInputData onBoardPrimaryDetailsInputData2"
                            type="text"
                            placeholder="Maximum"
                            name="max"
                            onChange={(e) => setJobDetails({ ...jobDetails, salary: { ...jobDetails.salary, max: e.target.value } })}
                          />
                        </div>
                      </div>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Skills</label>
                        <div className="onBoardPrimaryDetailsSkillAdding">
                          {jobDetails.skills.map((skill, index) => (
                            <span key={index} className="skillInputContainer">
                              <p
                                className={`mandatoryStar ${skill.mandatory ? 'active' : ''}`}
                                onClick={() => handleToggleMandatory(index)}
                              >
                                &#9733;
                              </p>
                              <input
                                className="onBoardPrimaryDetailsInputDataSkill"
                                type="text"
                                placeholder={`Skill ${index + 1}`}
                                value={skill.name}
                                onChange={(e) => handleSkillChange(e, index)}
                              />
                              <i className="fa-solid fa-xmark crossMarkDelete" onClick={() => handleDeleteSkill(index)}></i>
                            </span>
                          ))}
                          <div className="addSkillButtonforSkillsPartDiv">
                            <p onClick={addSkill} className="addSkillButtonforSkillsPart">Add</p>
                          </div>
                        </div>
                      </div>
                      <div className="onBoardPrimaryDetailsData">
                        <label className="onBoardPrimaryDetailsLabel">Job Description</label>
                        <div className="predefinedQuestions">

                          <div className="jobdescriptioneditorMainDiv">
                          
                            <select className="prescreeningquestioneditorSelect" onChange={(e) => handleFormat("fontSize", e.target.value)} >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                            <select className="prescreeningquestioneditorSelect" onChange={(e) => handleFormat("fontName", e.target.value)} >
                              <option value="Arial">Arial</option>
                              <option value="Courier New">Courier New</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Times New Roman">Times New Roman</option>
                              <option value="Verdana">Verdana</option>
                            </select>
                            <div className="prescreeningquestioneditorSelectDiv">
                              <button className="prescreningquestionButtonsEdit" type="button" onClick={() => handleFormatButton('bold')}><i class="fa-solid fa-bold"></i></button>
                              <button className="prescreningquestionButtonsEdit" type="button" onClick={() => handleFormatButton('italic')}><i class="fa-solid fa-italic"></i></button>
                              <button className="prescreningquestionButtonsEdit" type="button" onClick={() => handleFormatButton('underline')}><i class="fa-solid fa-underline"></i></button>
                            </div>
                            <div className="prescreeningquestioneditorSelectDiv">
                              <input  type="color" style={{width:"20px",height:"20px",marginTop:"3px"}} onChange={(e) => handleColorChange(e.target.value)} />
                              <button type="button" className="prescreningquestionButtonsEdit" onClick={() => handleList('ordered')}>
                              <i class="fa-solid fa-list-ul"></i>
                              </button>
                              <button type="button" className="prescreningquestionButtonsEdit" onClick={() => handleList('unordered')}>
                              <i class="fa-solid fa-list-ol"></i>
                              </button></div>
                          </div>
                          <div className="prescreeningquestionmapingDiv">
                            {predefinedQuestions.map((question, index) => (
                              <div key={index} className="predefinedQuestion">
                                <div className="onBoardPrimaryDetailsLabelDivQues">
                                  <div className="onBoardPrimaryDetailsLabelDivQuesDiv2">
                                  <i class="fa-solid fa-circle dotQuestionAns"></i>
                                    <label className="onBoardPrimaryDetailsLabel">{question}</label>
                                  </div>
                                  <div ref={contentEditableRef}
                                    className="onBoardPrimaryDetailsInputData onBoardPrimaryDetailsInputDataTextarea"
                                    contentEditable="true"
                                    placeholder={`Enter your answer for ${question}`}
                                    dangerouslySetInnerHTML={{ __html: jobDetails.jobDescription.answers[question] }}
                                    onBlur={(e) => handleAnswerChange(question, e.target.innerHTML)}
                                  />
                                </div>
                              </div>
                            ))}</div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
                {/* add prescreening questions */}
                <div className={`${showPrescreeningQuestions ? "prescreeningquestionDivShadow" : ""}`}>
                  <div className={`prescreeningquestionDiv ${showPrescreeningQuestions ? "prescreeningquestionDivShow" : ""}`}>
                    <span className="spanOfPrescreeningQuestions"><p className="prescreeningquestionHeading">Pre-Screening Questions</p><i class="fa-solid fa-xmark faCloseMark" onClick={() => setShowPrescreeningQuestions(false)}></i></span>
                    <div className="prescreeningquestionDivShadowFlex">
                      <div>
                        {preScreeningQuestions.map((questionObj, index) => (
                          <div className="prescreeningquesAns" key={index}>
                            <div className="onBoardPrimaryDetailsData">
                              <span className="prescreeningQuestionSpan">
                                <label className="onBoardPrimaryDetailsLabel">{`Question - ${index + 1}`}</label>
                                <i class="fa-regular fa-trash-can trashDelete" onClick={() => handleDeleteQuestion(index)}></i>
                              </span>
                              <textarea
                                style={{ height: '7rem', width: '100%' }}
                                className="onBoardPrimaryDetailsInputData"
                                placeholder="Enter text here"
                                value={questionObj.question}
                                onChange={(e) => {
                                  const textValue = e.target.value;
                                  setPreScreeningQuestions((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], question: textValue };
                                    return updatedQuestions;
                                  });
                                }}
                              />
                            </div>
                            <div className="onBoardPrimaryDetailsData">
                              <label className="onBoardPrimaryDetailsLabel">Answer Type</label>
                              <select
                                className="onBoardPrimaryDetailsInputData"
                                value={questionObj.answerType}
                                onChange={(e) => {
                                  const selectedOption = e.target.value;
                                  setPreScreeningQuestions((prevQuestions) => {
                                    const updatedQuestions = [...prevQuestions];
                                    updatedQuestions[index] = { ...updatedQuestions[index], answerType: selectedOption };
                                    return updatedQuestions;
                                  });
                                }}
                              >
                                <option value="select">Select</option>
                                <option value="select2">Single line text</option>
                                <option value="select3">Multi line text</option>
                              </select>
                            </div>
                          </div>
                        ))}
                        <button onClick={addQuestion} className="addquestionbuttonPrescreen">+ Add</button>
                      </div>
                      <div style={{ border: "none" }} className="buttonsCancAndNext buttonsOfAddJobCanAndNext">
                        <p className="buttonsCancAndNextCanc" onClick={() => setShowPrescreeningQuestions(false)}>Cancel</p>
                        <p className="buttonsCancAndNextSave" onClick={handleClosePrescreeningQuestion} >Save</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div style={{ pointerEvents: "none" }} className="ViewJObById">
                <div className="totaljobViewById">
                  <div className="totaljobViewByIdHeadings">
                    <p className="totaljobViewByIdFirstP">View</p>
                    <p className="totaljobViewByIdFirstP"><i class="fa-solid fa-xmark faCloseMark"></i></p>
                  </div>
                  <div className="totalJobCompanyPictures">
                    <img src={faceWithOutBg} alt="" className="totalJobCompanyPictures1" />
                    <img src={faceWithOutBg} alt="" className="totalJobCompanyPictures2" />
                  </div>
                  <div className="totalJobViewDetails">
                    <div className="totalJobJobDetailsById" style={{ width: "30%" }}>
                      <p className="totalJobJobDetailsByIdTwoP">Job Details</p>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">Job Title</p>
                        <input className="totalJobDetailsbyIdInput" value={jobDetails.clientName} type="text" />
                      </span>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">Location</p>
                        <input className="totalJobDetailsbyIdInput" value={jobDetails.location} type="text" />
                      </span>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">No. of Openings</p>
                        <input className="totalJobDetailsbyIdInput" value={jobDetails.noOfVacancies} type="text" />
                      </span>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">Skills</p>
                        <span className="totalJobDetailsIdInputFlex">
                          {jobDetails.skills.map((skill, index) => (
                            <input className="totalJobDetailsbyIdInput" value={skill.name} type="text" />
                          ))}
                        </span>
                      </span>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">Work Experience</p>
                        <input className="totalJobDetailsbyIdInput" value={jobDetails.workExperience} type="text" />
                      </span>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">Employment Type</p>
                        <input className="totalJobDetailsbyIdInput" value={jobDetails.employmentType} type="text" />
                      </span>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">Minimum Education</p>
                        <input className="totalJobDetailsbyIdInput" value={jobDetails.minEducation} type="text" />
                      </span>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">Salary</p>
                        <input className="totalJobDetailsbyIdInput" value={jobDetails.salary} type="text" />
                      </span>
                      <span className="totalJobDetailsbyIdSpanInput">
                        <p className="totalJobDetailsbyIdSpanTitle">Seniorty Level</p>
                        <input className="totalJobDetailsbyIdInput" value={jobDetails.seniorityLevel} type="text" />
                      </span>
                    </div>
                    <div className="totalJobJobDetailsById" style={{ width: "70%" }}>
                      <div className="totalJobJobDetailsByIdTwo">
                        <p className="totalJobJobDetailsByIdTwoP">Job Description</p>
                        <p className="totalJobJobDetailsByIdTwoP2">
                          <textarea className="totalJobJobDetailsByIdTextArea" value={jobDetails.jobDescription.answers[0]} />
                        </p>
                      </div>
                      <div className="totalJobJobDetailsByIdTwo">
                        <p className="totalJobJobDetailsByIdTwoP">Roles & Responsibilities</p>
                        <div className="totalJobTextAreaPart">
                          <span className="totalJobTextAreaPartSpan">
                            <textarea value={jobDetails.jobDescription.answers[1]} className="totalJobJobDetailsByIdTextArea" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="totaljobAboutCompany">
                    <p className="totaljobAboutCompany">About Vyzen</p>
                    <p className="totaljobAboutCompanyDesc">As a Product Designer, you will be at the forefront of our design process, ensuring that our digital products exceed user expectations and drive engagement. Through user research and insights, you will have the opportunity to deeply understand our customers' needs and pain points, translating them into seamless and delightful experiences. Your ability to iterate on designs based on feedback, user testing, and dat</p>
                  </div>
                </div>
              </div>
            }
            <div className="buttonsCancAndNext buttonsOfAddJob">
              <p style={{ visibility: previewMode ? "hidden" : "visible" }}
                className="buttonsAddPrequestion" onClick={() => setShowPrescreeningQuestions(true)}>Add Prescreening Questions</p>
              <div className="buttonsCancAndNextAddJob">
                <p className="buttonsCancAndNextCanc" onClick={handleCancel}>  {saveMode ? "Cancel" : previewMode ? "Back to edit" : "Cancel"}</p>
                <p className="buttonsCancAndNextSave" onClick={handleSaveAndNext}>{previewMode ? "Submit" : saveMode ? "Save" : "Preview & Submit"}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


export default ClientAddJob;
