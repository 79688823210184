import React, { useState } from "react";
import AppTextInput from "../../../Components/Common/AppTextInput";
import AppButton from "../../../Components/Common/Button";
import "../styles.css";
import { loginAPI, signUpAPI } from "../../../Api/auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import Image from "../../../Assets/png/sign-up-image.png";
import LogoImage from "../../../Assets/png/new-logo.svg";
import instance from "../../../Api";
import hideSvg from "../../../Assets/png/hide.svg"
import showEye from "../../../Assets/png/showEye.svg"

const SignUpPage = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [username, setUsername] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [area, setArea] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [pincode, setPincode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false); // State to control password visibility
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!name) return toast.error("Add name");
    if (!email) return toast.error("Add email");
    if (!username) return toast.error("Add username");
    if (!password) return toast.error("Add password");
    if (!mobileNumber) return toast.error("Add mobile number");
    if (!area) return toast.error("Add area");
    if (!city) return toast.error("Add city");
    if (!state) return toast.error("Add state");
    if (!pincode) return toast.error("Add pincode");

    const data = {
      email: email,
      password: password,
      name: name,
      username: username,
      mobileNumber: mobileNumber,
      companyAddress: {
        area: area,
        city: city,
        state: state,
        pincode: pincode,
      },
     
    };
    const resp = await signUpAPI(data).catch((e) => {
      toast.error(e.data.message);
    });

    if (resp?.message !== "SUCCESS") {
      toast.error(resp.message);
      return;
    }
    if (resp && resp.statusCode === 200) {
      toast.success("Register SuccessFully!");
      localStorage.setItem("HR_INFO", JSON.stringify(resp.data));
      setIsLoading(false);

      const LoginData = { email: email, password: password };
      const respL = await loginAPI(LoginData).catch((e) => {
        toast.error(e.data.message);
        setIsLoading(false);
      });
      if (respL && respL.statusCode === 200) {
        localStorage.setItem("hrToken", respL.data.token);
        instance.defaults.headers.common["Authorization"] =
          "Bearer " + respL.data.token;
        localStorage.setItem("HR_LOGIN_INFO", JSON.stringify(respL.data));

        navigate("/corporate_rentals/home", {});
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const passwordShowHide = () => {
    setPasswordVisible(!isPasswordVisible); // Toggle the visibility state
  };

  
  return (
    <div className="login-container">
      <div className="miiddle-container">
        <img src={LogoImage} alt="" className="tripprLogo" />
        <h1 className="title">Sign Up</h1>
        <form onSubmit={onSubmit}>
          <div >
            <div id="signupForm">
              <div className="auth_input_div">
                <label>Full Name</label>
                <AppTextInput
                  type="text"
                  placeholder="Full name"
                  className="form-control"
                  onChange={(text) => setName(text.target.value)}
                />
              </div>
              <div className="auth_input_div">
                <label>Email Address</label>
                <AppTextInput
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  onChange={(text) => setEmail(text.target.value)}
                />
              </div>
              <div className="auth_input_div">
                <label>Username</label>
                <AppTextInput
                  type="text"
                  placeholder="Username"
                  className="form-control"
                  onChange={(text) => setUsername(text.target.value)}
                />
              </div>
              <div className="auth_input_div">
                <label>Password</label>
                <div className="passwordAndEye">    <AppTextInput
                 type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  className="form-control"
                  onChange={(text) => setPassword(text.target.value)}
                />
                { !isPasswordVisible ? <img src={hideSvg } onClick={passwordShowHide} className="eyeHide" /> : <img src={showEye } onClick={passwordShowHide} className="eyeShow"/> }
              </div>
              </div>
              <div className="auth_input_div">
                <label>Mobile Number</label>
                <AppTextInput
                  type="number"
                  placeholder="Mobile number"
                  className="form-control"
                  onChange={(text) => setMobileNumber(text.target.value)}
                />
              </div>
              <div className="addressInputDiv">
              <div className="auth_input_div">
                <label>Area/colony(Organization's)</label>
                <AppTextInput
                  type="text"
                  placeholder="Area/colony"
                  className="form-control"
                  onChange={(text) => setArea(text.target.value)}
                />
              </div>
              <div className="auth_input_div">
                <label>City(Organization's)</label>
                <AppTextInput
                  type="text"
                  placeholder="City"
                  className="form-control"
                  onChange={(text) => setCity(text.target.value)}
                />
              </div>
              <div className="auth_input_div">
                <label>State(Organization's)</label>
                <AppTextInput
                  type="text"
                  placeholder="State"
                  className="form-control"
                  onChange={(text) => setState(text.target.value)}
                />
              </div>
              <div className="auth_input_div">
                <label>Pincode(Organization's)</label>
                <AppTextInput
                  type="text"
                  placeholder="Pincode"
                  className="form-control"
                  onChange={(text) => setPincode(text.target.value)}
                />
              </div>
            </div>
            <div className="signUpFormCheckboxAgreement">
              <input
                type="checkbox" style={{width:""}}
                className="form-check-input"
                id="exampleCheck1" required
              />
              <p className="form-check-label signUpFormLabel"> By creating an account you agree to the <a href="#">terms of use </a>and our <a href="#">privacy policy</a>.</p>
            </div>
            </div>
          </div>

          <ToastContainer />
          <div className="btn-container">
            <AppButton
              title="Create account"
              disabled={isLoading}
              type="submit"
            />
          </div>
          <div id="Login-text">
            <Link to="/corporate_rentals/login">
              Already have an account? <b style={{ color: "#605BFF" }}>Login</b>
            </Link>
          </div>
        </form>
      </div>
      <div className="auth_image_container">
        <img src={Image} alt="" className="auth_image" />
      </div>
    </div>
  );
};
export default SignUpPage;
