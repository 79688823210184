import "./index.css"
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { useState } from "react";
import magnifyingGlass from "../../Assets/png/Group 1981.svg"
import candidateProfilePicture from "../../Assets/png/candidateProfilePicture.png"
import companyProfilePicture from "../../Assets/png/jobProfileImage.png"
import feedBackImage from "../../Assets/png/noFeedbackIsHere.svg"
export const CandidateListing = () => {
    const [showFilterBox, setShowFilterBox] = useState(false)
    const [showOtherDetailsButtons, setShowOtherDetailsButtons] = useState(false)
    const [showCandidateDetialsBox, setShowCandidateDetialsBox] = useState(true)
    const [activeCandiDateFilter, setActiveCandiDateFilter] = useState("experience");
    const [activeCandiDateDetailsBoxFilter, setActiveCandiDateDetailsBoxFilter] = useState("candidateProfile");

    const handleFilterClick = (filter) => {
        setActiveCandiDateFilter(filter);
    };
    const handleCandidateFilterClick = (filter) => {
        setActiveCandiDateDetailsBoxFilter(filter);
    };
    return (
        <>
            <div className="totalJobs">
                <div className="ClientListSearchBoxTwo">
                    <div>
                        <Link className="clientlistSearchBoxFirstP">High {">"} UX/UI Designer (BVS Technologies)</Link>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <span className="ClientListSearchSpan"> <i class="fa-solid fa-magnifying-glass CiSearch"></i><input className="ClientListSearchSpanInput" type="search" style={{ width: "454px" }} placeholder="Search" /></span><i onClick={() => setShowFilterBox(!showFilterBox)} class="FaFilter fa-solid fa-sliders"></i><select className="ClientListSearchSpanP" ><option>Tools</option><option>Yes No</option></select>
                        <p className="magnifyingGlassP"><img src={magnifyingGlass} alt="" className="magnifyingGlass" />Source a Candidate </p>
                    </div>
                </div>
                {/* FILTER BOX */}
                {showFilterBox && (
                    <div className="totalJobFilterBox">
                        <div className="totalJobFilterBoxFilterMain">
                            <div className="onBoardPrimaryDetailsData">
                                <label className="onBoardPrimaryDetailsLabel">Job Match Score</label>
                                <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
                            </div>
                            <div className="onBoardPrimaryDetailsData">
                                <label className="onBoardPrimaryDetailsLabel">Location</label>
                                <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
                            </div>
                            <div className="onBoardPrimaryDetailsData">
                                <label className="onBoardPrimaryDetailsLabel">Expected Salary</label>
                                <input type="text"  className="onBoardPrimaryDetailsInputData" />
                            </div>
                            <div className="onBoardPrimaryDetailsData">
                                <label className="onBoardPrimaryDetailsLabel">Status</label>
                                <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
                            </div>

                            <div className="onBoardPrimaryDetailsData">
                                <label className="onBoardPrimaryDetailsLabel">Recruiter Assigned</label>
                                <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
                            </div>
                           
                            
                        </div>
                        <div className="totalJobFilterBoxDivTwo">
                            <div style={{display:"flex",gap:"10px"}}>
                             <div className="onBoardPrimaryDetailsData">
                                <label className="onBoardPrimaryDetailsLabel">Source</label>
                                <select className="onBoardPrimaryDetailsInputData" style={{ width: "245px" }}><option>Select</option></select>
                            </div>
                            <div className="onBoardPrimaryDetailsData">
                                <label className="onBoardPrimaryDetailsLabel">Notice Period</label>
                                <select className="onBoardPrimaryDetailsInputData" style={{ width: "245px" }}><option>Select</option></select>
                            </div>

                            </div>
                            <div className="filterBoxSubmit">
                                <p className="buttonsCancAndNextCanc">Clear All</p>
                                <p className="buttonsCancAndNextSave">Apply</p>
                            </div>
                        </div>
                    </div>
                )}
                {/* FILTER BUTTONS */}
                <div className="totalJobboxPartStart">
                    <div className="totalJobsfilterButtons">
                        <div className="totalJobsHeadings">
                            <p className="totalJobsHeadingsFilter" id="">SOURCED (39)</p>
                            <p className="totalJobsHeadingsFilter" id="">APPLIED (24)</p>
                            <p className="totalJobsHeadingsFilter" id="">INITIAL SCREENING (246)</p>
                            <p className="totalJobsHeadingsFilter" id="">RESUME SCREENING (23)</p>
                            <p className="totalJobsHeadingsFilter" id="">ASSESSMENT (43)</p>
                            <p className="totalJobsHeadingsFilter" id="" onClick={() => setShowOtherDetailsButtons(!showOtherDetailsButtons)}>...</p>
                            {showOtherDetailsButtons && (<div className="totalJobsHeadingsDivLastLists">
                                <div className="totalJobsHeadingsDivLast">
                                    <p className="totalJobsHeadingsDivLastHeadings">INTERVIEW EVALUATION (13)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings">FINAL INTERVIEW (12)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings">INTERVIEW EVALUATION (13)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings">BACKGROUND CHECKS (09)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings">OFFERED (34)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings">OFFER STATUS (48)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings">ONBOARDING (12)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings">HIRED (09)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings">EXIT (24)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings" style={{ color: "#0B48FF" }}>ALL CANDIDATE (10K)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings" style={{ color: "#FF000A" }}>REJECTED APPLICANTS (1K)</p>
                                    <p className="totalJobsHeadingsDivLastHeadings" style={{ color: "#32A211" }}>RECOMMENDED TALENT (128)</p>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
                <div className="ClientListMainDiv">
                    <div className="ClientListMainDivTable">
                        <div className="ClientListTableThead">
                            <div className="clientListFirstTR">
                                <p className="ClientListTableTH"><input type="checkbox" />&nbsp;&nbsp;Candidate Name <i class="fa-solid fa-sort faSolidIconSort"></i></p>
                                <p className="ClientListTableTH">Current CTC<i class="fa-solid fa-sort faSolidIconSort" ></i></p>
                                <p className="ClientListTableTH">Expected CTC<i class="fa-solid fa-sort faSolidIconSort"></i></p>
                                <p className="ClientListTableTH">Notice Period (Days)<i class="fa-solid fa-sort faSolidIconSort"></i></p>
                                <p className="ClientListTableTH">Source From <i class="fa-solid fa-sort faSolidIconSort"></i></p>
                                <p className="ClientListTableTH">Job Match Score<i class="fa-solid fa-sort faSolidIconSort"></i></p>
                                <p className="ClientListTableTH">Recruiter Assigned<i class="fa-solid fa-sort faSolidIconSort"></i></p>
                            </div>
                        </div>
                        <div className="ClientListTableTbody">
                            <div className="clientListSecondTR">
                                <p className="clientListTD "><input type="checkbox" />&nbsp;&nbsp;Subramanian</p>
                                <p className="clientListTD">6 - 12 LPA</p>
                                <p className="clientListTD">10 - 12 LPA</p>
                                <p className="clientListTD">30</p>
                                <p className="clientListTD">LinkedIn</p>
                                <p className="clientListTD">08-Aug-2023</p>
                                <p className="clientListTD">Janani</p>
                            </div>
                        </div>
                    </div>
                </div>
                {showCandidateDetialsBox && (
                    <div className="getCandidateDetailsByIdMain">
                        {/* <div className="ChevronRightDiv"><i class="fa-solid fa-chevron-right ChevronRight"></i></div> */}
                        <div className="getCandidateDetailsById">
                            <div className="getCandidateDetailsName">
                                <p className="getCandidateDetailsNameP"><i class="fa-solid fa-chevron-left"></i>Muruganthan<i class="fa-solid fa-chevron-right"></i></p>
                                <i class="fa-regular fa-circle-xmark" style={{ color: "black" }} onClick={() => setShowCandidateDetialsBox(!true)}></i>
                                <div className="getCandidateDetailsNameDiv">
                                    <p className="getCandidateDetailsDivP1">Reject</p>
                                    <p className="getCandidateDetailsDivP2">Add Feedback</p>
                                    <p className="getCandidateDetailsDivP3">...</p>
                                </div>
                            </div>
                            <div className="getCandidateDetailsFilterBtnDiv">
                                <p
                                    className={`getCandidateDetailsFilterButtons ${activeCandiDateDetailsBoxFilter === "candidateProfile" ? "activegetCandidateDetailsFilterButtons" : ""
                                        }`} onClick={() => handleCandidateFilterClick("candidateProfile")}
                                >Candidate Profile</p>
                                <p className={`getCandidateDetailsFilterButtons ${activeCandiDateDetailsBoxFilter === "cvByJavrvix" ? "activegetCandidateDetailsFilterButtons" : ""
                                    }`} onClick={() => handleCandidateFilterClick("cvByJavrvix")}>CV by Jarvis</p>
                                <p className={`getCandidateDetailsFilterButtons ${activeCandiDateDetailsBoxFilter === "jobMatchingScore" ? "activegetCandidateDetailsFilterButtons" : ""
                                    }`} onClick={() => handleCandidateFilterClick("jobMatchingScore")}>Job Matching Score</p>
                                <p className={`getCandidateDetailsFilterButtons ${activeCandiDateDetailsBoxFilter === "feedback" ? "activegetCandidateDetailsFilterButtons" : ""
                                    }`} onClick={() => handleCandidateFilterClick("feedback")}>Feedback</p>
                                <p className={`getCandidateDetailsFilterButtons ${activeCandiDateDetailsBoxFilter === "assessments" ? "activegetCandidateDetailsFilterButtons" : ""
                                    }`} onClick={() => handleCandidateFilterClick("assessments")}>Assessment</p>
                                <p className={`getCandidateDetailsFilterButtons ${activeCandiDateDetailsBoxFilter === "interview" ? "activegetCandidateDetailsFilterButtons" : ""
                                    }`} onClick={() => handleCandidateFilterClick("interview")}>Interview</p>
                                <p className={`getCandidateDetailsFilterButtons ${activeCandiDateDetailsBoxFilter === "emailOfCandidate" ? "activegetCandidateDetailsFilterButtons" : ""
                                    }`} onClick={() => handleCandidateFilterClick("emailOfCandidate")}>Email</p>
                                <p className={`getCandidateDetailsFilterButtons ${activeCandiDateDetailsBoxFilter === "timelineOfcandidate" ? "activegetCandidateDetailsFilterButtons" : ""
                                    }`} onClick={() => handleCandidateFilterClick("timelineOfcandidate")}>Timeline</p>
                            </div>
                            {activeCandiDateDetailsBoxFilter === "candidateProfile" && (
                                <div className="getCandiDateDetailsFirstDivFilter">
                                    <div className="getCandidateDetailsFilter">
                                        <img src={candidateProfilePicture} className="candidateProfilePicture" alt="candidateProfilePicture" />
                                        <div className="candidateProfilePictureDiv">
                                            <p className="candidateProfilePictureDivP1">Murugananthan</p>
                                            <p className="candidateProfilePictureDivP2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing</p>
                                        </div>
                                    </div>
                                    <div className="getCandidateNecessityDiv">
                                        <div className="getCandidateNecessityDivOne">
                                            <p className="getCandidateNecessityDivskillsFirst">Skills</p>
                                            <div className="getCandidateNecessityDivDetailsSkills">
                                                <p className="getCandidateNecessityDivskills">
                                                    Java
                                                </p>
                                                <p className="getCandidateNecessityDivskills">
                                                    React
                                                </p><p className="getCandidateNecessityDivskills">
                                                    SpringBoot
                                                </p><p className="getCandidateNecessityDivskills">
                                                    Javascript
                                                </p><p className="getCandidateNecessityDivskills">
                                                    Photoshop
                                                </p><p className="getCandidateNecessityDivskills">
                                                    Java
                                                </p><p className="getCandidateNecessityDivskills">
                                                    Photoshop
                                                </p><p className="getCandidateNecessityDivskills">
                                                    Photoshop
                                                </p>
                                            </div>
                                            <div className="getCandidateNecessityDivskillsLastDiv">
                                                <span className="getCandidateNecessityDivskillsLastspan"><p className="getCandidateNecessityDivskillsLastspanP">Last Working Date</p><p className="getCandidateNecessityDivskillsLastspanP2">12 Sep 2023</p></span>
                                                <span className="getCandidateNecessityDivskillsLastspan"><p className="getCandidateNecessityDivskillsLastspanP">Any offer Acceptance</p><p className="getCandidateNecessityDivskillsLastspanP2">Yes</p></span>
                                            </div>

                                        </div>
                                        <div className="getCandidateNecessityDivTwo">
                                            <div className="getCandidateNecessityDivFilterBtn">
                                                <p className={`getCandidateNecessityDivTwoFilterButtons ${activeCandiDateFilter === "basicDetails" ? "activeCandidateFilter" : ""
                                                    }`} onClick={() => handleFilterClick("basicDetails")}>Basic Details</p>
                                                <p className={`getCandidateNecessityDivTwoFilterButtons ${activeCandiDateFilter === "experience" ? "activeCandidateFilter" : ""
                                                    }`} onClick={() => handleFilterClick("experience")}>Experience</p>
                                                <p className={`getCandidateNecessityDivTwoFilterButtons ${activeCandiDateFilter === "prescreeningQuestions" ? "activeCandidateFilter" : ""
                                                    }`} onClick={() => handleFilterClick("prescreeningQuestions")}>Prescreening Questions</p>
                                            </div>

                                            {activeCandiDateFilter === "basicDetails" && (
                                                <div className="getCandidateNecessityDivFilterBtnBasicDetails">

                                                </div>
                                            )}
                                            {activeCandiDateFilter === "experience" && (
                                                <div className="getCandidateNecessityDivFilterBtnExperience">
                                                    <div className="getCandidateNecessityExperience">
                                                        <img src={companyProfilePicture} className="companyProfilePictureImg" alt="" />
                                                        <span className="getCandidateNecessityExperienceSpan">
                                                            <p className="getCandidateNecessityExperienceP1">Bell Technologies</p>
                                                            <p className="getCandidateNecessityExperienceP2">Intern - UX/UI Designer</p>
                                                            <p className="getCandidateNecessityExperienceP2">Oct 2021 - Dec 2021</p>
                                                        </span>
                                                    </div>
                                                    <div className="getCandidateNecessityExperience">
                                                        <img src={companyProfilePicture} className="companyProfilePictureImg" alt="" />
                                                        <span className="getCandidateNecessityExperienceSpan">
                                                            <p className="getCandidateNecessityExperienceP1">Bell Technologies</p>
                                                            <p className="getCandidateNecessityExperienceP2">Intern - UX/UI Designer</p>
                                                            <p className="getCandidateNecessityExperienceP2">Oct 2021 - Dec 2021</p>
                                                        </span>
                                                    </div>
                                                    <div className="getCandidateNecessityExperience">
                                                        <img src={companyProfilePicture} className="companyProfilePictureImg" alt="" />
                                                        <span className="getCandidateNecessityExperienceSpan">
                                                            <p className="getCandidateNecessityExperienceP1">Bell Technologies</p>
                                                            <p className="getCandidateNecessityExperienceP2">Intern - UX/UI Designer</p>
                                                            <p className="getCandidateNecessityExperienceP2">Oct 2021 - Dec 2021</p>
                                                        </span>
                                                    </div>
                                                </div>)}
                                            {activeCandiDateFilter === "prescreeningQuestions" && (
                                                <div className="getCandidateNecessityDivFilterBtnPrescreeningQuestions" >

                                                </div>)}
                                            <div className="MessageToCandidateDiv">
                                                <p className="MessageToCandidate">Message to Candidate</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeCandiDateDetailsBoxFilter === "cvByJavrvix" && (
                                <div className="getCandiDateDetailsFirstDivFilter">
                                    </div>
                                    )}
                                      {activeCandiDateDetailsBoxFilter === "jobMatchingScore" && (
                                <div className="getCandiDateDetailsFirstDivFilter">
                                    <div className="ClientListMainDiv">
                    <div className="ClientListMainDivTable">
                        <div className="ClientListTableThead candiDateListTableThead">
                            <div className="clientListFirstTR candiDateListFirstTR ">
                                <p className="ClientListTableTH">Required Data<i class="fa-solid fa-sort faSolidIconSort" ></i></p>
                                <p className="ClientListTableTH">Candidate Data<i class="fa-solid fa-sort faSolidIconSort"></i></p>
                                <p className="ClientListTableTH">Matching Score<i class="fa-solid fa-sort faSolidIconSort"></i></p>
                            </div>
                        </div>
                        <div className="ClientListTableTbody candiDateListTableTbody">
                            <div className="clientListSecondTR candiDatetListSecondTR">
                                <p className="clientListTD">Sentimental Analysis Role & Cultural Fitment</p>
                                <p className="clientListTD">2.4 Years of Experience</p>
                                <p className="clientListTD">Janani</p>
                            </div>
                        </div>
                    </div>
                </div>
                                    </div>
                                    )}
                                      {activeCandiDateDetailsBoxFilter === "feedback" && (
                                <div className="getCandiDateDetailsFirstDivFilter">
                                    <div className="feedBackButtons">
                                        <p className="feedbackButtonAll activeFeedbackButtonsAll">All</p>
                                        <p className="feedbackButtonAll ">HR</p>
                                        <p className="feedbackButtonAll ">Recruiter</p>
                                        <p className="feedbackButtonAll ">Interview</p>
                                        <p className="feedbackButtonAll ">Assessment</p>
                                    </div>
                                    <div className="feedbackButtonDivMain">
                                        <div className="feedbackButtonDivMainFlexDiv">
                                            <div style={{display:"flex" , gap:"10px"}} className="feedbackButtonMainSpan"
                                            >
<img src={candidateProfilePicture} className="candidateProfilePictureFeedback"/>
<span style={{display:"flex",flexDirection:"column"}}>
    <p className="designationNameFirst">Jason Jordan</p>
    <p className="designationName">Designation name</p>
</span>
                                            </div>
                                            <p className="candidateProfilePictureFeedbackP1">Client HR</p>
                                            <p className="candidateProfilePictureFeedbackP2">12 Jul 2023</p>
                                    </div>
                                  <div className="feedbackDesP">  <p className="feedbackButtonDivMainP">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p><i class="fa-solid fa-thumbs-up feedbackThumbs"></i></div>
                                    </div>
                                    <div className="feedbackButtonDivMain">
                                        <div className="feedbackButtonDivMainFlexDiv">
                                            <div style={{display:"flex" , gap:"10px"}} className="feedbackButtonMainSpan"
                                            >
<img src={candidateProfilePicture} className="candidateProfilePictureFeedback"/>
<span style={{display:"flex",flexDirection:"column"}}>
    <p className="designationNameFirst">Jason Jordan</p>
    <p className="designationName">Designation name</p>
</span>
                                            </div>
                                            <p className="candidateProfilePictureFeedbackP1">Client HR</p>
                                            <p className="candidateProfilePictureFeedbackP2">12 Jul 2023</p>
                                    </div>
                                    <div className="feedbackDesP">  <p className="feedbackButtonDivMainP">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p><i class="fa-solid fa-thumbs-up feedbackThumbs"></i></div>
                                    </div>
                                    <div className="feedbackButtonDivMain">
                                        <div className="feedbackButtonDivMainFlexDiv">
                                            <div style={{display:"flex" , gap:"10px"}} className="feedbackButtonMainSpan"
                                            >
<img src={candidateProfilePicture} className="candidateProfilePictureFeedback"/>
<span style={{display:"flex",flexDirection:"column"}}>
    <p className="designationNameFirst">Jason Jordan</p>
    <p className="designationName">Designation name</p>
</span>
                                            </div>
                                            <p className="candidateProfilePictureFeedbackP1">Client HR</p>
                                            <p className="candidateProfilePictureFeedbackP2">12 Jul 2023</p>
                                    </div>
                                    <div className="feedbackDesP">  <p className="feedbackButtonDivMainP">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p><i class="fa-solid fa-thumbs-up feedbackThumbs"></i></div>
                                    </div>
                                    </div>
                                    )}
                                      {activeCandiDateDetailsBoxFilter === "assessments" && (
                                <div className="getCandiDateDetailsFirstDivFilter">
                                    <div className="getNoFeedBackIshere">
                                    <div className="noFeedbackisHere">
<img src={feedBackImage} alt="feedBackImage" className="feedBackImage"/>
<p className="feedBackImagePara">No Feedback is there !!!</p>
                                    </div>
                                    </div>
                                    </div>
                                    )}
                                    {activeCandiDateDetailsBoxFilter === "interview" && (
                               <div className="getCandiDateDetailsFirstDivFilter">
                                <div className="getNoFeedBackIshere">
                                    <div className="noFeedbackisHere">
<img src={feedBackImage} alt="feedBackImage" className="feedBackImage"/>
<p className="feedBackImagePara">No Feedback is there !!!</p>
                                    </div>
                                    </div>
                                   </div>
                                   )}
                                      {activeCandiDateDetailsBoxFilter === "emailOfCandidate" && (
                                <div className="getCandiDateDetailsFirstDivFilter">
                                <div className="emailOfCandidateFlex">
                                      <p className="emailOfCandidateP1">123 conversation is there!!!
                                    </p>
                                    <p className="emailOfCandidateP2">Email Candidate</p>
                                    </div>  
                                        <div className="emailOfCandidateFlexDiv">
                                            <span className="emailOfCandidateFlexSpan"><p className="emailOfCandidateFlexSpanP">Cleony to Muruganathan</p><p className="emailOfCandidateFlexSpanP2">12 Jul 2023 | 12:34</p></span>
                                            <p className="emailOfCandidateFlexDivPSub">Sub : Lorem ipsum dolor sit amet, consetetur sadipscing elitr</p>
                                            <p className="emailOfCandidateFlexDivDescription">usam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                                        </div>
                                        <div className="emailOfCandidateFlexDiv">
                                            <span className="emailOfCandidateFlexSpan"><p className="emailOfCandidateFlexSpanP">Cleony to Muruganathan</p><p className="emailOfCandidateFlexSpanP2">12 Jul 2023 | 12:34</p></span>
                                            <p className="emailOfCandidateFlexDivPSub">Sub : Lorem ipsum dolor sit amet, consetetur sadipscing elitr</p>
                                            <p className="emailOfCandidateFlexDivDescription">usam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                                        </div>
                                        <div className="emailOfCandidateFlexDiv">
                                            <span className="emailOfCandidateFlexSpan"><p className="emailOfCandidateFlexSpanP">Cleony to Muruganathan</p><p className="emailOfCandidateFlexSpanP2">12 Jul 2023 | 12:34</p></span>
                                            <p className="emailOfCandidateFlexDivPSub">Sub : Lorem ipsum dolor sit amet, consetetur sadipscing elitr</p>
                                            <p className="emailOfCandidateFlexDivDescription">usam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                                        </div>
                                    </div>
                                    )}
                                     {activeCandiDateDetailsBoxFilter === "timelineOfcandidate" && (
                                <div className="getCandiDateDetailsFirstDivFilter">
<div className="timelineOfCandiDateDiv">
    <p className="timelineOfCandiDateDivP1">Applied on - 12 Jul 2023 | 12:34</p>
    <p  className="timelineOfCandiDateDivP2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
    </div>
    <div className="timelineOfCandiDateDiv">
    <p className="timelineOfCandiDateDivP1">Applied on - 12 Jul 2023 | 12:34</p>
    <p  className="timelineOfCandiDateDivP2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
    </div>
    <div className="timelineOfCandiDateDiv">
    <p className="timelineOfCandiDateDivP1">Applied on - 12 Jul 2023 | 12:34</p>
    <p  className="timelineOfCandiDateDivP2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
    </div>
                                    </div>
                                    )}

                        </div>
                    </div>)}
            </div>
        </>
    )

}
