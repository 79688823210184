import ExploreByCategory from "./ExploreByCategory";
import FeaturedJobs from "./FeaturedJob";
import JobFooter from "./JobFooter";
import LatestJobs from "./LatestJobs";
import faceWithoutBg from "../../Assets/png/faceWithoutBg.png"
import { Link } from "react-router-dom";
import blueRaysImg from "../../Assets/png/blueRays.png"
import jarvixJobSearchIMg from "../../Assets/png/jarvixJobLocation.svg"
import jarvixJobLocationLIMg from "../../Assets/png/jarvixJobSearch.svg"
import "./index.css"
const JarvixJobDashboard=()=>{
return (
    <>
    <div className="JarvixJobDashboardMaiDiv">

    
    {/* navbar Part */}
    <div className="JarvixJobDashboardHeader">
    <div className="JarvixJobDashboardNavbar">
        <div className="JarvixJobDashboardNavbarLinks">
        <img className="footerLogoJob" alt="" src={faceWithoutBg}/>
        <Link to="/" className="NavbarLinksTwo">Find Jobs</Link>
        <Link to="/" className="NavbarLinksTwo">Browse Companies</Link>
        </div>
        <div className="JarvixJobDashboardNavbarLoginPart">
            <Link to="/candidate/signIn" className="JarvixJobDashboardNavbarLogin1">
                Login
            </Link>
            <Link to="/candidate/login" className="JarvixJobDashboardNavbarLogin2">
               Sign Up
            </Link>
        </div>
    </div>
    <div className="JarvixJobDashboardHeaderDetails">
<h1 className="JarvixJobDashboardHeadingOne">Discover more than<p className="JarvixJobDashboardHeadingTwo">5000+ Jobs</p></h1>
<img src={blueRaysImg} alt="blueRaysImg" className="blueRaysImg"/>
<p className="greatplatformP">Great platform for the job seeker that searching for new career heights and passionate about startups.</p>
<form className="jarvixheaderFormSearch">
    <div className="SearchJobByLocation">
<span className="SearchJobByLocationSpan"><img src={jarvixJobSearchIMg} className="jarvixJobLocationIMg"/><input type="text" className="searchJobsByInput" placeholder="Job title or keyword"/></span>
<span className="SearchJobByLocationSpan"><img src={jarvixJobLocationLIMg} className="jarvixJobLocationIMg"/><select className="jobSearchSelectLocation">
    <option>Canada , USA</option></select></span>
<button type="submit" className="jobSearchButton">Search my job</button>
    </div>
</form>
<p className="popularSearches">Popular : UI Designer, UX Researcher, Android, Admin</p>
    </div>
    </div>
    {/* explore by category */}
    <ExploreByCategory/>
  
    {/* featured jobs */}
    <FeaturedJobs/>
    {/* latest jobs */}
    <LatestJobs/>
    {/* footer */}
    <JobFooter/>
    </div>
    </>
)
}

export default JarvixJobDashboard;