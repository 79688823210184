import React from 'react'
import SideBar from '../../Components/Sidebar/Sidebar'
import "./styles.css"

const Home = () => {
  return (
    <div className='Main_homeContainer'>
      <SideBar/>
    </div>
  )
}

export default Home
