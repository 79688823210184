import { Link } from "react-router-dom"
import "./CandidateDetailsSidebar.css"
import DashIMG1 from "../../Assets/png/DashIMG1.png"
import DashIMG2 from "../../Assets/png/DashIMG2.png"
import DashIMG3 from "../../Assets/png/DashIMG3.png"
import DashIMG4 from "../../Assets/png/DashIMG4.png"
import DashIMG5 from "../../Assets/png/DashIMG5.png"
import DashIMG6 from "../../Assets/png/DashIMG6.png"
import DashIMG7 from "../../Assets/png/DashIMG7.png"
import DashIMG8 from "../../Assets/png/DashIMG8.png"
const JobSearchDashboard= ()=>{
    return (
        <>
        <div className="JobSearchDashboardMain">
            <div className="JobSearchDashboardHeader">
                <Link to={""} className="JobSearchDashboardLink" id="JobSearchDashboardUser">LG</Link>
                <Link to={""} className="JobSearchDashboardLink"><img src={DashIMG1} className=""/></Link>
                <Link to={""} className="JobSearchDashboardLink"><img src={DashIMG2} className=""/></Link>
                <Link to={""} className="JobSearchDashboardLink"><img src={DashIMG3} className=""/></Link>
                <Link to={""} className="JobSearchDashboardLink"><img src={DashIMG4} className=""/></Link>
                <Link to={""} className="JobSearchDashboardLink"><img src={DashIMG5} className=""/></Link>
                <Link to={""} className="JobSearchDashboardLink"><img src={DashIMG6} className=""/></Link>
                <Link to={""} className="JobSearchDashboardLink"><img src={DashIMG7} className=""/></Link>
                <Link to={""} className="JobSearchDashboardLink"><img src={DashIMG8} className=""/></Link>
            </div>
        </div>
        
        </>
    )
}
export default JobSearchDashboard