import React, { useState } from "react";
import "./styles.css";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Dashboard from "../../Pages/Dashboard";
import Schedule from "../../Pages/Schedule";
import Employee from "../../Pages/Employee";
import Department from "../../Pages/Department";
import tripprLogo from "../../Assets/png/new-logo.svg";
import calendar from "../../Assets/png/calendar.png";
import dashboard from "../../Assets/png/dashboard.png";
import department from "../../Assets/png/department.png";
import employee from "../../Assets/png/employee.png";
import settings from "../../Assets/png/settings.png";
import support from "../../Assets/png/support.png";
import Header from "../Header";
import upgrade_now from "../../Assets/png/upgrade-now.png"
import upgrade_now_two from "../../Assets/png/upgrade-image-two.svg"
import { Link, useParams } from 'react-router-dom';

const SideBar = () => {
  const { component } = useParams;
  const [currentPage, setCurrentPage] = useState("dashboard");
  // const navigate = useNavigate();
  const handleNavClick = (page) => {
    setCurrentPage(page);
    // navigate(`/${page.toLowerCase()}`);
  };

  return (
    <div className="sideBarContainer">
      <div className="sideBar">
        <Container fluid style={{ backgroundColor: "#fafafb" }}>
          <Row className="h-100" id="main_row">
            <Col id="colSidebar" sm={2} className="colSidebar">
              <div className="sidebar_mainHeader">
                <img
                  src={tripprLogo}
                  alt="Trippr.co.in"
                 className="sidebar_tripprLogo"
                />
              </div>
              <Nav defaultActiveKey="dashboard" className="flex-column">
                <Nav.Link
                  eventKey="dashboard" as={Link} to="/corporate_rentals/home/dashboard"
                  onClick={() => handleNavClick("dashboard")} 
                 
                ><span className={currentPage === "dashboard" ? "activeTab" : ""}><img src={dashboard} alt="" /></span>Dashboard</Nav.Link>
                <Nav.Link
                  eventKey="about" as={Link} to="/corporate_rentals/home/schedule"
                  onClick={() => handleNavClick("schedule")}
                 
                >
                  <span className={currentPage === "schedule" ? "activeTab" : ""}> <img src={calendar} alt="" />  Schedule</span> 
                </Nav.Link>
                <Nav.Link
                  eventKey="contact" as={Link} to="/corporate_rentals/home/employee"
                  onClick={() => handleNavClick("employee")}
                  
                >
             <span className={currentPage === "employee" ? "activeTab" : ""}>     <img src={employee} alt="" /></span>  
                  Employee
                </Nav.Link>
                <Nav.Link
                  eventKey="contact" as={Link} to="/corporate_rentals/home/department"
                  onClick={() => handleNavClick("department")}
                  >
          <span className={currentPage === "department" ? "activeTab" : ""}>         <img src={department} alt="" /> </span> Department
                </Nav.Link>
                <Nav.Link
                  eventKey="dashboard" as={Link} to="/corporate_rentals/home/support"
                  onClick={() => handleNavClick("support")} 
                 
                 >
              <span className={currentPage === "support" ? "activeTab" : ""}>     <img src={support} alt="" /> </span> Support
                </Nav.Link>
                <Nav.Link
                  eventKey="about" as={Link} to="/corporate_rentals/home/settings"
                  onClick={() => handleNavClick("settings")}
                 
                >
            <span className={currentPage === "settings" ? "activeTab" : ""}>       <img src={settings} alt="" /></span>  Settings
                </Nav.Link>
              </Nav>
              <div className="upgrade_now">
  <img src={upgrade_now} alt="upgrade_now" className="upgrade_now_image"/>
  <img src={upgrade_now_two} alt="upgrade_now" className="upgrade_now_image_two"/>
  <button className="upgrade_now_button">Upgrade Now</button>
</div>
              <div>
                <Header />
              </div>
            </Col>
            <Col id="colRightBar" sm={10} className="colRightBar">
              {currentPage === "dashboard" && <Dashboard />}
              {currentPage === "schedule" && <Schedule />}
              {currentPage === "employee" && <Employee />}
              {currentPage === "department" && <Department />}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SideBar;
