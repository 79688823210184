import { Link } from "react-router-dom"
import LatestJobsOpenImgArrow from "../../Assets/png/rightArrowBlue.png"
import ExploreByCategoryImg from "../../Assets/png/CategoryImg1.png"
import blueBackgroundImg from "../../Assets/png/blueRectangle.png"
const ExploreByCategory=()=>{
    return (
        <>
        <div className="ExploreByCategoryFirst">
        <div className="LatestJobsOpenheadings">
    <div className="LatestJobsOpenH4">
    Explore<span className="LatestJobsOpenH4Span"> by category</span>
    </div>
    <Link to="/"  className="showAllJobsLink">Show all jobs <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/></Link>
</div>
<div className="ExploreByCategory">
    <div className="ExploreByCategoryTwo">
        <img src={ExploreByCategoryImg}alt="ExploreByCategoryImg" className="ExploreByCategoryImg"/>
        <p className="ExploreByCategoryType">Marketing</p>
        <Link to="/" className="explorebyCategoryLinkA">235 jobs available <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/> </Link>
    </div>
    <div className="ExploreByCategoryTwo">
        <img src={ExploreByCategoryImg}alt="ExploreByCategoryImg" className="ExploreByCategoryImg"/>
        <p className="ExploreByCategoryType">Marketing</p>
        <Link to="/" className="explorebyCategoryLinkA">235 jobs available <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/> </Link>
    </div>
    <div className="ExploreByCategoryTwo">
        <img src={ExploreByCategoryImg}alt="ExploreByCategoryImg" className="ExploreByCategoryImg"/>
        <p className="ExploreByCategoryType">Marketing</p>
        <Link to="/" className="explorebyCategoryLinkA">235 jobs available <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/> </Link>
    </div>
    <div className="ExploreByCategoryTwo">
        <img src={ExploreByCategoryImg}alt="ExploreByCategoryImg" className="ExploreByCategoryImg"/>
        <p className="ExploreByCategoryType">Marketing</p>
        <Link to="/" className="explorebyCategoryLinkA">235 jobs available <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/> </Link>
    </div>
    <div className="ExploreByCategoryTwo">
        <img src={ExploreByCategoryImg}alt="ExploreByCategoryImg" className="ExploreByCategoryImg"/>
        <p className="ExploreByCategoryType">Marketing</p>
        <Link to="/" className="explorebyCategoryLinkA">235 jobs available <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/> </Link>
    </div>
    <div className="ExploreByCategoryTwo">
        <img src={ExploreByCategoryImg}alt="ExploreByCategoryImg" className="ExploreByCategoryImg"/>
        <p className="ExploreByCategoryType">Marketing</p>
        <Link to="/" className="explorebyCategoryLinkA">235 jobs available <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/> </Link>
    </div>
    <div className="ExploreByCategoryTwo">
        <img src={ExploreByCategoryImg}alt="ExploreByCategoryImg" className="ExploreByCategoryImg"/>
        <p className="ExploreByCategoryType">Marketing</p>
        <Link to="/" className="explorebyCategoryLinkA">235 jobs available <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/> </Link>
    </div>
    <div className="ExploreByCategoryTwo">
        <img src={ExploreByCategoryImg}alt="ExploreByCategoryImg" className="ExploreByCategoryImg"/>
        <p className="ExploreByCategoryType">Marketing</p>
        <Link to="/" className="explorebyCategoryLinkA">235 jobs available <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/> </Link>
    </div>
</div>
<div className="ctaPart">
    <div className="ctaPartDiv">
        <p className="ctaPartDivP1">Start posting jobs today</p>
        <p className="ctaPartDivP2">Start posting jobs for only $10.</p>
        <Link to="/" className="signUpForFreeLink" >Sign Up For Free</Link>
    </div>
    <div className="blueBackgroundImgDiv">
        <img className="blueBackgroundImg" src={blueBackgroundImg}/>
    </div>
</div>
        </div>
        </>
    )
}
export default ExploreByCategory;