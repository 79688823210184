import { Link } from "react-router-dom"
import LatestJobsOpenImgArrow from "../../Assets/png/rightArrowBlue.png"
import NomadImg from "../../Assets/png/Nomad.png"

const LatestJobs=()=>{

    return(
        <>
        <div className="LatestJobsOpen">
<div className="LatestJobsOpenheadings">
    <div className="LatestJobsOpenH4">
    Latest <span className="LatestJobsOpenH4Span">jobs open</span>
    </div>
    <Link  className="showAllJobsLink" to="/">Show all jobs <img src={LatestJobsOpenImgArrow} alt="" className="LatestJobsOpenImgArrow"/></Link>
</div>

<div className="LatestJobsOpensLists">
    <div className="LatestJobsOpensMainList">
        <img className="LatestJobsOpensMainListImg" src={NomadImg} alt="NomadImg"/>
        <div className="LatestJobsOpensMainListFlexDiv">
             <p className="latestJobsTitle">Social Media Assistant</p>
             <p className="latestJobsTitleLocation">Nomad . Paris, France</p>
             <div className="latestJobsAboutProfile">
             <p id="borderLeftProfile" className="latestJobsAboutProfileAllPs">Full Time</p>
             <p id="latestJobsAboutProfile2"  className="latestJobsAboutProfileAllPs">Marketing</p>
             <p id="latestJobsAboutProfile3"  className="latestJobsAboutProfileAllPs">Design</p>

             </div>
        </div>
    </div>
    <div className="LatestJobsOpensMainList">
        <img className="LatestJobsOpensMainListImg" src={NomadImg} alt="NomadImg"/>
        <div className="LatestJobsOpensMainListFlexDiv">
             <p className="latestJobsTitle">Social Media Assistant</p>
             <p className="latestJobsTitleLocation">Nomad . Paris, France</p>
             <div className="latestJobsAboutProfile">
             <p id="borderLeftProfile" className="latestJobsAboutProfileAllPs">Full Time</p>
             <p id="latestJobsAboutProfile2"  className="latestJobsAboutProfileAllPs">Marketing</p>
             <p id="latestJobsAboutProfile3"  className="latestJobsAboutProfileAllPs">Design</p>

             </div>
        </div>
    </div>
    <div className="LatestJobsOpensMainList">
        <img className="LatestJobsOpensMainListImg" src={NomadImg} alt="NomadImg"/>
        <div className="LatestJobsOpensMainListFlexDiv">
             <p className="latestJobsTitle">Social Media Assistant</p>
             <p className="latestJobsTitleLocation">Nomad . Paris, France</p>
             <div className="latestJobsAboutProfile">
             <p id="borderLeftProfile" className="latestJobsAboutProfileAllPs">Full Time</p>
             <p id="latestJobsAboutProfile2"  className="latestJobsAboutProfileAllPs">Marketing</p>
             <p id="latestJobsAboutProfile3"  className="latestJobsAboutProfileAllPs">Design</p>

             </div>
        </div>
    </div>
    <div className="LatestJobsOpensMainList">
        <img className="LatestJobsOpensMainListImg" src={NomadImg} alt="NomadImg"/>
        <div className="LatestJobsOpensMainListFlexDiv">
             <p className="latestJobsTitle">Social Media Assistant</p>
             <p className="latestJobsTitleLocation">Nomad . Paris, France</p>
             <div className="latestJobsAboutProfile">
             <p id="borderLeftProfile" className="latestJobsAboutProfileAllPs">Full Time</p>
             <p id="latestJobsAboutProfile2"  className="latestJobsAboutProfileAllPs">Marketing</p>
             <p id="latestJobsAboutProfile3"  className="latestJobsAboutProfileAllPs">Design</p>

             </div>
        </div>
    </div>
    <div className="LatestJobsOpensMainList">
        <img className="LatestJobsOpensMainListImg" src={NomadImg} alt="NomadImg"/>
        <div className="LatestJobsOpensMainListFlexDiv">
             <p className="latestJobsTitle">Social Media Assistant</p>
             <p className="latestJobsTitleLocation">Nomad . Paris, France</p>
             <div className="latestJobsAboutProfile">
             <p id="borderLeftProfile" className="latestJobsAboutProfileAllPs">Full Time</p>
             <p id="latestJobsAboutProfile2"  className="latestJobsAboutProfileAllPs">Marketing</p>
             <p id="latestJobsAboutProfile3"  className="latestJobsAboutProfileAllPs">Design</p>

             </div>
        </div>
    </div>
    <div className="LatestJobsOpensMainList">
        <img className="LatestJobsOpensMainListImg" src={NomadImg} alt="NomadImg"/>
        <div className="LatestJobsOpensMainListFlexDiv">
             <p className="latestJobsTitle">Social Media Assistant</p>
             <p className="latestJobsTitleLocation">Nomad . Paris, France</p>
             <div className="latestJobsAboutProfile">
             <p id="borderLeftProfile" className="latestJobsAboutProfileAllPs">Full Time</p>
             <p id="latestJobsAboutProfile2"  className="latestJobsAboutProfileAllPs">Marketing</p>
             <p id="latestJobsAboutProfile3"  className="latestJobsAboutProfileAllPs">Design</p>

             </div>
        </div>
    </div>

</div>
        </div>
        </>
    )
}
export default LatestJobs