import React, { useEffect, useState } from "react";
import "../styles.css";
import { ToastContainer, toast } from "react-toastify";
import {
  addEmployee,
  deleteEmployeeList,
  editEmployee,
  getEmployeeList,
} from "../../Api/employee";
import PhoneIcon from "../../Assets/png/phone-icon.svg";
import MailIcon from "../../Assets/png/mail-icon.svg";
import searchIcon from "../../Assets/png/search-icon.svg";
import employeeEditB from "../../Assets/png/editButtonEmployee.svg"
import employeeDeleteB from "../../Assets/png/delteButtonEmployee.svg";
import arrowDown from "../../Assets/png/arrowDown.png";
import deleteIconCheckbox from "../../Assets/png/deleteIconCheckbox.svg";
import boxEmployeeAddress from "../../Assets/png/boxEmployeeAddress.svg";
import boxEmployeePhone from "../../Assets/png/boxEmployeePhone.svg";
import boxEmployeeEmail from "../../Assets/png/boxEmployeeEmail.svg";

const Employee = () => {
  const [showMore, setShowMore] = useState(false);
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [EmployeeList, setEmployeeList] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    area: "",
    city: "",
    state: "",
    pincode: "",
    coordinates: "",
    gender: "",
  });

  useEffect(() => {
    getEmployeeData();
  }, []);

  const getEmployeeData = async () => {
    const res = await getEmployeeList();
    console.log(res);
    setDataList([...res.data]);
  };
  // console.log(EmployeeList)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emplyeeData = {
      id: EmployeeList?.id,
      name: EmployeeList.name,
      email: EmployeeList.email,
      mobileNumber: EmployeeList.mobileNumber,
      address: {
        area: EmployeeList.area,
        city: EmployeeList.city,
        state: EmployeeList.state,
        pincode: EmployeeList.pincode,
      },
      coordinates: EmployeeList.coordinates,
      gender: EmployeeList.gender,
    };

    if (!emplyeeData.name) {
      toast.error("fill Employee Name");
      return;
    }
    if (!emplyeeData.mobileNumber) {
      toast.error("fill mobileNumber");
      return;
    }
    if (!/^[0-9]{10}$/.test(emplyeeData.mobileNumber)) {
      toast.error("Enter Valid Number");
      return;
    }
    if (!emplyeeData.email) {
      toast.error("fill email");
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emplyeeData.email)) {
      toast.error("Enter valid email");
      return;
    }

    if (!emplyeeData.address.area) {
      toast.error("fill area");
      return;
    }
    if (!emplyeeData.address.city) {
      toast.error("fill city");
      return;
    }
    if (!emplyeeData.address.state) {
      toast.error("fill state");
      return;
    }
    if (!emplyeeData.address.pincode) {
      toast.error("fill pincode");
      return;
    }
    if (!emplyeeData.coordinates) {
      toast.error("fill pickUpPoint");
      return;
    }
    if (!emplyeeData.gender) {
      toast.error("fill gender");
      return;
    }
    if (EmployeeList?.id) {
      const resp = await editEmployee(emplyeeData);
      if (resp && resp.statusCode === 200) {
        if (resp.data) {
          const res = await getEmployeeList();
          setDataList([...res.data]);
          setEmployeeList({
            name: "",
            email: "",
            mobileNumber: "",
            area: "",
            city: "",
            state: "",
            pincode: "",
            coordinates: "",
            gender: "",
          });
          toast.success(resp.message);
          setShowAddEmployee(false);
        } else {
          toast.error(resp.message);
        }
      } else {
        toast.error(resp.message);
      }
      return;
    }
    const resp = await addEmployee(emplyeeData);
    // console.log(resp)
    if (resp && resp.statusCode === 200) {
      if (resp.data) {
        const res = await getEmployeeList();
        setDataList([...res.data]);
        toast.success(resp.message);
        setShowAddEmployee(false);

        setEmployeeList({
          name: "",
          email: "",
          mobileNumber: "",
          area: "",
          city: "",
          state: "",
          pincode: "",
          coordinates: "",
          gender: "",
        });
        console.log(dataList);
      } else {
        toast.error(resp.message);
      }
    } else {
      toast.error(resp.message);
    }
  };
  
  

  useEffect(() => {
    getEmployeeData();
  }, []);
  const [visibleBoxRow, setVisibleBoxRow] = useState(null); // To manage the visibility of the right box

  // ... Other code ...

  const handleClickThreeDots = (index) => {
    // Toggle the visibility of the right box
    setVisibleBoxRow(visibleBoxRow === index ? null : index);
  };
  const handleEdit = (employee,index) => {
    setShowAddEmployee(true);
    setEmployeeList({
      id: employee._id,
      name: employee.name,
      email: employee.email,
      mobileNumber: employee.mobileNumber,
      area: employee.address.area,
      city: employee.address.city,
      state: employee.address.state,
      pincode: employee.address.pincode,
      coordinates: employee.coordinates,
      gender: employee.gender,
    });
    setVisibleBoxRow(index); // Close the box
    setSelectedRow(null)
  };

  const handleDelete = async (employeeId,index) => {
    const response = await deleteEmployeeList(employeeId);
    if (response.message) {
      const res = await getEmployeeList();
      setDataList([...res.data]);
      toast.success(response.message);
    }
    setVisibleBoxRow(index); // Close the box
    setSelectedRow(null)
  };
  // console.log(editEmployeeList?.address)

  const renderedList = showMore ? dataList : dataList.slice(0, 5);

  const handleClick = () => {
    setShowMore(!showMore);
  };
  const onChange = (e) => {
    setEmployeeList({ ...EmployeeList, [e.target.name]: e.target.value });
  };

  console.log(dataList.length);
  const [search, setSearch] = useState("");
  const handleSearchByEmployeeName = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
  };
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectAll, setSelectAll] = useState(false); // To manage select all checkbox
  const [selectedRows, setSelectedRows] = useState([]); // To store selected rows
  // Function to handle checkbox selections
  const handleCheckboxChange = (index) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = !updatedSelectedRows[index];
    setSelectedRows(updatedSelectedRows);
  };

  // Function to handle bulk delete
  const handleBulkDelete = () => {
    const idsToDelete = selectedRows
      .map((isSelected, index) => (isSelected ? dataList[index]._id : null))
      .filter((id) => id !== null);

    if (idsToDelete.length === 0) {
      toast.error("Select at least one employee to delete.");
      return;
    }

    // Call the API to delete selected employees
    // Update dataList, remove deleted items
    const updatedDataList = dataList.filter(
      (_, index) => !selectedRows[index]
    );

    // Use a Promise to ensure the API call finishes before updating the state
    Promise.all(idsToDelete.map((id) => deleteEmployeeList(id)))
      .then(() => {
        toast.success("Selected employees deleted successfully.");
        setDataList(updatedDataList);
        setSelectedRows(new Array(updatedDataList.length).fill(false));
      })
      .catch((error) => {
        toast.error("Error deleting employees: " + error.message);
      });
  };

  // Function to toggle select all
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedSelectedRows = new Array(dataList.length).fill(!selectAll);
    setSelectedRows(updatedSelectedRows);
  };
  
  return (
    <>
    <div className="dashboard_container">
      <ToastContainer />
      
      <div className="title_container_dashboard">
        <h2>Employee List</h2>
        <div className="searchAndAddEmployee">
          <div className="searchInputIcon">
            <input type="text"  onChange={handleSearchByEmployeeName} placeholder="Search" /><img src={searchIcon} alt="serachIcon" /></div>
          <button onClick={() => setShowAddEmployee(true)}> + Add New</button>
        </div>

      </div>
      {showAddEmployee && (
        <div className="details_mainContainer">
          <div className="employee_upper_container">
            <div className="employee_upper_contents">
              <p>Employee Name/ID</p>
              <input
                name="name"
                value={EmployeeList.name}
                onChange={onChange}
                placeholder="Enter the Name/ID"
              />
            </div>
            <div className="employee_upper_contents">
              <p>Gender</p>
              <input
                name="gender"
                value={EmployeeList.gender}
                onChange={onChange}
                placeholder="Enter Gender"
              />
            </div>
            <div className="employee_upper_contents">
              <p>Contact Number</p>
              <input
                type="tel"
                name="mobileNumber"
                value={EmployeeList.mobileNumber}
                onChange={onChange}
                placeholder="Enter phone number"
              />
            </div>
            <div className="employee_upper_contents">
              <p>Email ID</p>
              <input
                name="email"
                value={EmployeeList.email}
                onChange={onChange}
                placeholder="Enter the mail Id"
              />
            </div>

            <div className="employee_upper_contents">
              <p>Area</p>
              <input
                name="area"
                value={EmployeeList.area}
                onChange={onChange}
                placeholder="Enter the Area"
              />
            </div>
            <div className="employee_upper_contents">
              <p>City</p>
              <input
                name="city"
                value={EmployeeList.city}
                onChange={onChange}
                placeholder="Enter the city"
              />
            </div>
            <div className="employee_upper_contents">
              <p>State</p>
              <input
                name="state"
                value={EmployeeList.state}
                onChange={onChange}
                placeholder="Enter the state"
              />
            </div>
            <div className="employee_upper_contents">
              <p>Pincode</p>
              <input
                name="pincode"
                value={EmployeeList.pincode}
                onChange={onChange}
                type="text"
                pattern="[0-9]{6}"
                placeholder="Enter the pincode"
              />
            </div>
            <div className="employee_upper_contents">
              <p>Pick-Up Point Address</p>
              <input
                name="coordinates"
                value={EmployeeList.coordinates}
                onChange={onChange}
                placeholder="Enter the Pickup Area"
              />
            </div>
          </div>
          <div className="lower_container"></div>
          <div className="submitForm">
            <button onClick={handleSubmit}>
              {EmployeeList?.id ? "Update" : "Add"}
            </button>
            <button
              onClick={() => {
                setShowAddEmployee(false);
              }}
              style={{ marginLeft: "16px" }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <div className="routes_container">
        <div className="login_routes">
          <div className="routes_content">
            <div className="routes_content_heading">
              <span><input type="checkbox"  checked={selectAll}
              onChange={toggleSelectAll}  className="routes_content_heading_checkbox" /></span>
              <span style={{marginLeft:"-1rem"}}>Name <img src={arrowDown} alt="arrowDown"/></span>
              <span style={{marginLeft:"5rem"}}>Contact No.<img src={arrowDown} alt="arrowDown"/></span>
              <span>Email <img src={arrowDown} alt="arrowDown"/></span>
              <span>Residential Address <img src={arrowDown} alt="arrowDown"/></span>
              <span>Pickup Address <img src={arrowDown} alt="arrowDown"/></span>
              <span onClick={handleBulkDelete}><img src={deleteIconCheckbox} alt="deleteIconCheckbox"/></span>
            </div>
            {renderedList && renderedList.length
                    ? renderedList
                        .filter((data) => {
                          if (search == "") {
                            return data;
                          } else if (
                            data.name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                            ) {
                              return data;
                            }
                          }).map((item, index) => {
                            const isSelected = selectedRows[index];
    const isOpen = selectedRow === index; 
              return (
                <>
                <div className="content_para" key={index}>
                  <div onClick={(e) => {
                              if (
                                !e.target.classList.contains(
                                  "employeEditDeleteButton"
                                ) &&
                                !e.target.classList.contains("employeeCheckbox")
                              ) {
                                setSelectedRow(isSelected ? null : index);
                                setSelectedRow(isOpen ? null : index);

                              }
                            }} className="employee_list_item">                                    
                    <p><input type="checkbox" checked={isSelected}
                                onChange={() => handleCheckboxChange(index)} className="employeeCheckbox" /></p>
                    <div style={{ display: "flex",width:"140px" }}> <p className="user-info">{item.name[0]}</p><p style={{ width: "" }}> {item.name.split(" ")[0]} </p></div>
                    <p style={{ width:"107px" }}>
                      <img src={PhoneIcon} alt="" /> {item.mobileNumber}
                    </p>
                    <p style={{ width:"112px" }}>
                      <img src={MailIcon} alt="" /> {item.email.split("").slice(0, 10).join("")}...
                    </p>
                    <p id="content_people" style={{ width:"112px" }}>
                      {item.address.area.split("").slice(0, 14).join("")}...
                    </p>
                    <p id="content_people" style={{ width:"112px" }}>
                      {item.coordinates.split("").slice(0, 14).join("")}...
                    </p>
                    <button
                      onClick={() => handleClickThreeDots(index)}
                      className="employeEditDeleteButton"
                    >
                      ...
                    </button>

                    {visibleBoxRow === index && (
                      <div className="employeeEditDeleteBox">
                        <button
                          onClick={(e) =>{ e.stopPropagation();
                             handleEdit(item)}}
                          className="employeeEditB"
                        >
                          <span>
                            <img src={employeeEditB} alt="edit" /> Edit
                          </span>
                        </button>
                        <button
                          onClick={(e) =>{ e.stopPropagation();
                            handleDelete(item._id)}}
                          className="employeeDeleteB"
                        >
                          <span>
                            <img src={employeeDeleteB} alt="edit" /> Delete
                          </span>
                        </button>
                      </div>
                    )}

                  </div>
                  {selectedRow === index && (
                    <div className={`${selectedRow === index ? 'overlay':''}`}>
                  <div  className={`box_wrapper_employee ${selectedRow === index ? "show" : ""}`}>
                    <div className="box_employee">
                      <div className="box_employee_details">
                        <p className="box_employee_details_p0">{item.name[0]}</p>
                      <p className="box_employee_details_p1">{item.name}</p> 
                      <p className="box_employee_details_p2">UI/UX Designer</p> 
                      </div>
                      <hr style={{width:"90%",margin:"auto"}}/>

                      <div className="box_employee_contact">
<span>Contact Info</span>
                      <p><img src={boxEmployeeEmail} alt=""/> &nbsp;{item.email}</p>
                      <p><img src={boxEmployeePhone} alt=""/> &nbsp;{item.mobileNumber}</p>
                      {/* <p>Residential Address : {item.address.area}</p> */}
                      <p><img src={boxEmployeeAddress} alt=""/> &nbsp;{item.coordinates}</p>
                      </div>
                      <div className="box_employee_attendance"> 
                      <span>Attendance</span>
                      </div>
                    </div>
                  </div></div>
                  )}
                </div>
                </>
              );
            }):null}
          </div>
          <div className="seeMore">
            {/* <p className='see_all'>See All</p> */}
            {dataList.length > 5 && (
              <button onClick={handleClick}>
                {showMore ? "See Less" : "See More"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Employee;
