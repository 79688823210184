import { Link } from "react-router-dom";
import faceWithoutBg from "../../Assets/png/faceWithoutBg.png"
import Social1 from "../../Assets/png/Social1.png"
import Social2 from "../../Assets/png/Social2.png"
import Social3 from "../../Assets/png/Social3.png"
import Social4 from "../../Assets/png/Socail4.png"

const JobFooter =()=>{
    return (
        <>
        <div className="jobFooter" style={{background: "#202430"}}>
<div className="jobFooterMainDiv">
    <div className="jobFooterMainDivFlex">
        <img className="footerLogoJob" alt="" src={faceWithoutBg}/>
        <p className="jobFooterflexP">
        Great platform for the job seeker that passionate about startups. Find your dream job easier.
        </p>
    </div>
<div className="jobFooterMainDivFlex" >
    <p className="jobFooterFlexheadings">About</p>
    <Link className="jobFooterFlexheadingsLink" to="/">Client Login</Link>
    <Link className="jobFooterFlexheadingsLink" to="/">Pricing</Link>
    <Link className="jobFooterFlexheadingsLink"to="/">Terms</Link>
    <Link className="jobFooterFlexheadingsLink"to="/">Advice</Link>
    <Link className="jobFooterFlexheadingsLink"to="/">Privacy Policy</Link>
</div>
<div className="jobFooterMainDivFlex" >
    <p className="jobFooterFlexheadings">Resources</p>
    <Link className="jobFooterFlexheadingsLink" to="/hr/signIn">OnBoarding</Link>
    <Link className="jobFooterFlexheadingsLink" to="/corporate_rentals/sign-in">ETS - By Trippr</Link>
    <Link className="jobFooterFlexheadingsLink"to="/">Updates</Link>
    <Link className="jobFooterFlexheadingsLink"to="/">Contact Us</Link>
</div>
<div className="jobFooterMainDivFlex" >
    <p className="jobFooterFlexheadings">Get job notifications</p>
    <p className="jobFooterflexPLast">
    The latest job news, articles, sent to your inbox weekly.
        </p>
        <form className="jobFooterForm">
            <input className="jobFooterFormInput" type="email" placeholder="Email Address"/><button className="jobFooterFormButton" type="submit">Subscribe</button>
        </form>
</div>

</div>
<hr className="jobFooterFormHr"/>
<div className="jobFooterFormLastDiv">
    <p className="jobFooterDeadline">2023 @ MarkCipher Pvt Ltd. All rights reserved.</p>
    <span className="jobFooterSocial">
        <a href="/"><img src={Social1} alt="" className="SocialImagesLinks"/></a>
        <a href="/"><img src={Social2} alt="" className="SocialImagesLinks"/></a>
        <a href="/"><img src={Social3} alt="" className="SocialImagesLinks"/></a>
        <a href="/"><img src={Social4} alt="" className="SocialImagesLinks"/></a>
    </span>
</div>
        </div>
        </>
    )
}
export default JobFooter;