import "./style.css";
import { Link } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { FaSort } from "react-icons/fa6";
import { useState } from "react";
import faceWithOutBg from "../../Assets/png/findYourGrowth.png"

const TotalJobs=()=>{
    const [showFilterBox,setShowFilterBox]=useState(false)
return(
    <>
<div className="totalJobs">
<div className="ClientListSearchBox"> 
  <span className="ClientListSearchSpan"><CiSearch className="CiSearch"/><input className="ClientListSearchSpanInput" type="search" placeholder="Search"/></span><i onClick={()=>setShowFilterBox(!showFilterBox)}  class="FaFilter fa-solid fa-sliders"></i><p className="ClientListSearchSpanP">Import</p> <Link to="/" className="ClientListSearchSpanLink">Post a Job</Link> 
</div>
{/* FILTER BOX */}
{showFilterBox && (
<div className="totalJobFilterBox">
    <div className="totalJobFilterBoxFilterMain">
    <div className="onBoardPrimaryDetailsData">
        <label className="onBoardPrimaryDetailsLabel">Client Name</label>
        <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
    </div>
    <div className="onBoardPrimaryDetailsData">
        <label className="onBoardPrimaryDetailsLabel">Role Name</label>
        <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
    </div>
    <div className="onBoardPrimaryDetailsData">
        <label className="onBoardPrimaryDetailsLabel">Location</label>
        <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
    </div>

    <div className="onBoardPrimaryDetailsData">
        <label className="onBoardPrimaryDetailsLabel">Experience(Years)</label>
        <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
    </div>
    <div className="onBoardPrimaryDetailsData">
        <label className="onBoardPrimaryDetailsLabel">Job Status</label>
        <select className="onBoardPrimaryDetailsInputData"><option>Select</option></select>
    </div>
    </div>
    <div className="totalJobFilterBoxDivTwo">
    <div className="onBoardPrimaryDetailsData">
        <label className="onBoardPrimaryDetailsLabel">Job Status</label>
        <input type="date" style={{width:"245px"}}className="onBoardPrimaryDetailsInputData"/>
    </div>
    <div className="filterBoxSubmit">
        <p className="buttonsCancAndNextCanc">Clear All</p>
        <p className="buttonsCancAndNextSave">Apply</p>
    </div>
    </div>
</div>

)}
{/* FILTER BUTTONS */}
<div className="totalJobboxPartStart">
<div className="totalJobsfilterButtons">
<div className="totalJobsHeadings">
    <p className="totalJobsHeadingsFilter" id="">URGENT (4)</p>
    <p className="totalJobsHeadingsFilter" id="">HIGH (4)</p>
    <p className="totalJobsHeadingsFilter" id="">MEDIUM (4)</p>
    <p className="totalJobsHeadingsFilter" id="">LOW (4)</p>
</div>
<p className="totalJobsHeadingsFilter" id="">CLOSED JOBS (4)</p>
</div>
<div className="totalJobsTotalList">
{/* URGENT JOBS */}
<div className="totalJobGridBox">
<div className="totalJobsJobBoxStart">
<div className="totalJobsJobBoxFirstHeadings">
    <span className="totalJobsJobBoxFirstSpan"><p className="totaljobTitle">UX/UI Designer</p>
    <p className="totalJobTime">5 days ago</p>
    </span>
    <span className="totalJobsJobBoxFirstSpan"><i class="fa-solid fa-share-nodes ShareButtonJob"></i><input type="checkbox"/><i class="fa-solid fa-ellipsis dotDot"></i>
    <div className="ellipsisLinksOfJob">
        <div className="ellipsisLinksOfJobList">
<Link className="ellipsisLinksOfJobListTag" to="">View Job</Link>
<Link className="ellipsisLinksOfJobListTag"to="">Edit Job</Link>
<Link className="ellipsisLinksOfJobListTag"to="">Add Prescreening</Link>
<Link className="ellipsisLinksOfJobListTag"to="">Add Applications&nbsp;<i class="fa-solid fa-angle-right"></i></Link>
<Link className="ellipsisLinksOfJobListTag"to="">Republish Job</Link>
<Link className="ellipsisLinksOfJobListTag"to="">Close Hiring</Link>
        </div>
        <div className="ellipsisLinksOfJobListSecond">
<Link className="ellipsisLinksOfJobListTag"to="">Import (CSV/PDF)</Link>
<Link className="ellipsisLinksOfJobListTag"to="">AInvite to Apply</Link>
<Link className="ellipsisLinksOfJobListTag"to="">Create Candidate</Link>
<Link className="ellipsisLinksOfJobListTag"to="">Import from Job board</Link>
        </div>
    </div>

    </span>
</div>
<span className="totalJobId">
    <p className="totalJobIdNo">Job ID : <p className="JobIdptagCopy">12343</p></p>
    <p className="totalJobIdClient">Client : BVS Technologies</p>
</span>
<div className="totalJobAboutTheJob">
    <span className="totalJobtheJobLocTime"><i class="fa-solid fa-location-dot locationIconFontA"></i>&nbsp;Pan India</span>
    <span className="totalJobtheJobLocTime"><i class="fa-solid fa-briefcase briefcaseTotalJob"></i>&nbsp;4 Years</span>
    <span className="totalJobtheJobLocTime"><i class="fa-regular fa-calendar-days"></i>&nbsp;10-Dec-2023</span>
    <span className="totalJobtheJobLocTime" ><i class="fa-solid fa-users recruitersAssigned"></i>&nbsp;Recruiters Assigned<p className="assignedPara">(12)</p></span>
</div>
<div className="totalJobsApplicants">
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">New Applicant</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">Reviewed</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">Inprogress</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
</div>
</div>
<div className="totalJobsJobBoxStart">
<div className="totalJobsJobBoxFirstHeadings">
    <span className="totalJobsJobBoxFirstSpan"><p className="totaljobTitle">UX/UI Designer</p>
    <p className="totalJobTime">5 days ago</p></span>
    <span className="totalJobsJobBoxFirstSpan"><i class="fa-solid fa-share-nodes ShareButtonJob"></i><input type="checkbox"/><i class="fa-solid fa-ellipsis dotDot"></i></span>
</div>
<span className="totalJobId">
    <p className="totalJobIdNo">Job ID : <p className="JobIdptagCopy">12343</p></p>
    <p className="totalJobIdClient">Client : BVS Technologies</p>
</span>
<div className="totalJobAboutTheJob">
    <span className="totalJobtheJobLocTime"><i class="fa-solid fa-location-dot locationIconFontA"></i>&nbsp;Pan India</span>
    <span className="totalJobtheJobLocTime"><i class="fa-solid fa-briefcase briefcaseTotalJob"></i>&nbsp;4 Years</span>
    <span className="totalJobtheJobLocTime"><i class="fa-regular fa-calendar-days"></i>&nbsp;10-Dec-2023</span>
    <span className="totalJobtheJobLocTime" ><i class="fa-solid fa-users recruitersAssigned"></i>&nbsp;Recruiters Assigned<p className="assignedPara">(12)</p></span>
</div>
<div className="totalJobsApplicants">
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">New Applicant</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">Reviewed</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">Inprogress</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
</div>
</div>
<div className="totalJobsJobBoxStart">
<div className="totalJobsJobBoxFirstHeadings">
    <span className="totalJobsJobBoxFirstSpan"><p className="totaljobTitle">UX/UI Designer</p>
    <p className="totalJobTime">5 days ago</p></span>
    <span className="totalJobsJobBoxFirstSpan"><i class="fa-solid fa-share-nodes ShareButtonJob"></i><input type="checkbox"/><i class="fa-solid fa-ellipsis dotDot"></i></span>
</div>
<span className="totalJobId">
    <p className="totalJobIdNo">Job ID : <p className="JobIdptagCopy">12343</p></p>
    <p className="totalJobIdClient">Client : BVS Technologies</p>
</span>
<div className="totalJobAboutTheJob">
    <span className="totalJobtheJobLocTime"><i class="fa-solid fa-location-dot locationIconFontA"></i>&nbsp;Pan India</span>
    <span className="totalJobtheJobLocTime"><i class="fa-solid fa-briefcase briefcaseTotalJob"></i>&nbsp;4 Years</span>
    <span className="totalJobtheJobLocTime"><i class="fa-regular fa-calendar-days"></i>&nbsp;10-Dec-2023</span>
    <span className="totalJobtheJobLocTime" ><i class="fa-solid fa-users recruitersAssigned"></i>&nbsp;Recruiters Assigned<p className="assignedPara">(12)</p></span>
</div>
<div className="totalJobsApplicants">
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">New Applicant</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">Reviewed</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
    <span className="totalJobsApplicantsDetails">
        <p className="totalJobsApplicantsDetailsP1">Inprogress</p>
        <p className="totalJobsApplicantsDetailsP2">24</p>
    </span>
</div>
</div>

</div>

{/* HIGH JOBS */}
{/* MEDIUM JOBS */}
{/* LOW JOBS */}
{/* CLOSED JOBS */}
{/* JOB VIEW AND EDIT */}
{/* <div className="ViewJObById">
    <div className="totaljobViewById">
<div className="totaljobViewByIdHeadings">
    <p className="totaljobViewByIdFirstP">View</p>
    <p className="totaljobViewByIdFirstP"><i class="fa-solid fa-xmark faCloseMark"></i></p>
</div>
<div className="totalJobCompanyPictures">
    <img src={faceWithOutBg} alt="" className="totalJobCompanyPictures1"/>
    <img src={faceWithOutBg} alt="" className="totalJobCompanyPictures2"/>
</div>
<div className="totalJobViewDetails">
    <div className="totalJobJobDetailsById">
<p className="totalJobJobDetailsByIdTwoP">Job Details</p>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">Job Title</p>
    <input className="totalJobDetailsbyIdInput" type="text"/>
</span>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">Location</p>
    <input className="totalJobDetailsbyIdInput" type="text"/>
</span>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">No. of Openings</p>
    <input className="totalJobDetailsbyIdInput" type="text"/>
</span>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">Skills</p>
    <span className="totalJobDetailsIdInputFlex">
    <input className="totalJobDetailsbyIdInput" type="text"/>
    <input className="totalJobDetailsbyIdInput" type="text"/>
    <input className="totalJobDetailsbyIdInput" type="text"/>

    </span>
</span>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">Work Experience</p>
    <input className="totalJobDetailsbyIdInput" type="text"/>
</span>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">Employment Type</p>
    <input className="totalJobDetailsbyIdInput" type="text"/>
</span>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">Minimum Education</p>
    <input className="totalJobDetailsbyIdInput" type="text"/>
</span>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">Salary</p>
    <input className="totalJobDetailsbyIdInput" type="text"/>
</span>
<span className="totalJobDetailsbyIdSpanInput">
    <p className="totalJobDetailsbyIdSpanTitle">Seniorty Level</p>
    <input className="totalJobDetailsbyIdInput" type="text"/>
</span>
    </div>
    <div className="totalJobJobDetailsById">
        <div className="totalJobJobDetailsByIdTwo">
            <p className="totalJobJobDetailsByIdTwoP">Job Description</p>
            <p className="totalJobJobDetailsByIdTwoP2">
            As a Creative Designer on the Brand Creative team at Labcorp, you’ll have the intern opportunity to take on a wide variety of graphic design projects, from advertisements, video, and social media to spatial branding, presentations, and other high-profile projects. Reporting a Creative Design Manager, you can't showcase your
            </p>
        </div>
        <div className="totalJobJobDetailsByIdTwo">
        <p className="totalJobJobDetailsByIdTwoP">Roles & Responsibilities</p>
        <div className="totalJobTextAreaPart">
            <span className="totalJobTextAreaPartSpan">
<textarea  className="totalJobJobDetailsByIdTextArea">Collaborate closely with cross-functional teams, including product managers ,developers, to gain a deep understanding of project requirements and translate them into compelling and impactful design solutions</textarea></span>
<span className="totalJobTextAreaPartSpan"><textarea className="totalJobJobDetailsByIdTextArea">Collaborate closely with cross-functional teams, including product managers ,developers, to gain a deep understanding of project requirements and translate them into compelling and impactful design solutions</textarea></span>
</div>
        </div>
    </div>
</div>
<div className="totaljobAboutCompany">
    <p className="totaljobAboutCompany">About Vyzen</p>
    <p className="totaljobAboutCompanyDesc">As a Product Designer, you will be at the forefront of our design process, ensuring that our digital products exceed user expectations and drive engagement. Through user research and insights, you will have the opportunity to deeply understand our customers' needs and pain points, translating them into seamless and delightful experiences. Your ability to iterate on designs based on feedback, user testing, and dat</p>
</div>
    </div>
    </div> */}
{/* CARDS SELECTION AND MOVING */}
<div className="totalJobCardSelectedMainDiv">
<div className="totalJobCardSelected">
    <p className="totalJobCardSelectedP">1 Cards Selected</p>
    <p className="totalJobCardSelectedP">Move to&nbsp;<i class="fa-solid fa-chevron-down ArrowDownMove"></i></p>
    <p className="totalJobCardSelectedP">Cancel</p>
</div>
</div>
</div>

</div>
</div>
    
    </>
)
}
export default TotalJobs;
