import { Link } from "react-router-dom";
import "./styles.css"
import JobSearchMainTopImage from "../../Assets/png/JobSearchTopImage.png"
import ellipseImage from "../../Assets/png/jobSearchEllipse.png"
import JobSearchFilter from "../../Assets/png/filterButton.png"
import wishListImage from "../../Assets/png/wishlist.svg"
import jobLocationSvg from "../../Assets/png/jobLocation.svg"
import jobApplicants from "../../Assets/png/jobApplicant.svg"
import jobPostedDate from "../../Assets/png/JobDate.svg"
import jobtypePermanent from "../../Assets/png/jobType.svg"
import JobTypePackage from "../../Assets/png/jobMoney.svg"
import jobTypeOffice from "../../Assets/png/JobWorkType.svg"
import jobWorkExp from "../../Assets/png/JobWorkExp.svg"
import jobProfilePicture from "../../Assets/png/jobProfileImage.png"
const JobSearch = () => {
    return (

        <>
            <div className="jobSearchMainDiv">
                <div className="JobSearchTopDiv">
                    <div className="loginSignUpButtons">
                        <Link to="/candidate/login">SignIn</Link>
                        <Link to="/">Create an account</Link>
                    </div>
                    <div className="loginSignUpButtonsImage">
                        <img src={JobSearchMainTopImage} alt="JobSearchMainTopImage" className="JobSearchMainTopImage" />
                    </div>
                </div>

                <div className="JobSearchSecondDiv">
                    <img src={ellipseImage} alt="ellipseImage" className="ellipseImage" />
                    <div className="JobSearchSecondSearchBar">
                        <input type="search" placeholder="Search" />
                        <img alt="JobSearchFilter" className="JobSearchFilter" src={JobSearchFilter} />
                    </div>
                </div>

                <div className="JobSearchJob_results">
                    <div className="jobResultsDiv">
                        <div className="jobProfileDiv">
                            <div className="jobProfileAbout_title_name">
                                <img src={jobProfilePicture} alt="jobProfilePicture" className="jobProfilePicture" />
                                <div className="jobProfileTitles"><p className="jobTitleSearch">Back End Developer</p>
                                    <p className="jobCompanyName">Amazon - 2,90,0000 Followers</p>
                                </div>
                            </div>
                            <div className="wishApplyorNot">
                                <img src={wishListImage} alt="wishListImage" className="wishListImage" />
                                <button className="applyNowButton">Apply Now</button>
                            </div>
                        </div>
                        <p className="jobDescription">
                            As a Creative Designer on the Brand Creative team at Labcorp, you’ll have the ntern opportunity to take on a wide variety of graphic design projects, from advertisements, video,
                            and social media to spatial branding, presentations, and other high-profile projects. Reporting a Creative Design Manager, you can't showcase your
                        </p>
                        <div className="aboutThejobDetailsSearch">
                            <span className="aboutThejobDetailsSearchSpan"><img src={jobLocationSvg} alt="jobLocationSvg" className="aboutThejobDetailsSearchSvg" /><p className="aboutThejobDetailsSearchPara">Chennai, Ind</p></span>
                            <span className="aboutThejobDetailsSearchSpan"><img src={ jobWorkExp} alt="jobWorkExp" className="aboutThejobDetailsSearchSvg" /><p className="aboutThejobDetailsSearchPara">4 - 5 Years</p></span>
                            <span className="aboutThejobDetailsSearchSpan"><img src={ jobTypeOffice} alt="jobTypeOffice" className="aboutThejobDetailsSearchSvg" /><p className="aboutThejobDetailsSearchPara">Work From Office</p></span>
                            <span className="aboutThejobDetailsSearchSpan"><img src={ JobTypePackage} alt="JobTypePackage" className="aboutThejobDetailsSearchSvg" /><p className="aboutThejobDetailsSearchPara">5 - 10 LPA</p></span>
                            <span className="aboutThejobDetailsSearchSpan"><img src={ jobtypePermanent} alt="jobtypePermanent" className="aboutThejobDetailsSearchSvg" /><p className="aboutThejobDetailsSearchPara">Permanent</p></span>
                            <span className="aboutThejobDetailsSearchSpan"><img src={ jobPostedDate} alt="jobPostedDate" className="aboutThejobDetailsSearchSvg" /><p className="aboutThejobDetailsSearchPara">28-10-2023</p></span>
                            <span className="aboutThejobDetailsSearchSpan"><img src={ jobApplicants} alt="jobApplicants" className="aboutThejobDetailsSearchSvg" /><p className="aboutThejobDetailsSearchPara">50 Applicant</p></span>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default JobSearch;
