import React from 'react'

const Department = () => {
  return (
    <div>
      department page
    </div>
  )
}

export default Department
